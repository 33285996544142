<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import AppSidebar from '@/layout/AppSidebar.vue';
import { usePrimeVue } from 'primevue/config';
import { useUser } from '@/composables/useUser';
import { auth, signOutUser } from '@/service/FirestoreService';
import { useRouter } from 'vue-router';

const router = useRouter();

const { getUser, setUser } = useUser();
const user = ref(null);
const $primevue = usePrimeVue();

defineExpose({
    $primevue
});
const { isHorizontal, onMenuToggle, showConfigSidebar, showSidebar } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);

onMounted(() => {
    bindOutsideClickListener();
    user.value = getUser();
    // console.log(user.value);
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const logout = async () => {
    try {
        await signOutUser(auth);
        setUser(null); // Clear user state
        router.push('/auth/login'); // Redirect to login page
    } catch (error) {
        console.error('Error signing out:', error);
    }
};

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
const onMenuButtonClick = () => {
    onMenuToggle();
};

const onConfigButtonClick = () => {
    showConfigSidebar();
};
const onSidebarButtonClick = () => {
    showSidebar();
};
</script>

<template>
    <div class="layout-topbar">
        <div class="topbar-start">
            <Button ref="menubutton" type="button" class="topbar-menubutton p-link p-trigger transition-duration-300"
                @click="onMenuButtonClick()">
                <i class="pi pi-bars"></i>
            </Button>
        </div>
        <div class="layout-topbar-menu-section">
            <AppSidebar></AppSidebar>
        </div>
        <div class="topbar-end">
            <ul class="topbar-menu">
                <li :class="isHorizontal ? 'block topbar-item' : 'block sm:hidden topbar-item'">
                    <a v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'px-scalein', leaveToClass: 'hidden', leaveActiveClass: 'px-fadeout', hideOnOutsideClick: 'true' }"
                        v-ripple>
                        <!-- <Button type="button" icon="pi pi-search" text severity="secondary"></Button> -->
                    </a>
                    <ul :class="'hidden topbar-menu active-topbar-menu p-3 w-15rem  z-5'" style="bottom: -5.8rem">
                        <IconField iconPosition="left" class="w-full">
                            <InputIcon class="pi pi-search" />
                            <InputText type="text" placeholder="Search" class="w-full" />
                        </IconField>
                    </ul>
                </li>
                <li class="topbar-item">
                    <a v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'px-scalein', leaveToClass: 'hidden', leaveActiveClass: 'px-fadeout', hideOnOutsideClick: 'true' }"
                        v-ripple class="cursor-pointer">
                        <span class="">Hello, {{ user?.displayName ? user.displayName : '' }}</span>
                        <p>{{user?.email}}</p>
                    </a>
                </li>
                <!-- <li>
                    <Button type="button" icon="pi pi-cog" class="flex-shrink-0" text severity="secondary"
                        @click="onConfigButtonClick()"></Button>
                </li> -->
                <li>
                    <Button type="button" label="Log out" class="flex-shrink-0" text severity="secondary"
                        @click="logout"></Button>
                </li>
            </ul>
        </div>
    </div>
</template>
