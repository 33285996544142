<script setup>
import AppMenu from './AppMenu.vue';
import { useLayout } from '@/layout/composables/layout';
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { useUser } from '@/composables/useUser';

const userState = useUser();
const userName = ref('');
const userEmail = ref('');
const userRoles = ref(null);
const router = useRouter();


const { layoutState } = useLayout();

onMounted(() => {
	const user = userState.getUser();
	if (user) {
		userName.value = user.displayName || `${user.firstName} ${user.lastName}`;
		userEmail.value = user.email;
		userRoles.value = user.roles;
		// console.log('user:', user);
	}
});

const navigateToDashboard = () => {
  // console.log('userRoles:', userRoles.value);
  
  if (Array.isArray(userRoles.value) && userRoles.value.length > 0) {
    const roles = userRoles.value[0];
    
    if (Array.isArray(roles) && roles.includes('Admin')) {
      // console.log('Navigating to admin dashboard');
      router.push('/');
    } else {
      // console.log('Navigating to client view');
      router.push('/clients/view-all');
    }
  } else {
    // console.log('No user roles found, defaulting to client view');
    // router.push('/clients/view-all');
  }
};


let timeout = null;

const onMouseEnter = () => {
    if (!layoutState.anchored.value) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        layoutState.sidebarActive.value = true;
    }
};

const onMouseLeave = () => {
    if (!layoutState.anchored.value) {
        if (!timeout) {
            timeout = setTimeout(() => (layoutState.sidebarActive.value = false), 300);
        }
    }
};

const anchor = () => {
    layoutState.anchored.value = !layoutState.anchored.value;
};
</script>

<template>
    <div class="layout-sidebar" @mouseenter="onMouseEnter()" @mouseleave="onMouseLeave()">
        <div class="sidebar-header">
            <a @click="navigateToDashboard" class="app-logo cursor-pointer">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="80%" viewBox="0 0 1152 1134"
                    enable-background="new 0 0 1152 1134" xml:space="preserve">
                    <path fill="#FFFFFF" opacity="1.000000" stroke="none" d="
M767.000000,1135.000000 
	C511.333344,1135.000000 256.166656,1135.000000 1.000000,1135.000000 
	C1.000000,757.000000 1.000000,379.000000 1.000000,1.000000 
	C385.000000,1.000000 769.000000,1.000000 1153.000000,1.000000 
	C1153.000000,379.000000 1153.000000,757.000000 1153.000000,1135.000000 
	C1024.500000,1135.000000 896.000000,1135.000000 767.000000,1135.000000 
M422.003357,636.984314 
	C422.003357,636.984314 422.011047,636.914429 422.237671,637.629395 
	C422.836945,637.745972 423.436188,637.862549 423.995544,637.999573 
	C423.995544,637.999573 423.990814,637.955505 423.547729,638.590210 
	C430.564392,645.096069 437.365417,651.854919 444.640259,658.057983 
	C462.684631,673.443848 483.234314,684.700317 505.374054,692.880005 
	C523.233398,699.478333 541.636353,704.403809 560.823364,704.930237 
	C575.349426,705.328674 589.995178,706.038818 604.408142,704.690796 
	C621.803040,703.063843 638.711182,698.460876 655.296082,692.529114 
	C670.336670,687.149658 680.049866,674.958984 678.063477,657.593445 
	C675.762756,637.480530 655.249451,625.337952 636.204346,632.613770 
	C610.585144,642.401184 584.416870,645.615967 557.535706,641.796509 
	C537.806335,638.993286 519.125793,632.372925 502.328217,621.158752 
	C505.548828,613.020752 505.884583,604.655457 503.968048,596.216675 
	C501.176453,583.925049 495.308380,573.544495 484.275574,565.867249 
	C483.909271,565.760803 483.542999,565.654358 483.176727,565.547913 
	C483.425323,566.054565 483.673889,566.561279 484.022949,567.888000 
	C486.535065,574.655212 484.340363,580.430847 479.928955,585.492432 
	C477.266205,588.547607 473.507233,588.919861 469.956696,587.374817 
	C461.945190,583.888367 455.515228,577.807922 451.314850,570.680847 
	C444.956848,559.892822 440.713074,547.950806 440.977661,534.135681 
	C441.919617,509.556061 447.626892,486.461609 462.589508,466.168304 
	C445.145996,474.568390 435.832642,489.337524 428.834717,506.326874 
	C428.834717,496.886047 428.834717,487.445251 429.006897,477.188324 
	C429.852325,472.428253 430.612671,467.651154 431.559082,462.911255 
	C434.478271,448.290680 439.909637,434.533783 446.727173,420.727936 
	C457.145966,402.296509 470.196930,386.093994 487.680328,372.822937 
	C500.196594,363.342072 513.678101,355.510498 528.501282,350.321899 
	C536.837524,347.403961 545.521484,345.479218 554.931396,343.022003 
	C560.190552,342.665466 565.537964,342.793396 570.690857,341.854034 
	C582.305115,339.736755 593.665283,341.970367 604.961792,343.655792 
	C613.753174,344.967377 622.302124,347.903564 631.335510,350.748291 
	C649.139893,356.946686 665.148438,366.398590 679.176514,378.834625 
	C700.062256,397.350098 715.497742,419.865234 723.624939,446.639587 
	C727.805054,460.410309 742.509033,471.865692 758.101562,470.015717 
	C771.625916,468.411072 784.221191,457.920868 785.973572,443.176453 
	C787.597595,436.910858 785.951416,430.976624 784.060120,425.095947 
	C776.954529,403.002136 766.240112,382.739685 752.501831,364.101837 
	C740.499573,347.819153 726.435974,333.413422 710.342773,321.193756 
	C694.169006,308.913025 676.583252,298.921509 657.466614,291.862396 
	C644.017517,286.896088 630.432861,282.342865 615.539429,280.204803 
	C612.379639,280.118469 609.218201,280.071228 606.060364,279.938934 
	C593.015076,279.392517 579.970764,278.820374 566.049927,278.134155 
	C565.051758,278.143158 564.032776,278.023468 563.058472,278.180573 
	C550.233337,280.247986 537.320068,281.912262 524.630005,284.619934 
	C517.233337,286.198151 510.196442,289.462341 502.231354,292.030212 
	C491.149200,297.364197 479.714722,302.087952 469.093628,308.220459 
	C460.196167,313.357697 452.248657,320.140167 443.265167,326.183929 
	C442.732697,326.677917 442.200256,327.171936 441.591888,327.693848 
	C441.591888,327.693848 441.511932,327.705841 440.944763,327.957153 
	C432.905609,335.937256 424.348114,343.470825 416.977203,352.026642 
	C410.011169,360.112488 404.292023,369.272552 397.956970,377.977753 
	C397.956970,377.977753 397.946655,378.046722 397.497070,378.178986 
	C397.217010,378.362976 397.055573,378.621155 396.972961,378.961761 
	C396.972961,378.961761 396.955048,378.998291 396.369019,379.180939 
	C396.254028,379.767975 396.139038,380.354980 395.402954,381.279053 
	C386.714325,396.807129 378.667816,412.614136 373.856873,430.683136 
	C373.170715,432.976624 372.250549,435.228882 371.838287,437.570557 
	C369.880920,448.687500 368.062012,459.828766 366.008087,471.769531 
	C365.744476,474.197693 365.480865,476.625885 365.074463,479.906464 
	C365.111420,481.922241 365.148376,483.937988 365.024933,486.856140 
	C365.072113,489.905304 365.119293,492.954437 365.026337,496.929596 
	C365.350830,503.357788 365.230835,509.847046 366.103485,516.199951 
	C367.429108,525.850525 369.443573,535.406494 371.042786,545.787537 
	C375.860260,564.166565 382.517242,581.839905 392.097321,598.274841 
	C400.054749,611.925964 408.403595,625.358459 420.960846,636.385559 
	C421.331573,636.582031 421.702301,636.778564 422.003357,636.984314 
M533.500000,452.000000 
	C544.900513,452.000000 556.301086,452.000000 568.144775,452.000000 
	C550.198730,497.309326 532.544006,541.883118 514.674561,586.998962 
	C528.421936,586.998962 541.242065,586.906128 554.059204,587.061707 
	C557.424805,587.102539 558.076233,584.970642 559.005920,582.606140 
	C564.930908,567.537354 570.912842,552.491089 576.874939,537.436890 
	C587.596497,510.365021 598.363586,483.310944 608.973938,456.195557 
	C610.181519,453.109528 611.721375,451.888580 615.123047,451.923218 
	C631.783691,452.092743 648.447693,451.919342 665.108582,452.075500 
	C668.053162,452.103088 669.596008,451.087585 670.860779,448.564240 
	C675.351868,439.604187 680.047058,430.746674 684.596436,421.815399 
	C685.950500,419.157135 687.079712,416.384277 688.471985,413.310669 
	C686.862854,413.142883 686.208801,413.015076 685.554810,413.014954 
	C628.569519,413.003754 571.584229,412.977234 514.599365,413.134338 
	C513.048401,413.138611 510.832214,414.779999 510.062378,416.252136 
	C504.496552,426.895752 499.216156,437.688843 493.869720,448.446686 
	C493.348145,449.496216 492.991241,450.627533 492.447266,452.000000 
	C506.179077,452.000000 519.339539,452.000000 533.500000,452.000000 
M723.223450,589.205505 
	C719.153198,591.951233 714.829529,594.404358 711.145386,597.595825 
	C709.792847,598.767456 709.953735,601.686218 709.424194,603.807983 
	C711.375122,603.930481 713.572205,604.682739 715.222839,604.037292 
	C718.159485,602.888977 720.765198,600.894470 723.509277,599.253967 
	C723.886047,599.622314 724.262756,599.990723 724.639526,600.359070 
	C723.347229,601.924500 722.286621,603.794983 720.712952,604.989929 
	C717.717468,607.264526 714.505249,609.285889 711.239746,611.163208 
	C708.187622,612.917969 708.188416,615.744019 709.744751,617.750732 
	C710.499512,618.723938 713.872803,618.314941 715.729675,617.632080 
	C718.493896,616.615479 720.965027,614.802002 723.560913,613.327637 
	C723.867554,613.607483 724.174194,613.887329 724.480835,614.167175 
	C723.654236,615.618713 722.953308,617.161438 721.977295,618.504456 
	C719.164124,622.375854 719.308655,626.303467 722.644104,629.089233 
	C725.915222,631.821350 729.313538,631.704041 732.491150,628.949646 
	C735.772705,626.105103 735.773987,622.324768 732.902222,618.354126 
	C731.964539,617.057617 731.465332,615.444031 730.765625,613.975525 
	C734.179016,615.149170 736.478455,617.074097 739.080200,618.410461 
	C741.078186,619.436646 743.711365,621.137878 745.428345,617.941956 
	C746.907532,615.188599 746.030151,612.815552 743.420471,611.117310 
	C740.264526,609.063599 736.954346,607.210510 733.996216,604.904663 
	C732.505798,603.742920 731.645569,601.772705 730.500427,600.168091 
	C730.850342,599.903870 731.200256,599.639709 731.550171,599.375488 
	C734.267273,601.206604 736.820679,603.407593 739.770447,604.712708 
	C741.243469,605.364502 743.441040,604.378906 745.310852,604.133911 
	C744.874756,601.942383 745.117920,599.154663 743.841675,597.700623 
	C741.978088,595.577698 739.109863,594.248718 736.513855,592.876526 
	C732.260254,590.628235 730.465820,587.561890 730.807068,582.502625 
	C731.033142,579.151123 732.166321,576.971985 734.942383,576.179138 
	C737.040161,575.580017 739.676941,575.777283 741.776917,576.492188 
	C746.943115,578.250977 748.861389,582.027771 748.119629,587.577393 
	C747.762146,590.251892 748.295898,593.221558 749.162842,595.810852 
	C749.610291,597.147034 751.593018,598.568909 753.029907,598.744934 
	C753.867798,598.847534 755.685120,596.656799 755.859436,595.338806 
	C756.280640,592.154358 755.996094,588.876526 755.996094,585.636353 
	C756.470703,585.463867 756.945312,585.291443 757.419922,585.119019 
	C758.278625,586.587708 759.661987,587.978149 759.895020,589.540161 
	C760.525574,593.765930 760.830261,598.060303 760.945618,602.335999 
	C761.023560,605.221008 762.461609,606.675842 764.909241,605.818298 
	C766.330322,605.320496 767.817444,603.226074 768.096619,601.642395 
	C768.606506,598.749390 768.241638,595.702148 768.241638,591.133606 
	C770.013916,593.453003 771.237000,594.703186 772.046021,596.180176 
	C774.217773,600.145264 778.216919,602.009155 781.925476,600.549622 
	C785.671082,599.075500 787.285583,596.067993 786.595886,592.358948 
	C785.778564,587.962708 782.402954,586.337769 777.468872,586.731201 
	C776.090881,586.841125 774.612488,585.693787 772.479858,584.844238 
	C775.582886,583.243530 777.526184,581.813477 779.697693,581.223633 
	C782.754028,580.393555 784.926270,578.806702 783.708679,575.725830 
	C782.336365,572.253601 779.270813,572.325317 776.190552,573.937317 
	C772.981262,575.617004 769.813171,577.434265 766.450867,578.736511 
	C764.912598,579.332336 762.952209,578.837891 761.183228,578.837891 
	C761.133972,578.408875 761.084656,577.979858 761.035400,577.550903 
	C763.492126,576.331665 765.953247,575.121338 768.404236,573.890686 
	C770.688293,572.743896 773.264526,571.500427 771.588074,568.360779 
	C770.091492,565.557922 767.509216,565.309204 764.735352,566.701965 
	C761.206055,568.474060 757.728394,570.378174 754.086121,571.880249 
	C752.811035,572.406067 750.900513,572.366394 749.654114,571.784302 
	C747.020569,570.554321 744.633484,568.796631 742.337402,567.374390 
	C740.971375,561.897583 742.693909,558.604065 747.944885,556.275940 
	C750.994446,554.923828 753.095032,554.675293 755.827026,556.322327 
	C758.498291,557.932678 761.262024,559.580811 764.208862,560.476135 
	C766.195374,561.079712 769.960632,561.161682 770.506409,560.158386 
	C772.045105,557.330017 771.080627,554.491455 767.512146,553.112000 
	C765.079651,552.171570 762.796936,550.843689 760.447266,549.689026 
	C760.514221,549.251465 760.581177,548.813904 760.648132,548.376343 
	C762.815369,548.597717 765.147827,548.385864 767.113770,549.135315 
	C770.636292,550.478088 773.965027,552.347961 777.323181,554.096497 
	C779.649048,555.307495 781.610413,555.791321 783.063171,552.842529 
	C784.507141,549.911316 783.168335,548.237732 780.735596,546.943420 
	C777.999329,545.487610 775.242065,544.070984 771.208557,541.966736 
	C774.692078,541.535339 776.879883,540.914917 779.010071,541.072510 
	C783.630371,541.414246 786.293762,538.516174 786.617188,534.952393 
	C786.836426,532.536682 784.694885,528.908447 782.521851,527.500122 
	C778.958496,525.190735 774.764832,527.199524 772.558899,530.833801 
	C771.605225,532.404968 770.237183,533.724670 769.057434,535.158691 
	C768.541870,531.355835 769.195740,528.220154 768.811951,525.217102 
	C768.617004,523.691406 766.553040,522.404480 765.333923,521.009644 
	C763.891235,522.463501 761.904907,523.686462 761.161316,525.437256 
	C760.441162,527.132629 761.573486,529.604248 760.875549,531.321838 
	C759.467957,534.785583 761.852112,539.873718 755.950134,542.267029 
	C756.338684,538.488708 757.209351,535.236816 756.765137,532.175781 
	C756.537842,530.609863 753.956238,529.385681 752.438660,528.007019 
	C751.296326,529.311951 750.025085,530.531311 749.074585,531.963562 
	C748.689453,532.543823 749.034729,533.589844 748.995178,534.421387 
	C748.643799,541.808594 748.748779,549.439819 739.490051,552.042664 
	C738.910645,552.205505 738.427368,552.710266 737.929077,553.036499 
	C737.341125,552.615906 736.968201,552.284973 736.541199,552.053894 
	C733.165100,550.227173 730.643494,548.517334 730.715881,543.583191 
	C730.785706,538.824890 732.806091,536.880310 736.384827,535.010681 
	C738.986328,533.651611 741.688110,532.093872 743.612976,529.963257 
	C744.972900,528.458130 745.106873,525.845459 745.788147,523.727234 
	C743.739197,523.529846 741.432190,522.643005 739.699890,523.291504 
	C736.874023,524.349426 734.423096,526.409058 731.814026,528.046082 
	C731.411682,527.742249 731.009338,527.438477 730.606934,527.134705 
	C731.574890,525.690735 732.251160,523.851990 733.567505,522.879639 
	C736.988586,520.352478 740.972290,518.527832 744.145569,515.752869 
	C745.455078,514.607788 745.265503,511.748596 745.757812,509.669067 
	C743.854431,509.443390 741.678772,508.523987 740.106201,509.141113 
	C737.135010,510.307159 734.510559,512.356689 731.741943,514.039001 
	C731.374268,513.803467 731.006531,513.567871 730.638794,513.332336 
	C731.379944,511.762177 731.907837,510.039215 732.902649,508.650818 
	C735.754272,504.670959 736.052673,499.877655 732.385010,498.310547 
	C729.521484,497.087006 725.263672,497.432922 722.274170,498.660828 
	C719.106689,499.961853 719.500366,505.037567 722.137146,508.679504 
	C723.096130,510.004089 723.681580,511.599091 724.437500,513.070679 
	C721.448792,512.500122 719.414734,511.212646 717.388550,509.912842 
	C714.834351,508.274231 711.687195,506.206360 709.559204,509.386383 
	C707.369507,512.658630 710.091492,515.152832 712.931641,516.989685 
	C715.960266,518.948425 719.046936,520.844910 721.889099,523.050903 
	C723.061768,523.961121 723.663208,525.607300 724.524475,526.918762 
	C724.175537,527.210083 723.826599,527.501404 723.477661,527.792725 
	C721.444153,526.496582 719.408875,525.203186 717.377625,523.903625 
	C714.822327,522.268921 711.673340,520.200623 709.551880,523.398010 
	C707.377441,526.675293 710.096985,529.164673 712.945007,530.994446 
	C715.565063,532.677795 718.015381,534.659973 720.747681,536.123901 
	C723.265381,537.472778 723.751709,539.297852 724.148438,542.006592 
	C725.070374,548.301208 721.983948,551.155334 716.358215,552.489075 
	C715.363281,551.895081 714.187439,550.872986 712.824097,550.437195 
	C708.132629,548.937439 706.469238,545.938477 706.885132,541.036438 
	C707.157959,537.820923 706.663208,534.424072 705.836914,531.286316 
	C705.487671,529.960083 703.497009,528.466370 702.071167,528.251221 
	C701.233337,528.124939 699.309326,530.269653 699.147217,531.553589 
	C698.725037,534.898010 699.005249,538.331238 699.005249,541.729004 
	C698.463440,541.886353 697.921570,542.043640 697.379761,542.200928 
	C696.588074,540.649536 695.339600,539.166626 695.094788,537.533203 
	C694.485962,533.471069 694.153931,529.344421 694.061646,525.235840 
	C693.997803,522.396423 693.286987,520.690430 690.101074,521.007629 
	C686.878479,521.328369 686.334045,523.380981 686.579407,526.144592 
	C686.832886,528.999268 686.879517,531.872375 687.016541,534.737427 
	C686.526428,534.917542 686.036377,535.097595 685.546265,535.277710 
	C684.615784,534.007324 683.451904,532.845642 682.799438,531.445740 
	C680.983459,527.549500 678.328369,525.691589 673.849121,526.533630 
	C669.539612,527.343628 667.910828,530.541748 668.265991,533.990723 
	C668.717529,538.374695 671.008911,541.128967 676.713745,541.039551 
	C678.327881,541.014160 679.949463,541.466797 682.895996,541.891907 
	C678.812195,544.040771 675.726379,545.095032 673.450439,547.078552 
	C672.113037,548.244080 672.062988,550.886780 671.433838,552.865051 
	C673.311890,553.237427 675.422241,554.342285 677.021240,553.835022 
	C680.744934,552.653809 684.142029,550.473755 687.831421,549.141479 
	C689.855469,548.410522 692.204163,548.578857 694.406494,548.341675 
	C691.681946,550.752686 688.851318,552.044373 686.089478,553.469421 
	C683.657837,554.724121 682.060913,556.462341 683.772522,559.360107 
	C685.344299,562.021179 687.510376,561.706909 689.932007,560.570984 
	C694.385010,558.481995 698.893982,556.512390 703.416687,554.476318 
	C705.017395,555.317688 706.731750,556.231873 708.457703,557.123474 
	C712.467224,559.194580 714.344177,563.273438 712.926941,566.862854 
	C712.109009,568.934570 702.683838,572.924988 700.745789,571.914734 
	C697.544006,570.245605 694.564026,567.958252 691.187378,566.888733 
	C689.130859,566.237366 685.286621,566.571411 684.406799,567.897156 
	C682.882935,570.193359 684.306702,572.849304 687.335083,574.133789 
	C689.662109,575.120605 691.810791,576.527832 694.038818,577.748169 
	C692.099121,578.925232 690.200745,579.419556 688.743103,578.827942 
	C685.094849,577.347229 681.775452,574.978455 678.062622,573.767639 
	C676.239258,573.172974 673.835205,574.358948 671.693909,574.739136 
	C672.635498,576.764038 673.114746,579.294312 674.635742,580.686035 
	C676.538025,582.426636 679.296753,583.231262 681.685730,584.439941 
	C680.271362,585.845093 678.868042,586.357666 677.558044,586.180481 
	C672.612732,585.511780 669.695496,587.300415 668.395325,592.189880 
	C667.296021,596.323853 669.401794,598.922302 672.921509,600.367981 
	C677.185303,602.119202 680.115173,600.260010 682.300842,596.432251 
	C683.238953,594.789429 684.841064,593.525879 686.939880,591.208374 
	C686.541321,595.758911 685.776794,598.911621 686.187134,601.903259 
	C686.431458,603.684509 688.370544,606.405762 689.816711,606.589661 
	C692.316040,606.907471 694.126038,605.140137 694.092163,601.861572 
	C694.048096,597.596313 694.449768,593.288208 695.130859,589.074707 
	C695.361755,587.646484 697.023987,586.449524 698.032104,585.146851 
	C698.658691,589.022034 697.873291,592.315735 698.199890,595.495178 
	C698.360413,597.057556 700.581238,598.408325 701.869751,599.854858 
	C703.244446,598.495178 705.487305,597.320129 705.823486,595.739624 
	C706.672852,591.745422 706.751099,587.573669 706.977905,583.464233 
	C707.246155,578.604553 715.239441,573.988220 719.728821,575.884033 
	C726.225464,578.627563 723.540710,584.011658 723.223450,589.205505 
M204.102371,827.287781 
	C207.955551,837.490112 211.808716,847.692444 215.653076,857.871460 
	C220.011200,857.871460 224.155197,857.871460 228.487122,857.871460 
	C234.398224,841.455383 240.290726,825.090942 246.183212,808.726440 
	C246.515961,808.773926 246.848709,808.821350 247.181458,808.868835 
	C247.181458,825.169067 247.181458,841.469299 247.181458,857.715698 
	C253.035858,857.715698 258.297485,857.715698 263.647705,857.715698 
	C263.647705,833.390259 263.647705,809.350769 263.647705,785.005371 
	C256.655762,785.005371 250.002258,784.802734 243.379593,785.164612 
	C241.936829,785.243469 239.889145,787.074158 239.335831,788.552917 
	C235.383087,799.116455 231.748199,809.798889 228.006027,820.441345 
	C226.228851,825.495361 224.437912,830.544617 222.756393,835.303894 
	C217.164291,819.501221 211.577103,803.629944 205.880600,787.797974 
	C205.495483,786.727661 204.307892,785.186340 203.457031,785.162415 
	C196.045273,784.954041 188.625183,785.042053 181.283447,785.042053 
	C181.283447,809.708252 181.283447,833.630310 181.283447,857.640076 
	C186.283966,857.640076 190.995758,857.640076 196.103699,857.640076 
	C196.103699,840.149536 196.103699,823.014282 196.103699,804.733582 
	C199.013519,812.754517 201.506744,819.627136 204.102371,827.287781 
M1046.555298,858.000000 
	C1049.636963,858.000000 1052.718628,858.000000 1055.712646,858.000000 
	C1055.712646,833.279724 1055.712646,809.360413 1055.712646,785.540100 
	C1055.134766,785.314148 1054.839966,785.101074 1054.542480,785.097412 
	C1050.546509,785.047913 1046.550049,785.008667 1042.553833,785.003723 
	C1033.031616,784.992065 1033.123291,785.032227 1029.873535,794.273682 
	C1025.142090,807.729004 1020.262878,821.132324 1015.234436,835.149353 
	C1009.143494,818.051208 1003.259338,801.533386 997.497986,785.360413 
	C989.446899,785.360413 981.863770,785.360413 974.247620,785.360413 
	C974.247620,809.640747 974.247620,833.559875 974.247620,857.676941 
	C979.209229,857.676941 983.919800,857.676941 989.034302,857.676941 
	C989.034302,840.864441 989.034302,824.369080 989.034302,807.873718 
	C995.066223,822.646362 1000.759155,837.394897 1005.826782,852.355286 
	C1007.305237,856.719971 1009.068298,858.068054 1013.688477,858.217163 
	C1019.418823,858.402039 1022.455444,856.950439 1023.615784,851.044495 
	C1024.488892,846.600830 1026.510132,842.378967 1028.061157,838.073059 
	C1031.825928,827.620911 1035.614624,817.177307 1039.393555,806.730286 
	C1039.689941,806.791565 1039.986328,806.852844 1040.282715,806.914062 
	C1040.282715,823.832886 1040.282715,840.751709 1040.282715,858.000000 
	C1042.312500,858.000000 1043.955078,858.000000 1046.555298,858.000000 
M168.113708,840.546021 
	C169.438766,835.704468 170.996811,830.912476 172.040207,826.010925 
	C174.189972,815.912170 171.895691,806.537842 166.457596,797.946533 
	C160.915924,789.191711 152.835190,784.821960 142.481659,783.620422 
	C132.154602,782.422119 122.784767,784.381897 114.838615,790.811829 
	C101.401062,801.685425 98.972626,816.725891 102.452560,832.343384 
	C106.032944,848.411499 115.906754,856.611511 131.734039,858.788818 
	C145.746948,860.716431 160.906143,855.382996 168.113708,840.546021 
M354.355469,783.977539 
	C348.730560,785.998047 342.299530,786.896851 337.632629,790.250610 
	C323.915405,800.107666 320.670013,818.609924 324.723022,833.342896 
	C328.701904,847.806458 338.123260,856.641479 352.860229,858.735107 
	C367.039307,860.749573 382.842590,855.846436 390.076263,840.595337 
	C397.201965,825.571838 396.655823,810.658020 387.738831,796.651001 
	C381.262573,786.478027 366.186249,781.146118 354.355469,783.977539 
M961.138916,801.540161 
	C959.005310,798.443726 957.333069,794.858765 954.659851,792.333679 
	C941.592590,779.990295 918.183838,780.092896 905.230530,792.407410 
	C896.503052,800.704468 894.023315,811.443665 893.820129,823.358948 
	C893.560303,838.599365 904.249634,855.475525 922.139404,858.346680 
	C940.815247,861.344055 958.131409,852.153870 963.368530,834.078491 
	C966.478333,823.345337 965.721313,812.600342 961.138916,801.540161 
M448.475281,820.018005 
	C456.332001,813.595703 456.629211,805.000427 453.696808,796.615967 
	C452.369598,792.821167 448.214783,789.334839 444.480133,787.264709 
	C441.122742,785.403687 436.598816,785.208923 432.565857,785.091797 
	C422.815002,784.808716 413.050385,785.000000 403.249573,785.000000 
	C403.249573,809.634766 403.249573,833.558533 403.249573,857.673645 
	C408.880432,857.673645 414.258972,857.673645 420.200104,857.673645 
	C420.200104,848.301086 420.200104,839.243591 420.200104,830.454102 
	C426.423065,828.581543 429.446960,832.084412 432.012421,836.162048 
	C435.937225,842.400208 439.637878,848.779724 443.588043,855.001221 
	C444.365021,856.224976 445.798798,857.788757 447.003113,857.854675 
	C452.557312,858.158936 458.137512,857.988892 465.048431,857.988892 
	C456.945984,845.955200 449.574432,835.007080 442.339325,824.261536 
	C443.731171,823.341370 445.849274,821.941040 448.475281,820.018005 
M822.000000,841.498352 
	C822.000000,846.922424 822.000000,852.346558 822.000000,857.721741 
	C828.023804,857.721741 833.284912,857.721741 839.121582,857.721741 
	C839.121582,847.735352 839.121582,838.013855 839.121582,828.249756 
	C849.299194,828.249756 858.891663,828.249756 869.064087,828.249756 
	C869.064087,838.205627 869.064087,847.928101 869.064087,857.600098 
	C874.704468,857.600098 879.751465,857.600098 884.709595,857.600098 
	C884.709595,833.277222 884.709595,809.355408 884.709595,785.366699 
	C879.367676,785.366699 874.321167,785.366699 868.878418,785.366699 
	C868.878418,794.992737 868.878418,804.256165 868.878418,813.661743 
	C858.758545,813.661743 849.048157,813.661743 838.758850,813.661743 
	C838.758850,803.944397 838.758850,794.553711 838.758850,785.311523 
	C832.909302,785.311523 827.647949,785.311523 822.000000,785.311523 
	C822.000000,803.913269 822.000000,822.205994 822.000000,841.498352 
M559.517151,849.056091 
	C560.530029,850.738098 561.419495,852.512817 562.592102,854.074951 
	C563.662476,855.500793 564.959290,857.709900 566.295532,857.815674 
	C571.703552,858.243835 577.165405,857.993469 583.759155,857.993469 
	C579.736023,851.556458 576.391113,845.829224 572.670959,840.357117 
	C568.901978,834.813293 564.762024,829.521667 560.863953,824.227356 
	C566.673157,822.509399 570.668030,818.540283 573.245422,812.478821 
	C577.901245,801.529663 572.244019,786.972595 559.870972,786.085999 
	C547.759644,785.218079 535.537415,785.897034 523.367065,785.897034 
	C523.367065,809.680969 523.367065,833.701172 523.367065,857.739502 
	C528.661987,857.739502 533.585876,857.739502 539.050781,857.739502 
	C539.050781,848.432373 539.050781,839.370239 539.050781,830.381958 
	C543.969604,829.450073 547.575134,830.041687 550.063843,834.447449 
	C552.793457,839.279663 556.008118,843.837952 559.517151,849.056091 
M611.156372,843.618652 
	C605.793091,841.568665 603.311340,837.419556 603.029724,831.930176 
	C602.580750,823.178101 602.235535,814.417969 602.031433,805.656982 
	C601.873901,798.896545 602.000000,792.129517 602.000000,785.389221 
	C596.312195,785.389221 591.269104,785.389221 586.001953,785.389221 
	C586.001953,801.246216 585.697937,816.723938 586.143250,832.180054 
	C586.336304,838.880920 588.266296,845.525452 593.175232,850.625549 
	C602.876770,860.704895 614.958679,860.553833 627.088135,857.285034 
	C640.881287,853.567871 644.697021,844.762207 645.840149,830.022705 
	C646.661377,819.432861 646.000000,808.728027 646.000000,798.074524 
	C646.000000,793.816833 646.000000,789.559143 646.000000,785.422974 
	C640.246338,785.422974 635.200378,785.422974 629.999878,785.422974 
	C629.999878,799.324890 630.484314,812.826843 629.860474,826.277466 
	C629.264160,839.136597 626.272400,846.086548 611.156372,843.618652 
M1110.139038,797.829712 
	C1110.509766,793.687988 1110.880493,789.546265 1111.250977,785.407837 
	C1096.616333,785.407837 1082.905029,785.407837 1069.000000,785.407837 
	C1069.000000,797.804138 1069.000000,809.793457 1069.000000,821.782776 
	C1069.000000,833.719727 1069.000000,845.656677 1069.000000,857.626099 
	C1083.983643,857.626099 1098.361450,857.626099 1112.725586,857.626099 
	C1112.725586,852.986023 1112.725586,848.722595 1112.725586,843.884338 
	C1103.742310,843.884338 1095.018066,843.884338 1086.249878,843.884338 
	C1086.249878,838.368896 1086.249878,833.440857 1086.249878,827.933716 
	C1094.206055,827.933716 1101.932739,827.933716 1109.597168,827.933716 
	C1109.597168,822.958984 1109.597168,818.581116 1109.597168,813.805908 
	C1101.598755,813.805908 1094.000488,813.805908 1086.378174,813.805908 
	C1086.378174,808.342407 1086.378174,803.297302 1086.378174,797.971130 
	C1094.237427,797.971130 1101.720215,797.971130 1110.139038,797.829712 
M658.051941,816.216003 
	C661.901855,819.222351 665.538757,822.569885 669.659607,825.142273 
	C674.078125,827.900391 679.128906,829.639587 683.576477,832.358704 
	C687.902588,835.003723 688.489990,841.239929 684.563110,842.899109 
	C680.686462,844.536926 675.989685,845.851929 671.993469,845.204590 
	C666.161560,844.259949 660.634399,841.434570 655.001221,839.419434 
	C655.001221,843.345154 654.745239,847.698120 655.144226,851.990112 
	C655.278381,853.433228 656.797791,855.423645 658.161377,855.967896 
	C667.783508,859.808105 677.710510,859.794556 687.732361,857.658264 
	C701.392212,854.746399 708.158325,842.196899 702.989319,828.620056 
	C700.154907,821.175354 693.253967,818.391113 686.893066,815.055481 
	C682.984253,813.005676 678.752625,811.430420 675.182983,808.909790 
	C670.355042,805.500671 671.028992,800.863037 676.395142,798.323364 
	C677.974792,797.575684 679.816956,797.190674 681.572998,797.041626 
	C688.398132,796.462219 694.917236,797.520996 701.000000,801.797058 
	C701.000000,800.015808 700.996521,798.853394 701.000549,797.690918 
	C701.041260,785.946350 700.986938,786.216492 689.280518,783.847473 
	C680.422363,782.054810 672.351379,783.655273 664.620239,787.807861 
	C654.808777,793.077881 651.566956,806.579163 658.051941,816.216003 
M58.011162,825.077393 
	C58.007572,823.245728 57.928928,821.410583 58.012615,819.582886 
	C58.543617,807.985840 67.734550,796.801819 80.076279,798.065979 
	C86.001495,798.672913 91.855049,799.979492 97.999512,801.012695 
	C97.999512,797.613647 97.840691,793.629883 98.052628,789.666016 
	C98.206352,786.790833 97.040268,785.540405 94.322594,784.976379 
	C86.394554,783.331055 78.462914,781.992371 70.426300,784.059875 
	C61.485001,786.360107 53.800465,790.470337 48.136372,798.223694 
	C41.354988,807.506531 39.976944,817.987976 41.149982,828.757935 
	C42.582096,841.906494 49.252846,851.789185 62.143024,856.578308 
	C71.409225,860.020996 80.940277,859.597107 90.370705,857.649658 
	C93.080452,857.090088 97.059860,855.310730 97.627007,853.294495 
	C98.769150,849.234192 97.966995,844.627014 97.966995,841.105530 
	C90.897232,842.293335 84.394966,844.410339 77.952194,844.242615 
	C66.586639,843.946655 60.434284,836.698792 58.011162,825.077393 
M277.000000,830.500000 
	C277.000000,839.583923 277.000000,848.667847 277.000000,857.728271 
	C282.678741,857.728271 287.605988,857.728271 293.105255,857.728271 
	C293.105255,848.085571 293.105255,838.698853 293.105255,828.754333 
	C301.397491,828.754333 309.119476,828.754333 316.688354,828.754333 
	C316.688354,824.240601 316.688354,820.309448 316.688354,815.814087 
	C308.667786,815.814087 300.944702,815.814087 293.280457,815.814087 
	C293.280457,809.635925 293.280457,804.042786 293.280457,797.878174 
	C301.934296,797.878174 310.323212,797.878174 318.751404,797.878174 
	C318.751404,793.368896 318.751404,789.441101 318.751404,785.316040 
	C304.795898,785.316040 291.090332,785.316040 277.000000,785.316040 
	C277.000000,800.252563 277.000000,814.876282 277.000000,830.500000 
M478.970764,799.782593 
	C478.970764,819.042786 478.970764,838.303040 478.970764,857.638428 
	C484.960266,857.638428 490.337311,857.638428 496.276123,857.638428 
	C496.276123,837.576294 496.276123,817.871094 496.276123,797.772461 
	C503.108551,797.772461 509.371216,797.772461 515.607178,797.772461 
	C515.607178,793.313660 515.607178,789.269592 515.607178,785.276611 
	C496.628387,785.276611 478.043823,785.276611 459.351318,785.276611 
	C459.351318,789.602783 459.351318,793.645813 459.351318,798.000000 
	C461.506470,798.000000 463.321808,797.921936 465.128326,798.015320 
	C469.696472,798.251343 474.260620,798.564392 478.970764,799.782593 
M727.029297,856.207642 
	C727.496643,856.776611 727.951477,857.827332 728.433289,857.839844 
	C733.185730,857.963379 737.942749,857.914551 743.254639,857.914551 
	C743.254639,837.594360 743.254639,817.886353 743.254639,797.790283 
	C750.418518,797.790283 757.015076,797.790283 763.618042,797.790283 
	C763.618042,793.335449 763.618042,789.291870 763.618042,785.265381 
	C744.650757,785.265381 726.066650,785.265381 707.339844,785.265381 
	C707.339844,789.579590 707.339844,793.622498 707.339844,798.242554 
	C714.058350,798.242554 720.450806,798.242554 727.004272,798.242554 
	C727.004272,817.635254 727.004272,836.446655 727.029297,856.207642 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M502.011780,621.842773 
	C519.125793,632.372925 537.806335,638.993286 557.535706,641.796509 
	C584.416870,645.615967 610.585144,642.401184 636.204346,632.613770 
	C655.249451,625.337952 675.762756,637.480530 678.063477,657.593445 
	C680.049866,674.958984 670.336670,687.149658 655.296082,692.529114 
	C638.711182,698.460876 621.803040,703.063843 604.408142,704.690796 
	C589.995178,706.038818 575.349426,705.328674 560.823364,704.930237 
	C541.636353,704.403809 523.233398,699.478333 505.374054,692.880005 
	C483.234314,684.700317 462.684631,673.443848 444.640259,658.057983 
	C437.365417,651.854919 430.564392,645.096069 424.129639,638.210693 
	C440.753174,647.742859 457.874451,650.376587 476.493134,645.390503 
	C488.338440,641.833374 495.400970,633.738342 500.337250,623.023193 
	C501.079407,622.556580 501.545593,622.199646 502.011780,621.842773 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M533.000000,452.000000 
	C519.339539,452.000000 506.179077,452.000000 492.447266,452.000000 
	C492.991241,450.627533 493.348145,449.496216 493.869720,448.446686 
	C499.216156,437.688843 504.496552,426.895752 510.062378,416.252136 
	C510.832214,414.779999 513.048401,413.138611 514.599365,413.134338 
	C571.584229,412.977234 628.569519,413.003754 685.554810,413.014954 
	C686.208801,413.015076 686.862854,413.142883 688.471985,413.310669 
	C687.079712,416.384277 685.950500,419.157135 684.596436,421.815399 
	C680.047058,430.746674 675.351868,439.604187 670.860779,448.564240 
	C669.596008,451.087585 668.053162,452.103088 665.108582,452.075500 
	C648.447693,451.919342 631.783691,452.092743 615.123047,451.923218 
	C611.721375,451.888580 610.181519,453.109528 608.973938,456.195557 
	C598.363586,483.310944 587.596497,510.365021 576.874939,537.436890 
	C570.912842,552.491089 564.930908,567.537354 559.005920,582.606140 
	C558.076233,584.970642 557.424805,587.102539 554.059204,587.061707 
	C541.242065,586.906128 528.421936,586.998962 514.674561,586.998962 
	C532.544006,541.883118 550.198730,497.309326 568.144775,452.000000 
	C556.301086,452.000000 544.900513,452.000000 533.000000,452.000000 
z" />
                    <path fill="#F7A217" opacity="1.000000" stroke="none" d="
M785.833496,444.001953 
	C784.221191,457.920868 771.625916,468.411072 758.101562,470.015717 
	C742.509033,471.865692 727.805054,460.410309 723.624939,446.639587 
	C715.497742,419.865234 700.062256,397.350098 679.176514,378.834625 
	C665.148438,366.398590 649.139893,356.946686 631.129150,350.071655 
	C627.646606,345.794617 624.528442,342.033844 621.065918,338.622528 
	C608.076904,325.825500 594.980591,313.137268 581.905396,300.427887 
	C580.963318,299.512146 579.767944,298.850311 578.860229,297.907013 
	C573.062012,291.881531 567.311340,285.810303 561.373291,279.573761 
	C563.252869,279.128265 565.089478,278.692963 566.926147,278.257629 
	C579.970764,278.820374 593.015076,279.392517 606.060364,279.938934 
	C609.218201,280.071228 612.379639,280.118469 615.807800,280.897949 
	C617.171631,283.014923 618.105957,284.606628 619.387207,285.836884 
	C634.129333,299.991577 648.925110,314.090240 663.704529,328.206116 
	C678.604309,342.436951 693.524902,356.646210 708.394226,370.908813 
	C726.027100,387.822266 743.606567,404.791504 761.226807,421.718231 
	C768.415894,428.624420 775.632996,435.501953 782.891541,442.334961 
	C783.688782,443.085510 784.844238,443.455475 785.833496,444.001953 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M723.499268,588.897827 
	C723.540710,584.011658 726.225464,578.627563 719.728821,575.884033 
	C715.239441,573.988220 707.246155,578.604553 706.977905,583.464233 
	C706.751099,587.573669 706.672852,591.745422 705.823486,595.739624 
	C705.487305,597.320129 703.244446,598.495178 701.869751,599.854858 
	C700.581238,598.408325 698.360413,597.057556 698.199890,595.495178 
	C697.873291,592.315735 698.658691,589.022034 698.032104,585.146851 
	C697.023987,586.449524 695.361755,587.646484 695.130859,589.074707 
	C694.449768,593.288208 694.048096,597.596313 694.092163,601.861572 
	C694.126038,605.140137 692.316040,606.907471 689.816711,606.589661 
	C688.370544,606.405762 686.431458,603.684509 686.187134,601.903259 
	C685.776794,598.911621 686.541321,595.758911 686.939880,591.208374 
	C684.841064,593.525879 683.238953,594.789429 682.300842,596.432251 
	C680.115173,600.260010 677.185303,602.119202 672.921509,600.367981 
	C669.401794,598.922302 667.296021,596.323853 668.395325,592.189880 
	C669.695496,587.300415 672.612732,585.511780 677.558044,586.180481 
	C678.868042,586.357666 680.271362,585.845093 681.685730,584.439941 
	C679.296753,583.231262 676.538025,582.426636 674.635742,580.686035 
	C673.114746,579.294312 672.635498,576.764038 671.693909,574.739136 
	C673.835205,574.358948 676.239258,573.172974 678.062622,573.767639 
	C681.775452,574.978455 685.094849,577.347229 688.743103,578.827942 
	C690.200745,579.419556 692.099121,578.925232 694.038818,577.748169 
	C691.810791,576.527832 689.662109,575.120605 687.335083,574.133789 
	C684.306702,572.849304 682.882935,570.193359 684.406799,567.897156 
	C685.286621,566.571411 689.130859,566.237366 691.187378,566.888733 
	C694.564026,567.958252 697.544006,570.245605 700.745789,571.914734 
	C702.683838,572.924988 712.109009,568.934570 712.926941,566.862854 
	C714.344177,563.273438 712.467224,559.194580 708.457703,557.123474 
	C706.731750,556.231873 705.017395,555.317688 703.416687,554.476318 
	C698.893982,556.512390 694.385010,558.481995 689.932007,560.570984 
	C687.510376,561.706909 685.344299,562.021179 683.772522,559.360107 
	C682.060913,556.462341 683.657837,554.724121 686.089478,553.469421 
	C688.851318,552.044373 691.681946,550.752686 694.406494,548.341675 
	C692.204163,548.578857 689.855469,548.410522 687.831421,549.141479 
	C684.142029,550.473755 680.744934,552.653809 677.021240,553.835022 
	C675.422241,554.342285 673.311890,553.237427 671.433838,552.865051 
	C672.062988,550.886780 672.113037,548.244080 673.450439,547.078552 
	C675.726379,545.095032 678.812195,544.040771 682.895996,541.891907 
	C679.949463,541.466797 678.327881,541.014160 676.713745,541.039551 
	C671.008911,541.128967 668.717529,538.374695 668.265991,533.990723 
	C667.910828,530.541748 669.539612,527.343628 673.849121,526.533630 
	C678.328369,525.691589 680.983459,527.549500 682.799438,531.445740 
	C683.451904,532.845642 684.615784,534.007324 685.546265,535.277710 
	C686.036377,535.097595 686.526428,534.917542 687.016541,534.737427 
	C686.879517,531.872375 686.832886,528.999268 686.579407,526.144592 
	C686.334045,523.380981 686.878479,521.328369 690.101074,521.007629 
	C693.286987,520.690430 693.997803,522.396423 694.061646,525.235840 
	C694.153931,529.344421 694.485962,533.471069 695.094788,537.533203 
	C695.339600,539.166626 696.588074,540.649536 697.379761,542.200928 
	C697.921570,542.043640 698.463440,541.886353 699.005249,541.729004 
	C699.005249,538.331238 698.725037,534.898010 699.147217,531.553589 
	C699.309326,530.269653 701.233337,528.124939 702.071167,528.251221 
	C703.497009,528.466370 705.487671,529.960083 705.836914,531.286316 
	C706.663208,534.424072 707.157959,537.820923 706.885132,541.036438 
	C706.469238,545.938477 708.132629,548.937439 712.824097,550.437195 
	C714.187439,550.872986 715.363281,551.895081 716.358215,552.489075 
	C721.983948,551.155334 725.070374,548.301208 724.148438,542.006592 
	C723.751709,539.297852 723.265381,537.472778 720.747681,536.123901 
	C718.015381,534.659973 715.565063,532.677795 712.945007,530.994446 
	C710.096985,529.164673 707.377441,526.675293 709.551880,523.398010 
	C711.673340,520.200623 714.822327,522.268921 717.377625,523.903625 
	C719.408875,525.203186 721.444153,526.496582 723.477661,527.792725 
	C723.826599,527.501404 724.175537,527.210083 724.524475,526.918762 
	C723.663208,525.607300 723.061768,523.961121 721.889099,523.050903 
	C719.046936,520.844910 715.960266,518.948425 712.931641,516.989685 
	C710.091492,515.152832 707.369507,512.658630 709.559204,509.386383 
	C711.687195,506.206360 714.834351,508.274231 717.388550,509.912842 
	C719.414734,511.212646 721.448792,512.500122 724.437500,513.070679 
	C723.681580,511.599091 723.096130,510.004089 722.137146,508.679504 
	C719.500366,505.037567 719.106689,499.961853 722.274170,498.660828 
	C725.263672,497.432922 729.521484,497.087006 732.385010,498.310547 
	C736.052673,499.877655 735.754272,504.670959 732.902649,508.650818 
	C731.907837,510.039215 731.379944,511.762177 730.638794,513.332336 
	C731.006531,513.567871 731.374268,513.803467 731.741943,514.039001 
	C734.510559,512.356689 737.135010,510.307159 740.106201,509.141113 
	C741.678772,508.523987 743.854431,509.443390 745.757812,509.669067 
	C745.265503,511.748596 745.455078,514.607788 744.145569,515.752869 
	C740.972290,518.527832 736.988586,520.352478 733.567505,522.879639 
	C732.251160,523.851990 731.574890,525.690735 730.606934,527.134705 
	C731.009338,527.438477 731.411682,527.742249 731.814026,528.046082 
	C734.423096,526.409058 736.874023,524.349426 739.699890,523.291504 
	C741.432190,522.643005 743.739197,523.529846 745.788147,523.727234 
	C745.106873,525.845459 744.972900,528.458130 743.612976,529.963257 
	C741.688110,532.093872 738.986328,533.651611 736.384827,535.010681 
	C732.806091,536.880310 730.785706,538.824890 730.715881,543.583191 
	C730.643494,548.517334 733.165100,550.227173 736.541199,552.053894 
	C736.968201,552.284973 737.341125,552.615906 737.929077,553.036499 
	C738.427368,552.710266 738.910645,552.205505 739.490051,552.042664 
	C748.748779,549.439819 748.643799,541.808594 748.995178,534.421387 
	C749.034729,533.589844 748.689453,532.543823 749.074585,531.963562 
	C750.025085,530.531311 751.296326,529.311951 752.438660,528.007019 
	C753.956238,529.385681 756.537842,530.609863 756.765137,532.175781 
	C757.209351,535.236816 756.338684,538.488708 755.950134,542.267029 
	C761.852112,539.873718 759.467957,534.785583 760.875549,531.321838 
	C761.573486,529.604248 760.441162,527.132629 761.161316,525.437256 
	C761.904907,523.686462 763.891235,522.463501 765.333923,521.009644 
	C766.553040,522.404480 768.617004,523.691406 768.811951,525.217102 
	C769.195740,528.220154 768.541870,531.355835 769.057434,535.158691 
	C770.237183,533.724670 771.605225,532.404968 772.558899,530.833801 
	C774.764832,527.199524 778.958496,525.190735 782.521851,527.500122 
	C784.694885,528.908447 786.836426,532.536682 786.617188,534.952393 
	C786.293762,538.516174 783.630371,541.414246 779.010071,541.072510 
	C776.879883,540.914917 774.692078,541.535339 771.208557,541.966736 
	C775.242065,544.070984 777.999329,545.487610 780.735596,546.943420 
	C783.168335,548.237732 784.507141,549.911316 783.063171,552.842529 
	C781.610413,555.791321 779.649048,555.307495 777.323181,554.096497 
	C773.965027,552.347961 770.636292,550.478088 767.113770,549.135315 
	C765.147827,548.385864 762.815369,548.597717 760.648132,548.376343 
	C760.581177,548.813904 760.514221,549.251465 760.447266,549.689026 
	C762.796936,550.843689 765.079651,552.171570 767.512146,553.112000 
	C771.080627,554.491455 772.045105,557.330017 770.506409,560.158386 
	C769.960632,561.161682 766.195374,561.079712 764.208862,560.476135 
	C761.262024,559.580811 758.498291,557.932678 755.827026,556.322327 
	C753.095032,554.675293 750.994446,554.923828 747.944885,556.275940 
	C742.693909,558.604065 740.971375,561.897583 742.337402,567.374390 
	C744.633484,568.796631 747.020569,570.554321 749.654114,571.784302 
	C750.900513,572.366394 752.811035,572.406067 754.086121,571.880249 
	C757.728394,570.378174 761.206055,568.474060 764.735352,566.701965 
	C767.509216,565.309204 770.091492,565.557922 771.588074,568.360779 
	C773.264526,571.500427 770.688293,572.743896 768.404236,573.890686 
	C765.953247,575.121338 763.492126,576.331665 761.035400,577.550903 
	C761.084656,577.979858 761.133972,578.408875 761.183228,578.837891 
	C762.952209,578.837891 764.912598,579.332336 766.450867,578.736511 
	C769.813171,577.434265 772.981262,575.617004 776.190552,573.937317 
	C779.270813,572.325317 782.336365,572.253601 783.708679,575.725830 
	C784.926270,578.806702 782.754028,580.393555 779.697693,581.223633 
	C777.526184,581.813477 775.582886,583.243530 772.479858,584.844238 
	C774.612488,585.693787 776.090881,586.841125 777.468872,586.731201 
	C782.402954,586.337769 785.778564,587.962708 786.595886,592.358948 
	C787.285583,596.067993 785.671082,599.075500 781.925476,600.549622 
	C778.216919,602.009155 774.217773,600.145264 772.046021,596.180176 
	C771.237000,594.703186 770.013916,593.453003 768.241638,591.133606 
	C768.241638,595.702148 768.606506,598.749390 768.096619,601.642395 
	C767.817444,603.226074 766.330322,605.320496 764.909241,605.818298 
	C762.461609,606.675842 761.023560,605.221008 760.945618,602.335999 
	C760.830261,598.060303 760.525574,593.765930 759.895020,589.540161 
	C759.661987,587.978149 758.278625,586.587708 757.419922,585.119019 
	C756.945312,585.291443 756.470703,585.463867 755.996094,585.636353 
	C755.996094,588.876526 756.280640,592.154358 755.859436,595.338806 
	C755.685120,596.656799 753.867798,598.847534 753.029907,598.744934 
	C751.593018,598.568909 749.610291,597.147034 749.162842,595.810852 
	C748.295898,593.221558 747.762146,590.251892 748.119629,587.577393 
	C748.861389,582.027771 746.943115,578.250977 741.776917,576.492188 
	C739.676941,575.777283 737.040161,575.580017 734.942383,576.179138 
	C732.166321,576.971985 731.033142,579.151123 730.807068,582.502625 
	C730.465820,587.561890 732.260254,590.628235 736.513855,592.876526 
	C739.109863,594.248718 741.978088,595.577698 743.841675,597.700623 
	C745.117920,599.154663 744.874756,601.942383 745.310852,604.133911 
	C743.441040,604.378906 741.243469,605.364502 739.770447,604.712708 
	C736.820679,603.407593 734.267273,601.206604 731.550171,599.375488 
	C731.200256,599.639709 730.850342,599.903870 730.500427,600.168091 
	C731.645569,601.772705 732.505798,603.742920 733.996216,604.904663 
	C736.954346,607.210510 740.264526,609.063599 743.420471,611.117310 
	C746.030151,612.815552 746.907532,615.188599 745.428345,617.941956 
	C743.711365,621.137878 741.078186,619.436646 739.080200,618.410461 
	C736.478455,617.074097 734.179016,615.149170 730.765625,613.975525 
	C731.465332,615.444031 731.964539,617.057617 732.902222,618.354126 
	C735.773987,622.324768 735.772705,626.105103 732.491150,628.949646 
	C729.313538,631.704041 725.915222,631.821350 722.644104,629.089233 
	C719.308655,626.303467 719.164124,622.375854 721.977295,618.504456 
	C722.953308,617.161438 723.654236,615.618713 724.480835,614.167175 
	C724.174194,613.887329 723.867554,613.607483 723.560913,613.327637 
	C720.965027,614.802002 718.493896,616.615479 715.729675,617.632080 
	C713.872803,618.314941 710.499512,618.723938 709.744751,617.750732 
	C708.188416,615.744019 708.187622,612.917969 711.239746,611.163208 
	C714.505249,609.285889 717.717468,607.264526 720.712952,604.989929 
	C722.286621,603.794983 723.347229,601.924500 724.639526,600.359070 
	C724.262756,599.990723 723.886047,599.622314 723.509277,599.253967 
	C720.765198,600.894470 718.159485,602.888977 715.222839,604.037292 
	C713.572205,604.682739 711.375122,603.930481 709.424194,603.807983 
	C709.953735,601.686218 709.792847,598.767456 711.145386,597.595825 
	C714.829529,594.404358 719.153198,591.951233 723.499268,588.897827 
z" />
                    <path fill="#FAAA17" opacity="1.000000" stroke="none" d="
M785.903564,443.589203 
	C784.844238,443.455475 783.688782,443.085510 782.891541,442.334961 
	C775.632996,435.501953 768.415894,428.624420 761.226807,421.718231 
	C743.606567,404.791504 726.027100,387.822266 708.394226,370.908813 
	C693.524902,356.646210 678.604309,342.436951 663.704529,328.206116 
	C648.925110,314.090240 634.129333,299.991577 619.387207,285.836884 
	C618.105957,284.606628 617.171631,283.014923 616.059021,281.238312 
	C630.432861,282.342865 644.017517,286.896088 657.466614,291.862396 
	C676.583252,298.921509 694.169006,308.913025 710.342773,321.193756 
	C726.435974,333.413422 740.499573,347.819153 752.501831,364.101837 
	C766.240112,382.739685 776.954529,403.002136 784.060120,425.095947 
	C785.951416,430.976624 787.597595,436.910858 785.903564,443.589203 
z" />
                    <path fill="#DB5319" opacity="1.000000" stroke="none" d="
M500.061279,623.132874 
	C495.400970,633.738342 488.338440,641.833374 476.445618,645.010132 
	C471.516693,640.132935 467.098419,635.480347 462.492371,631.021667 
	C443.971039,613.093018 425.388550,595.227600 406.834930,577.332275 
	C397.018524,567.864258 387.265259,558.329712 377.358368,548.957520 
	C375.610870,547.304321 373.245453,546.304321 371.165405,545.002686 
	C369.443573,535.406494 367.429108,525.850525 366.103485,516.199951 
	C365.230835,509.847046 365.350830,503.357788 365.450806,496.470764 
	C367.247711,496.838959 368.792511,497.474335 369.962463,498.526428 
	C374.768921,502.848785 379.514771,507.242096 384.186096,511.710541 
	C400.738159,527.543762 417.277954,543.390076 433.768433,559.287415 
	C445.724976,570.813843 457.590820,582.434448 469.517273,593.992188 
	C470.342163,594.791565 471.412445,595.337585 472.238220,596.136292 
	C481.527344,605.119873 490.790863,614.129944 500.061279,623.132874 
z" />
                    <path fill="#EE8918" opacity="1.000000" stroke="none" d="
M443.880554,326.194214 
	C452.248657,320.140167 460.196167,313.357697 469.093628,308.220459 
	C479.714722,302.087952 491.149200,297.364197 502.754639,292.302917 
	C515.992310,305.015381 528.742493,317.418854 541.401245,329.914978 
	C545.734924,334.192932 549.839539,338.703003 554.048767,343.107025 
	C545.521484,345.479218 536.837524,347.403961 528.501282,350.321899 
	C513.678101,355.510498 500.196594,363.342072 487.221710,372.657593 
	C473.457672,359.448273 460.144043,346.412628 446.856262,333.350739 
	C445.009613,331.535461 443.290222,329.590668 441.511932,327.705841 
	C441.511932,327.705841 441.591888,327.693848 441.928772,327.714172 
	C442.803986,327.221100 443.342255,326.707642 443.880554,326.194214 
z" />
                    <path fill="#F39818" opacity="1.000000" stroke="none" d="
M554.490112,343.064514 
	C549.839539,338.703003 545.734924,334.192932 541.401245,329.914978 
	C528.742493,317.418854 515.992310,305.015381 503.137817,292.271790 
	C510.196442,289.462341 517.233337,286.198151 524.630005,284.619934 
	C537.320068,281.912262 550.233337,280.247986 563.058472,278.180573 
	C564.032776,278.023468 565.051758,278.143158 566.488037,278.195892 
	C565.089478,278.692963 563.252869,279.128265 561.373291,279.573761 
	C567.311340,285.810303 573.062012,291.881531 578.860229,297.907013 
	C579.767944,298.850311 580.963318,299.512146 581.905396,300.427887 
	C594.980591,313.137268 608.076904,325.825500 621.065918,338.622528 
	C624.528442,342.033844 627.646606,345.794617 630.940857,349.756378 
	C622.302124,347.903564 613.753174,344.967377 604.961792,343.655792 
	C593.665283,341.970367 582.305115,339.736755 570.690857,341.854034 
	C565.537964,342.793396 560.190552,342.665466 554.490112,343.064514 
z" />
                    <path fill="#E77919" opacity="1.000000" stroke="none" d="
M441.228333,327.831482 
	C443.290222,329.590668 445.009613,331.535461 446.856262,333.350739 
	C460.144043,346.412628 473.457672,359.448273 486.906219,372.801392 
	C470.196930,386.093994 457.145966,402.296509 446.100220,420.752625 
	C443.889984,419.592438 442.158997,418.560699 440.746643,417.198608 
	C429.034271,405.903534 417.436432,394.488953 405.659882,383.261658 
	C403.444000,381.149109 400.586060,379.709991 398.024414,377.960114 
	C404.292023,369.272552 410.011169,360.112488 416.977203,352.026642 
	C424.348114,343.470825 432.905609,335.937256 441.228333,327.831482 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M204.051178,826.893799 
	C201.506744,819.627136 199.013519,812.754517 196.103699,804.733582 
	C196.103699,823.014282 196.103699,840.149536 196.103699,857.640076 
	C190.995758,857.640076 186.283966,857.640076 181.283447,857.640076 
	C181.283447,833.630310 181.283447,809.708252 181.283447,785.042053 
	C188.625183,785.042053 196.045273,784.954041 203.457031,785.162415 
	C204.307892,785.186340 205.495483,786.727661 205.880600,787.797974 
	C211.577103,803.629944 217.164291,819.501221 222.756393,835.303894 
	C224.437912,830.544617 226.228851,825.495361 228.006027,820.441345 
	C231.748199,809.798889 235.383087,799.116455 239.335831,788.552917 
	C239.889145,787.074158 241.936829,785.243469 243.379593,785.164612 
	C250.002258,784.802734 256.655762,785.005371 263.647705,785.005371 
	C263.647705,809.350769 263.647705,833.390259 263.647705,857.715698 
	C258.297485,857.715698 253.035858,857.715698 247.181458,857.715698 
	C247.181458,841.469299 247.181458,825.169067 247.181458,808.868835 
	C246.848709,808.821350 246.515961,808.773926 246.183212,808.726440 
	C240.290726,825.090942 234.398224,841.455383 228.487122,857.871460 
	C224.155197,857.871460 220.011200,857.871460 215.653076,857.871460 
	C211.808716,847.692444 207.955551,837.490112 204.051178,826.893799 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M1046.076538,858.000000 
	C1043.955078,858.000000 1042.312500,858.000000 1040.282715,858.000000 
	C1040.282715,840.751709 1040.282715,823.832886 1040.282715,806.914062 
	C1039.986328,806.852844 1039.689941,806.791565 1039.393555,806.730286 
	C1035.614624,817.177307 1031.825928,827.620911 1028.061157,838.073059 
	C1026.510132,842.378967 1024.488892,846.600830 1023.615784,851.044495 
	C1022.455444,856.950439 1019.418823,858.402039 1013.688477,858.217163 
	C1009.068298,858.068054 1007.305237,856.719971 1005.826782,852.355286 
	C1000.759155,837.394897 995.066223,822.646362 989.034302,807.873718 
	C989.034302,824.369080 989.034302,840.864441 989.034302,857.676941 
	C983.919800,857.676941 979.209229,857.676941 974.247620,857.676941 
	C974.247620,833.559875 974.247620,809.640747 974.247620,785.360413 
	C981.863770,785.360413 989.446899,785.360413 997.497986,785.360413 
	C1003.259338,801.533386 1009.143494,818.051208 1015.234436,835.149353 
	C1020.262878,821.132324 1025.142090,807.729004 1029.873535,794.273682 
	C1033.123291,785.032227 1033.031616,784.992065 1042.553833,785.003723 
	C1046.550049,785.008667 1050.546509,785.047913 1054.542480,785.097412 
	C1054.839966,785.101074 1055.134766,785.314148 1055.712646,785.540100 
	C1055.712646,809.360413 1055.712646,833.279724 1055.712646,858.000000 
	C1052.718628,858.000000 1049.636963,858.000000 1046.076538,858.000000 
z" />
                    <path fill="#E36A18" opacity="1.000000" stroke="none" d="
M397.990692,377.968933 
	C400.586060,379.709991 403.444000,381.149109 405.659882,383.261658 
	C417.436432,394.488953 429.034271,405.903534 440.746643,417.198608 
	C442.158997,418.560699 443.889984,419.592438 445.791870,420.913025 
	C439.909637,434.533783 434.478271,448.290680 431.559082,462.911255 
	C430.612671,467.651154 429.852325,472.428253 428.566345,477.589813 
	C426.758667,477.168884 425.176147,476.569611 424.057373,475.489838 
	C411.446625,463.318695 399.010864,450.964081 386.282196,438.918610 
	C382.679688,435.509399 378.277283,432.945435 374.241730,429.993805 
	C378.667816,412.614136 386.714325,396.807129 395.957092,380.956970 
	C396.659180,380.089355 396.807129,379.543823 396.955078,378.998291 
	C396.955048,378.998291 396.972961,378.961761 397.203674,378.871429 
	C397.700592,378.602905 397.871338,378.358124 397.946655,378.046722 
	C397.946655,378.046722 397.956970,377.977753 397.990692,377.968933 
z" />
                    <path fill="#DF5E19" opacity="1.000000" stroke="none" d="
M374.049316,430.338470 
	C378.277283,432.945435 382.679688,435.509399 386.282196,438.918610 
	C399.010864,450.964081 411.446625,463.318695 424.057373,475.489838 
	C425.176147,476.569611 426.758667,477.168884 428.480255,477.997864 
	C428.834717,487.445251 428.834717,496.886047 428.834717,506.326874 
	C435.832642,489.337524 445.145996,474.568390 462.589508,466.168304 
	C447.626892,486.461609 441.919617,509.556061 440.564789,534.525879 
	C438.623322,533.965515 436.877594,533.243103 435.600739,532.027954 
	C426.000885,522.892212 416.484558,513.668579 406.959320,504.454620 
	C396.122223,493.971649 385.336578,483.435120 374.444550,473.009644 
	C372.490387,471.139221 370.128815,469.694489 367.908356,468.017761 
	C367.167267,469.290161 366.680542,470.125763 366.193848,470.961395 
	C368.062012,459.828766 369.880920,448.687500 371.838287,437.570557 
	C372.250549,435.228882 373.170715,432.976624 374.049316,430.338470 
z" />
                    <path fill="#DE5A19" opacity="1.000000" stroke="none" d="
M366.100952,471.365479 
	C366.680542,470.125763 367.167267,469.290161 367.908356,468.017761 
	C370.128815,469.694489 372.490387,471.139221 374.444550,473.009644 
	C385.336578,483.435120 396.122223,493.971649 406.959320,504.454620 
	C416.484558,513.668579 426.000885,522.892212 435.600739,532.027954 
	C436.877594,533.243103 438.623322,533.965515 440.492737,534.958130 
	C440.713074,547.950806 444.956848,559.892822 451.314850,570.680847 
	C455.515228,577.807922 461.945190,583.888367 469.956696,587.374817 
	C473.507233,588.919861 477.266205,588.547607 479.928955,585.492432 
	C484.340363,580.430847 486.535065,574.655212 484.165375,567.296753 
	C484.420593,566.569946 484.533386,566.434448 484.646210,566.298889 
	C495.308380,573.544495 501.176453,583.925049 503.968048,596.216675 
	C505.884583,604.655457 505.548828,613.020752 502.169983,621.500732 
	C501.545593,622.199646 501.079407,622.556580 500.337250,623.023193 
	C490.790863,614.129944 481.527344,605.119873 472.238220,596.136292 
	C471.412445,595.337585 470.342163,594.791565 469.517273,593.992188 
	C457.590820,582.434448 445.724976,570.813843 433.768433,559.287415 
	C417.277954,543.390076 400.738159,527.543762 384.186096,511.710541 
	C379.514771,507.242096 374.768921,502.848785 369.962463,498.526428 
	C368.792511,497.474335 367.247711,496.838959 365.520905,496.007751 
	C365.119293,492.954437 365.072113,489.905304 365.346619,486.130737 
	C365.517914,483.288269 365.367584,481.171143 365.217255,479.054047 
	C365.480865,476.625885 365.744476,474.197693 366.100952,471.365479 
z" />
                    <path fill="#D84C1A" opacity="1.000000" stroke="none" d="
M371.104095,545.395142 
	C373.245453,546.304321 375.610870,547.304321 377.358368,548.957520 
	C387.265259,558.329712 397.018524,567.864258 406.834930,577.332275 
	C425.388550,595.227600 443.971039,613.093018 462.492371,631.021667 
	C467.098419,635.480347 471.516693,640.132935 476.068787,645.076782 
	C457.874451,650.376587 440.753174,647.742859 424.351196,637.893311 
	C423.990814,637.955505 423.995544,637.999573 423.957642,637.626465 
	C423.283508,637.140320 422.647278,637.027344 422.011047,636.914429 
	C422.011047,636.914429 422.003357,636.984314 422.014038,636.553711 
	C421.521698,636.051147 421.018677,635.979248 420.515686,635.907349 
	C408.403595,625.358459 400.054749,611.925964 392.097321,598.274841 
	C382.517242,581.839905 375.860260,564.166565 371.104095,545.395142 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M168.023026,840.914429 
	C160.906143,855.382996 145.746948,860.716431 131.734039,858.788818 
	C115.906754,856.611511 106.032944,848.411499 102.452560,832.343384 
	C98.972626,816.725891 101.401062,801.685425 114.838615,790.811829 
	C122.784767,784.381897 132.154602,782.422119 142.481659,783.620422 
	C152.835190,784.821960 160.915924,789.191711 166.457596,797.946533 
	C171.895691,806.537842 174.189972,815.912170 172.040207,826.010925 
	C170.996811,830.912476 169.438766,835.704468 168.023026,840.914429 
M151.601898,805.147156 
	C150.905319,804.434387 150.116989,803.790100 149.526367,802.998291 
	C143.575943,795.020813 127.788651,796.682373 122.759750,805.640991 
	C117.793526,814.487976 117.917984,823.383179 120.861229,832.658936 
	C123.304482,840.358887 129.128830,844.301086 136.546143,844.588867 
	C143.355026,844.852966 149.331451,841.550781 152.464020,834.976562 
	C157.102966,825.240967 156.255417,815.464966 151.601898,805.147156 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M354.796875,783.931030 
	C366.186249,781.146118 381.262573,786.478027 387.738831,796.651001 
	C396.655823,810.658020 397.201965,825.571838 390.076263,840.595337 
	C382.842590,855.846436 367.039307,860.749573 352.860229,858.735107 
	C338.123260,856.641479 328.701904,847.806458 324.723022,833.342896 
	C320.670013,818.609924 323.915405,800.107666 337.632629,790.250610 
	C342.299530,786.896851 348.730560,785.998047 354.796875,783.931030 
M352.570435,843.157043 
	C362.990417,846.455505 372.628571,842.299011 375.719971,832.208801 
	C378.367188,823.568542 378.207947,814.551880 374.003204,806.271301 
	C368.019989,794.488281 350.066681,795.699463 345.749817,804.774902 
	C343.629333,809.232910 341.853638,814.059326 341.067535,818.904846 
	C339.489014,828.634949 342.612305,838.129211 352.570435,843.157043 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M961.377136,801.845337 
	C965.721313,812.600342 966.478333,823.345337 963.368530,834.078491 
	C958.131409,852.153870 940.815247,861.344055 922.139404,858.346680 
	C904.249634,855.475525 893.560303,838.599365 893.820129,823.358948 
	C894.023315,811.443665 896.503052,800.704468 905.230530,792.407410 
	C918.183838,780.092896 941.592590,779.990295 954.659851,792.333679 
	C957.333069,794.858765 959.005310,798.443726 961.377136,801.845337 
M922.622437,843.187988 
	C932.861877,846.824585 940.878235,842.857422 944.495239,835.894104 
	C949.696350,825.881104 949.674438,815.503418 944.107483,805.831360 
	C938.325317,795.785400 925.529968,794.992615 917.239563,803.193176 
	C908.009766,812.322815 908.544495,836.633301 922.622437,843.187988 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M448.221344,820.279358 
	C445.849274,821.941040 443.731171,823.341370 442.339325,824.261536 
	C449.574432,835.007080 456.945984,845.955200 465.048431,857.988892 
	C458.137512,857.988892 452.557312,858.158936 447.003113,857.854675 
	C445.798798,857.788757 444.365021,856.224976 443.588043,855.001221 
	C439.637878,848.779724 435.937225,842.400208 432.012421,836.162048 
	C429.446960,832.084412 426.423065,828.581543 420.200104,830.454102 
	C420.200104,839.243591 420.200104,848.301086 420.200104,857.673645 
	C414.258972,857.673645 408.880432,857.673645 403.249573,857.673645 
	C403.249573,833.558533 403.249573,809.634766 403.249573,785.000000 
	C413.050385,785.000000 422.815002,784.808716 432.565857,785.091797 
	C436.598816,785.208923 441.122742,785.403687 444.480133,787.264709 
	C448.214783,789.334839 452.369598,792.821167 453.696808,796.615967 
	C456.629211,805.000427 456.332001,813.595703 448.221344,820.279358 
M431.160736,816.037354 
	C437.040466,813.074890 439.463135,808.364380 437.708740,803.305603 
	C435.963654,798.273499 429.548370,796.110352 420.322174,797.531433 
	C420.322174,803.889465 420.322174,810.272827 420.322174,817.294800 
	C423.915680,816.908875 427.135620,816.563049 431.160736,816.037354 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M822.000000,840.998535 
	C822.000000,822.205994 822.000000,803.913269 822.000000,785.311523 
	C827.647949,785.311523 832.909302,785.311523 838.758850,785.311523 
	C838.758850,794.553711 838.758850,803.944397 838.758850,813.661743 
	C849.048157,813.661743 858.758545,813.661743 868.878418,813.661743 
	C868.878418,804.256165 868.878418,794.992737 868.878418,785.366699 
	C874.321167,785.366699 879.367676,785.366699 884.709595,785.366699 
	C884.709595,809.355408 884.709595,833.277222 884.709595,857.600098 
	C879.751465,857.600098 874.704468,857.600098 869.064087,857.600098 
	C869.064087,847.928101 869.064087,838.205627 869.064087,828.249756 
	C858.891663,828.249756 849.299194,828.249756 839.121582,828.249756 
	C839.121582,838.013855 839.121582,847.735352 839.121582,857.721741 
	C833.284912,857.721741 828.023804,857.721741 822.000000,857.721741 
	C822.000000,852.346558 822.000000,846.922424 822.000000,840.998535 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M559.264282,848.785828 
	C556.008118,843.837952 552.793457,839.279663 550.063843,834.447449 
	C547.575134,830.041687 543.969604,829.450073 539.050781,830.381958 
	C539.050781,839.370239 539.050781,848.432373 539.050781,857.739502 
	C533.585876,857.739502 528.661987,857.739502 523.367065,857.739502 
	C523.367065,833.701172 523.367065,809.680969 523.367065,785.897034 
	C535.537415,785.897034 547.759644,785.218079 559.870972,786.085999 
	C572.244019,786.972595 577.901245,801.529663 573.245422,812.478821 
	C570.668030,818.540283 566.673157,822.509399 560.863953,824.227356 
	C564.762024,829.521667 568.901978,834.813293 572.670959,840.357117 
	C576.391113,845.829224 579.736023,851.556458 583.759155,857.993469 
	C577.165405,857.993469 571.703552,858.243835 566.295532,857.815674 
	C564.959290,857.709900 563.662476,855.500793 562.592102,854.074951 
	C561.419495,852.512817 560.530029,850.738098 559.264282,848.785828 
M546.169983,797.001526 
	C543.913818,797.001526 541.657593,797.001526 539.324341,797.001526 
	C539.324341,803.957825 539.324341,810.310364 539.324341,816.995422 
	C541.932861,816.995422 544.234802,817.123047 546.518250,816.971436 
	C553.344177,816.518188 557.081482,812.328979 557.019409,805.585999 
	C556.957397,798.852478 551.817322,798.190186 546.169983,797.001526 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M611.518188,843.769775 
	C626.272400,846.086548 629.264160,839.136597 629.860474,826.277466 
	C630.484314,812.826843 629.999878,799.324890 629.999878,785.422974 
	C635.200378,785.422974 640.246338,785.422974 646.000000,785.422974 
	C646.000000,789.559143 646.000000,793.816833 646.000000,798.074524 
	C646.000000,808.728027 646.661377,819.432861 645.840149,830.022705 
	C644.697021,844.762207 640.881287,853.567871 627.088135,857.285034 
	C614.958679,860.553833 602.876770,860.704895 593.175232,850.625549 
	C588.266296,845.525452 586.336304,838.880920 586.143250,832.180054 
	C585.697937,816.723938 586.001953,801.246216 586.001953,785.389221 
	C591.269104,785.389221 596.312195,785.389221 602.000000,785.389221 
	C602.000000,792.129517 601.873901,798.896545 602.031433,805.656982 
	C602.235535,814.417969 602.580750,823.178101 603.029724,831.930176 
	C603.311340,837.419556 605.793091,841.568665 611.518188,843.769775 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M1109.671021,797.900391 
	C1101.720215,797.971130 1094.237427,797.971130 1086.378174,797.971130 
	C1086.378174,803.297302 1086.378174,808.342407 1086.378174,813.805908 
	C1094.000488,813.805908 1101.598755,813.805908 1109.597168,813.805908 
	C1109.597168,818.581116 1109.597168,822.958984 1109.597168,827.933716 
	C1101.932739,827.933716 1094.206055,827.933716 1086.249878,827.933716 
	C1086.249878,833.440857 1086.249878,838.368896 1086.249878,843.884338 
	C1095.018066,843.884338 1103.742310,843.884338 1112.725586,843.884338 
	C1112.725586,848.722595 1112.725586,852.986023 1112.725586,857.626099 
	C1098.361450,857.626099 1083.983643,857.626099 1069.000000,857.626099 
	C1069.000000,845.656677 1069.000000,833.719727 1069.000000,821.782776 
	C1069.000000,809.793457 1069.000000,797.804138 1069.000000,785.407837 
	C1082.905029,785.407837 1096.616333,785.407837 1111.250977,785.407837 
	C1110.880493,789.546265 1110.509766,793.687988 1109.671021,797.900391 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M657.954895,815.849060 
	C651.566956,806.579163 654.808777,793.077881 664.620239,787.807861 
	C672.351379,783.655273 680.422363,782.054810 689.280518,783.847473 
	C700.986938,786.216492 701.041260,785.946350 701.000549,797.690918 
	C700.996521,798.853394 701.000000,800.015808 701.000000,801.797058 
	C694.917236,797.520996 688.398132,796.462219 681.572998,797.041626 
	C679.816956,797.190674 677.974792,797.575684 676.395142,798.323364 
	C671.028992,800.863037 670.355042,805.500671 675.182983,808.909790 
	C678.752625,811.430420 682.984253,813.005676 686.893066,815.055481 
	C693.253967,818.391113 700.154907,821.175354 702.989319,828.620056 
	C708.158325,842.196899 701.392212,854.746399 687.732361,857.658264 
	C677.710510,859.794556 667.783508,859.808105 658.161377,855.967896 
	C656.797791,855.423645 655.278381,853.433228 655.144226,851.990112 
	C654.745239,847.698120 655.001221,843.345154 655.001221,839.419434 
	C660.634399,841.434570 666.161560,844.259949 671.993469,845.204590 
	C675.989685,845.851929 680.686462,844.536926 684.563110,842.899109 
	C688.489990,841.239929 687.902588,835.003723 683.576477,832.358704 
	C679.128906,829.639587 674.078125,827.900391 669.659607,825.142273 
	C665.538757,822.569885 661.901855,819.222351 657.954895,815.849060 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M58.038857,825.515869 
	C60.434284,836.698792 66.586639,843.946655 77.952194,844.242615 
	C84.394966,844.410339 90.897232,842.293335 97.966995,841.105530 
	C97.966995,844.627014 98.769150,849.234192 97.627007,853.294495 
	C97.059860,855.310730 93.080452,857.090088 90.370705,857.649658 
	C80.940277,859.597107 71.409225,860.020996 62.143024,856.578308 
	C49.252846,851.789185 42.582096,841.906494 41.149982,828.757935 
	C39.976944,817.987976 41.354988,807.506531 48.136372,798.223694 
	C53.800465,790.470337 61.485001,786.360107 70.426300,784.059875 
	C78.462914,781.992371 86.394554,783.331055 94.322594,784.976379 
	C97.040268,785.540405 98.206352,786.790833 98.052628,789.666016 
	C97.840691,793.629883 97.999512,797.613647 97.999512,801.012695 
	C91.855049,799.979492 86.001495,798.672913 80.076279,798.065979 
	C67.734550,796.801819 58.543617,807.985840 58.012615,819.582886 
	C57.928928,821.410583 58.007572,823.245728 58.038857,825.515869 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M277.000000,830.000000 
	C277.000000,814.876282 277.000000,800.252563 277.000000,785.316040 
	C291.090332,785.316040 304.795898,785.316040 318.751404,785.316040 
	C318.751404,789.441101 318.751404,793.368896 318.751404,797.878174 
	C310.323212,797.878174 301.934296,797.878174 293.280457,797.878174 
	C293.280457,804.042786 293.280457,809.635925 293.280457,815.814087 
	C300.944702,815.814087 308.667786,815.814087 316.688354,815.814087 
	C316.688354,820.309448 316.688354,824.240601 316.688354,828.754333 
	C309.119476,828.754333 301.397491,828.754333 293.105255,828.754333 
	C293.105255,838.698853 293.105255,848.085571 293.105255,857.728271 
	C287.605988,857.728271 282.678741,857.728271 277.000000,857.728271 
	C277.000000,848.667847 277.000000,839.583923 277.000000,830.000000 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M478.898560,799.314575 
	C474.260620,798.564392 469.696472,798.251343 465.128326,798.015320 
	C463.321808,797.921936 461.506470,798.000000 459.351318,798.000000 
	C459.351318,793.645813 459.351318,789.602783 459.351318,785.276611 
	C478.043823,785.276611 496.628387,785.276611 515.607178,785.276611 
	C515.607178,789.269592 515.607178,793.313660 515.607178,797.772461 
	C509.371216,797.772461 503.108551,797.772461 496.276123,797.772461 
	C496.276123,817.871094 496.276123,837.576294 496.276123,857.638428 
	C490.337311,857.638428 484.960266,857.638428 478.970764,857.638428 
	C478.970764,838.303040 478.970764,819.042786 478.898560,799.314575 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M727.016785,855.732910 
	C727.004272,836.446655 727.004272,817.635254 727.004272,798.242554 
	C720.450806,798.242554 714.058350,798.242554 707.339844,798.242554 
	C707.339844,793.622498 707.339844,789.579590 707.339844,785.265381 
	C726.066650,785.265381 744.650757,785.265381 763.618042,785.265381 
	C763.618042,789.291870 763.618042,793.335449 763.618042,797.790283 
	C757.015076,797.790283 750.418518,797.790283 743.254639,797.790283 
	C743.254639,817.886353 743.254639,837.594360 743.254639,857.914551 
	C737.942749,857.914551 733.185730,857.963379 728.433289,857.839844 
	C727.951477,857.827332 727.496643,856.776611 727.016785,855.732910 
z" />
                    <path fill="#DF5E19" opacity="1.000000" stroke="none" d="
M365.145874,479.480255 
	C365.367584,481.171143 365.517914,483.288269 365.426788,485.679565 
	C365.148376,483.937988 365.111420,481.922241 365.145874,479.480255 
z" />
                    <path fill="#E77919" opacity="1.000000" stroke="none" d="
M443.572876,326.189087 
	C443.342255,326.707642 442.803986,327.221100 441.966736,327.700226 
	C442.200256,327.171936 442.732697,326.677917 443.572876,326.189087 
z" />
                    <path fill="#E77919" opacity="1.000000" stroke="none" d="
M396.662048,379.089600 
	C396.807129,379.543823 396.659180,380.089355 396.267639,380.788452 
	C396.139038,380.354980 396.254028,379.767975 396.662048,379.089600 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M422.124359,637.271912 
	C422.647278,637.027344 423.283508,637.140320 423.977600,637.616211 
	C423.436188,637.862549 422.836945,637.745972 422.124359,637.271912 
z" />
                    <path fill="#DE5A19" opacity="1.000000" stroke="none" d="
M484.115143,566.886719 
	C483.673889,566.561279 483.425323,566.054565 483.176727,565.547913 
	C483.542999,565.654358 483.909271,565.760803 484.460876,566.083069 
	C484.533386,566.434448 484.420593,566.569946 484.115143,566.886719 
z" />
                    <path fill="#E77919" opacity="1.000000" stroke="none" d="
M397.721863,378.112854 
	C397.871338,378.358124 397.700592,378.602905 397.223633,378.867310 
	C397.055573,378.621155 397.217010,378.362976 397.721863,378.112854 
z" />
                    <path fill="#38C4D6" opacity="1.000000" stroke="none" d="
M420.738281,636.146484 
	C421.018677,635.979248 421.521698,636.051147 422.048859,636.549072 
	C421.702301,636.778564 421.331573,636.582031 420.738281,636.146484 
z" />
                    <path fill="#FFFFFF" opacity="1.000000" stroke="none" d="
M151.776688,805.481812 
	C156.255417,815.464966 157.102966,825.240967 152.464020,834.976562 
	C149.331451,841.550781 143.355026,844.852966 136.546143,844.588867 
	C129.128830,844.301086 123.304482,840.358887 120.861229,832.658936 
	C117.917984,823.383179 117.793526,814.487976 122.759750,805.640991 
	C127.788651,796.682373 143.575943,795.020813 149.526367,802.998291 
	C150.116989,803.790100 150.905319,804.434387 151.776688,805.481812 
z" />
                    <path fill="#FFFFFF" opacity="1.000000" stroke="none" d="
M352.197845,843.056824 
	C342.612305,838.129211 339.489014,828.634949 341.067535,818.904846 
	C341.853638,814.059326 343.629333,809.232910 345.749817,804.774902 
	C350.066681,795.699463 368.019989,794.488281 374.003204,806.271301 
	C378.207947,814.551880 378.367188,823.568542 375.719971,832.208801 
	C372.628571,842.299011 362.990417,846.455505 352.197845,843.056824 
z" />
                    <path fill="#FFFFFF" opacity="1.000000" stroke="none" d="
M922.248596,843.077271 
	C908.544495,836.633301 908.009766,812.322815 917.239563,803.193176 
	C925.529968,794.992615 938.325317,795.785400 944.107483,805.831360 
	C949.674438,815.503418 949.696350,825.881104 944.495239,835.894104 
	C940.878235,842.857422 932.861877,846.824585 922.248596,843.077271 
z" />
                    <path fill="#FFFFFF" opacity="1.000000" stroke="none" d="
M430.758148,816.127319 
	C427.135620,816.563049 423.915680,816.908875 420.322174,817.294800 
	C420.322174,810.272827 420.322174,803.889465 420.322174,797.531433 
	C429.548370,796.110352 435.963654,798.273499 437.708740,803.305603 
	C439.463135,808.364380 437.040466,813.074890 430.758148,816.127319 
z" />
                    <path fill="#FFFFFF" opacity="1.000000" stroke="none" d="
M546.612305,797.006042 
	C551.817322,798.190186 556.957397,798.852478 557.019409,805.585999 
	C557.081482,812.328979 553.344177,816.518188 546.518250,816.971436 
	C544.234802,817.123047 541.932861,816.995422 539.324341,816.995422 
	C539.324341,810.310364 539.324341,803.957825 539.324341,797.001526 
	C541.657593,797.001526 543.913818,797.001526 546.612305,797.006042 
z" />
                </svg>

            </a>
            <button class="layout-sidebar-anchor p-link" type="button" @click="anchor()"></button>
        </div>

        <div ref="menuContainer" class="layout-menu-container">
            <AppMenu></AppMenu>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>
