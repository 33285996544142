// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import LogEntry from './LogEntry';
import { Timestamp, writeBatch, where, query, getFirestore, deleteField, increment, collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc, arrayUnion, setDoc, runTransaction, arrayRemove, serverTimestamp } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, signInWithRedirect, signOut, onAuthStateChanged, getRedirectResult, signInWithPopup, indexedDBLocalPersistence, setPersistence } from 'firebase/auth';
import { getUser } from './authModule';
import { fetchWithCache } from '@/composables/useCache';
// import { getAnalytics } from "firebase/analytics";
// import { updateService } from './FirestoreService';

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
  apiKey: "AIzaSyD86OnMA79XCgNHZ6M71MEF0ko8TBeclHc",
  // authDomain: "comforttrusthome-internal.web.app",
  authDomain: "comforttrusthome-internal.firebaseapp.com",
  // authDomain: "comforttrusthome-internal.firebaseapp.com",
  projectId: "comforttrusthome-internal",
  storageBucket: "comforttrusthome-internal.appspot.com",
  messagingSenderId: "322130364633",
  appId: "1:322130364633:web:7df913bdfdb71ad9b996c8",
  measurementId: "G-F4V441Z6RB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);


// Check functions
export const checkPaidMatchesTransaction = async () => {
  const clientsCollection = collection(db, 'Clients');
  const clientsSnapshot = await getDocs(clientsCollection);

  clientsSnapshot.forEach(clientDoc => {
    const clientData = clientDoc.data();
    const clientId = clientDoc.id; // Get the document ID

    // Safely access the 'paid' value
    const paid = clientData?.balances?.paid || 0;

    const transaction_records = clientData.transaction_records || [];
    let total = 0;

    if (Array.isArray(transaction_records)) {
      total = transaction_records.reduce((sum, record) => sum + (record.amount || 0), 0);
    }

    if (paid !== total) {
      console.log('Client ID:', clientId);
      console.log('Paid amount:', paid);
      console.log('Total from transactions:', total);
      console.log('Difference:', paid - total);
      console.log('------------------------');
    }
  });

  console.log('Check completed');
};

// Dashboard Query Functions
export const getTotalOrderThisMonthByLocation = async () => {
  return fetchWithCache('totalOrderThisMonth', async () => {
    const now = new Date();
    // TODO: change back after testing
    const firstDayOfMonth = Timestamp.fromDate(new Date(now.getFullYear(), now.getMonth(), 1));
    const firstDayOfNextMonth = Timestamp.fromDate(new Date(now.getFullYear(), now.getMonth() + 1, 1));

    // const firstDayOfMonth = Timestamp.fromDate(new Date('2024-07-01'));
    // const firstDayOfNextMonth = Timestamp.fromDate(new Date('2024-08-01'))

    const clientsCollection = collection(db, 'Clients');

    const clientsSnapshot = await getDocs(clientsCollection);
    let ordersByLocation = {};
    let productCategories = {};

    let totalQuotes = 0;
    let convertedQuotes = 0;
    let legitimateOrders = 0;

    clientsSnapshot.forEach(clientDoc => {
      const clientData = clientDoc.data();
      const clientLocation = clientData.location;

      if (clientData.quotes) {
        let quotesToProcess = [];

        if (Array.isArray(clientData.quotes)) {
          quotesToProcess = clientData.quotes;
        } else if (typeof clientData.quotes === 'object') {
          quotesToProcess = Object.values(clientData.quotes);
        }

        totalQuotes += quotesToProcess.length;

        quotesToProcess.forEach(quote => {
          if (quote.is_converted === true) {
            convertedQuotes++;
            // console.log('Quote date:', quote.date.toDate());
            // console.log('Is within range:', quote.date >= firstDayOfMonth && quote.date < firstDayOfNextMonth);
            if (quote.date instanceof Timestamp &&
              quote.date >= firstDayOfMonth &&
              quote.date < firstDayOfNextMonth) {

              if (isLegitOrder(quote) || isPaidClient(clientData)) {
                legitimateOrders++;
                if (clientLocation) {
                  ordersByLocation[clientLocation] = (ordersByLocation[clientLocation] || 0) + 1;
                } else {
                  ordersByLocation['Unknown'] = (ordersByLocation['Unknown'] || 0) + 1;
                }
                // Count product categories
                if (quote.product && Array.isArray(quote.product)) {
                  quote.product.forEach(product => {
                    if (product.category && product.category !== "ACCESSORIES" && product.category !== "WARRANTY" && product.category !== "DISCOUNT") {
                      productCategories[product.category] = (productCategories[product.category] || 0) + 1;
                    }
                  });
                }
              }
            }
          }
        });
      }
    });

    // Get top 4 product categories
    const topCategories = Object.entries(productCategories)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 4);

    const pieData = {
      labels: topCategories.map(category => category[0]),
      datasets: [{
        data: topCategories.map(category => category[1]),
        backgroundColor: [
          getComputedStyle(document.documentElement).getPropertyValue('--primary-300'),
          getComputedStyle(document.documentElement).getPropertyValue('--orange-300'),
          getComputedStyle(document.documentElement).getPropertyValue('--green-300'),
          getComputedStyle(document.documentElement).getPropertyValue('--cyan-300')
        ],
        borderColor: getComputedStyle(document.documentElement).getPropertyValue('--surface-border')
      }]
    };

    // console.log('pieData:', pieData);

    return {
      ordersByLocation,
      pieData,
      totalQuotes,
      convertedQuotes,
      legitimateOrders
    };
  });
};


function isLegitOrder(quote) {
  // console.log('Checking if order is legitimate:', JSON.stringify(quote, null, 2));
  // Case 1: Quote has contract_id and contract_status is "Signed"
  if (quote.contract_id && quote.contract_status === "Signed" || quote.status === "Signed" || quote.contract_status === "Completed" || quote.status === "Completed") {
    // console.log('Legitimate order with signed contract');
    return true;
  }

  // Case 2: No contract, all products are categorized as "DC"
  if (!quote.contract_id && Array.isArray(quote.product)) {
    const isLegit = quote.product.length > 0 && quote.product.every(item => item.category === "DC");
    // console.log('No contract, all products DC:', isLegit);
    return isLegit;
  }

  // console.log('Not a legitimate order');
  return false;
}

function isPaidClient(client) {
  return client.balances && client.balances.paid > 0;
}

export const getTotalRevenueByLocation = async () => {
  return fetchWithCache('totalRevenueByLocation', async () => {
    const now = new Date();
    // TODO: change back after testing
    const firstDayOfMonth = Timestamp.fromDate(new Date(now.getFullYear(), now.getMonth(), 1));
    const firstDayOfNextMonth = Timestamp.fromDate(new Date(now.getFullYear(), now.getMonth() + 1, 1));

    // const firstDayOfMonth = Timestamp.fromDate(new Date('2024-07-01'));
    // const firstDayOfNextMonth = Timestamp.fromDate(new Date('2024-08-01'))
    // console.log('First day of month:', firstDayOfMonth);
    // console.log('First day of next month:', firstDayOfNextMonth);

    const clientsCollection = collection(db, 'Clients');

    // Query all clients without any filter
    const clientsSnapshot = await getDocs(clientsCollection);
    let revenueByLocation = {};
    let clientCount = 0;


    clientsSnapshot.forEach(clientDoc => {
      const clientData = clientDoc.data();
      const clientLocation = clientData.location || 'Unknown';

      if (clientData.quotes) {
        let quotesToProcess = Array.isArray(clientData.quotes) ? clientData.quotes : Object.values(clientData.quotes);

        quotesToProcess.forEach(quote => {
          if (quote.is_converted === true &&
            clientData.transaction_records && Array.isArray(clientData.transaction_records)) {

            if (isLegitOrder(quote) || isPaidClient(clientData)) {
              let orderRevenue = 0;

              clientData.transaction_records.forEach(record => {
                // Assuming record.date is now a Timestamp
                if (record.date instanceof Timestamp &&
                  record.date >= firstDayOfMonth &&
                  record.date < firstDayOfNextMonth) {

                  if (clientLocation === 'Toronto') {
                    clientCount++;
                    // console.log('Record date:', record.date.toDate());
                    // console.log('Record amount:', record.amount);
                  }

                  orderRevenue += record.amount || 0;
                }
              });

              revenueByLocation[clientLocation] = (revenueByLocation[clientLocation] || 0) + orderRevenue;
            }
          }
        });
      }
    });
    // console.log('Total client count:', clientCount);
    return revenueByLocation;
  });
};

function calculateOrderRevenue(quote) {
  let totalRevenue = 0;

  if (Array.isArray(quote.product)) {
    quote.product.forEach(item => {
      if (item.price && !isNaN(item.price)) {
        totalRevenue += parseFloat(item.price);
      }
    });
  }

  return totalRevenue;
}

export const getSalesConversionRateByLocation = async () => {
  return fetchWithCache('salesConversionRateByLocation', async () => {
    const now = new Date();
    // TODO: change back after testing
    const firstDayOfMonth = Timestamp.fromDate(new Date(now.getFullYear(), now.getMonth(), 1));
    const firstDayOfNextMonth = Timestamp.fromDate(new Date(now.getFullYear(), now.getMonth() + 1, 1));

    // const firstDayOfMonth = Timestamp.fromDate(new Date('2024-07-01'));
    // const firstDayOfNextMonth = Timestamp.fromDate(new Date('2024-08-01'))

    const clientsCollection = collection(db, 'Clients');

    // Query all clients without any filter
    const clientsSnapshot = await getDocs(clientsCollection);
    let conversionRateByLocation = {};
    let totalConvertedQuotesByLocation = {};
    let legitOrdersByLocation = {};

    clientsSnapshot.forEach(clientDoc => {
      const clientData = clientDoc.data();
      const clientLocation = clientData.location || 'Unknown';

      if (clientData.quotes && typeof clientData.quotes === 'object') {
        const quotesMap = clientData.quotes;

        Object.values(quotesMap).forEach(quote => {
          if (quote.is_converted === true &&
            quote.date instanceof Timestamp &&
            quote.date >= firstDayOfMonth &&
            quote.date < firstDayOfNextMonth) {

            // Increment total converted quotes for this location
            totalConvertedQuotesByLocation[clientLocation] = (totalConvertedQuotesByLocation[clientLocation] || 0) + 1;

            // Check if it's a legit order
            if (isLegitOrder(quote) || isPaidClient(clientData)) {
              legitOrdersByLocation[clientLocation] = (legitOrdersByLocation[clientLocation] || 0) + 1;
            }
          }
        });
      }
    });

    // Calculate conversion rates
    for (const location in totalConvertedQuotesByLocation) {
      const totalConvertedQuotes = totalConvertedQuotesByLocation[location];
      const legitOrders = legitOrdersByLocation[location] || 0;
      const conversionRate = totalConvertedQuotes > 0 ? legitOrders / totalConvertedQuotes : 0;
      conversionRateByLocation[location] = conversionRate;
    }

    // console.log(`Sales conversion rate by location:`, conversionRateByLocation);

    return conversionRateByLocation;
  });
};

const getUTC4Timestamp = () => {
  const now = new Date();

  // Create a date object for UTC-4
  const utc4Date = new Date(now.toLocaleString("en-US", { timeZone: "America/New_York" }));

  // Create a Firestore Timestamp from this date
  return Timestamp.fromDate(utc4Date);
};


export const getUpcomingInstallationsByLocation = async () => {
  return fetchWithCache('upcomingInstallationsByLocation', async () => {
    const currentTimestamp = Timestamp.now();

    const clientsCollection = collection(db, 'Clients');
    const clientsSnapshot = await getDocs(clientsCollection);

    let upcomingInstallationsByLocation = {};

    for (const clientDoc of clientsSnapshot.docs) {
      const clientData = clientDoc.data();
      const clientLocation = clientData.location || 'Unknown';
      const clientName = `${clientData.first_name} ${clientData.last_name}`;
      const clientAddress = clientData.address;

      if (clientData.quotes && typeof clientData.quotes === 'object') {
        for (const [quoteId, quote] of Object.entries(clientData.quotes)) {
          if (quote.is_converted === true && Array.isArray(quote.install_record)) {
            const upcomingInstallations = quote.install_record.filter(record =>
              record.from_time instanceof Timestamp &&
              record.from_time > currentTimestamp
            );

            for (const installation of upcomingInstallations) {
              if (!upcomingInstallationsByLocation[clientLocation]) {
                upcomingInstallationsByLocation[clientLocation] = [];
              }

              upcomingInstallationsByLocation[clientLocation].push({
                clientName,
                quoteId,
                installationDetails: installation,
                clientAddress,
                // Add any other relevant details you want to include
              });
            }
          }
        }
      }
    }

    // Sort installations by date for each location
    for (const location in upcomingInstallationsByLocation) {
      upcomingInstallationsByLocation[location].sort((a, b) =>
        a.installationDetails.from_time.seconds - b.installationDetails.from_time.seconds
      );
    }
    // console.log('Upcoming installations by location:', upcomingInstallationsByLocation);
    return upcomingInstallationsByLocation;
  });
};


export const getLegitOrdersInTimeSpans = async () => {
  return fetchWithCache('legitOrdersInTimeSpans', async () => {
    const clientsCollection = collection(db, 'Clients');
    const clientsSnapshot = await getDocs(clientsCollection);

    const now = new Date();
    const monthlyData = getDataForTimeSpan(clientsSnapshot, 12, 'month', now);
    const weeklyData = getDataForTimeSpan(clientsSnapshot, 24, 'week', now);
    const dailyData = getDataForTimeSpan(clientsSnapshot, 30, 'day', now);

    return {
      monthlyData: {
        dateRange: 'last 12 months',
        ...monthlyData,
      },
      weeklyData: {
        dateRange: 'last 24 weeks',
        ...weeklyData,
      },
      dailyData: {
        dateRange: 'last 30 days',
        ...dailyData,
      },
    };
  });
};

function getDataForTimeSpan(clientsSnapshot, span, unit, endDate) {
  const startDate = new Date(endDate);
  startDate.setDate(startDate.getDate() - (span * (unit === 'month' ? 30 : unit === 'week' ? 7 : 1)));

  let orders = new Array(span).fill(0);
  let orderUnits = new Array(span).fill(0);
  let totalSales = 0;

  clientsSnapshot.forEach(clientDoc => {
    const clientData = clientDoc.data();
    if (clientData.quotes && typeof clientData.quotes === 'object') {
      Object.values(clientData.quotes).forEach(quote => {
        if ((isLegitOrder(quote) || isPaidClient(clientData)) && quote.date instanceof Timestamp) {
          const quoteDate = quote.date.toDate();
          if (quoteDate >= startDate && quoteDate <= endDate) {
            const index = getIndex(quoteDate, startDate, endDate, span);
            orders[index]++;
            orderUnits[index] += quote.product ? quote.product.length : 0;
            totalSales += calculateOrderTotal(quote);
          }
        }
      });
    }
  });

  const totalOrders = orders.reduce((a, b) => a + b, 0);
  const totalUnits = orderUnits.reduce((a, b) => a + b, 0);

  return {
    orders,
    orderUnits,
    averageUnitByOrder: totalOrders > 0 ? (totalUnits / totalOrders).toFixed(1) : 0,
    averageSalesByOrder: totalOrders > 0 ? `$${(totalSales / totalOrders).toFixed(2)}` : '$0.00',
    totalSales: `$${totalSales.toFixed(2)}`,
  };
}

function getIndex(date, startDate, endDate, span) {
  const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
  const daysSinceStart = (date - startDate) / (1000 * 60 * 60 * 24);
  return Math.floor(daysSinceStart / (totalDays / span));
}

function calculateOrderTotal(quote) {
  if (!quote.product || !Array.isArray(quote.product)) return 0;
  return quote.product.reduce((total, item) => total + (parseFloat(item.price) || 0), 0);
}




export const getNewClientsLastSevenDaysByLocation = async () => {
  return fetchWithCache('newClientsLastSevenDaysByLocation', async () => {
    const now = Timestamp.now();
    const sevenDaysAgo = Timestamp.fromDate(new Date(now.toDate().getTime() - 7 * 24 * 60 * 60 * 1000));

    console.log('Date range:', sevenDaysAgo.toDate().toISOString(), 'to', now.toDate().toISOString());

    const clientsCollection = collection(db, 'Clients');

    // Query for all clients
    const allClientsQuery = query(clientsCollection);

    // Query for new clients in the last 7 days
    const newClientsQuery = query(
      clientsCollection,
      where('created_at', '>=', sevenDaysAgo)
    );

    const [allClientsSnapshot, newClientsSnapshot] = await Promise.all([
      getDocs(allClientsQuery),
      getDocs(newClientsQuery)
    ]);

    const totalClients = allClientsSnapshot.size;
    const totalNewClientsLastWeek = newClientsSnapshot.size;

    console.log('Total clients:', totalClients);
    console.log('New clients in the last week:', totalNewClientsLastWeek);

    // Initialize objects to store counts for each day and location
    const locationCounts = {};
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const labels = [];
    const labelDates = [];

    for (let i = 6; i >= 0; i--) {
      const date = new Date(now.toDate().getTime() - i * 24 * 60 * 60 * 1000);
      const dateString = date.toISOString().split('T')[0];
      const dayOfWeek = daysOfWeek[date.getDay()];
      labels.push(`${date.getDate()}${dayOfWeek}`);
      labelDates.push(dateString);
      // console.log('Label added:', `${date.getDate()}${dayOfWeek}`, 'for date:', dateString);
    }

    // Count clients for each day and location
    newClientsSnapshot.forEach((doc) => {
      const clientData = doc.data();
      if (clientData.created_at instanceof Timestamp && clientData.location) {
        const createdDate = clientData.created_at.toDate();
        const createdDateString = createdDate.toISOString().split('T')[0];
        const location = clientData.location;

        // console.log('Processing client:', doc.id, 'Location:', location, 'Created at:', createdDateString);

        if (!locationCounts[location]) {
          locationCounts[location] = labels.map(() => 0);
        }

        const dayIndex = labelDates.indexOf(createdDateString);

        if (dayIndex !== -1) {
          locationCounts[location][dayIndex]++;
          // console.log('Incremented count for', location, 'on day', labels[dayIndex]);
        } else {
          console.log('Failed to find matching day for', createdDateString);
        }
      } else {
        // console.log('Skipped client due to invalid data:', clientData);
      }
    });

    // console.log('Final locationCounts:', locationCounts);

    const documentStyle = getComputedStyle(document.documentElement);
    const colors = [
      '--primary-light-color',
      '--orange-300',
      '--green-300',
      '--cyan-300',
      '--pink-300',
      '--indigo-300',
      '--teal-300',
      '--blue-300'
    ];

    const datasets = Object.entries(locationCounts).map(([location, data], index) => ({
      label: location,
      data: data,
      borderColor: documentStyle.getPropertyValue(colors[index % colors.length]),
      borderWidth: 2,
      fill: true,
      tension: 0.4
    }));

    // console.log('Final datasets:', datasets);

    return {
      chartData: {
        labels,
        datasets
      },
      totalClients,
      totalNewClientsLastWeek,
      locationCounts
    };
  });
};

export const getTopSales = async (limit = 30) => {
  return fetchWithCache('topSales', async () => {
    const clientsCollection = collection(db, 'Clients');
    const clientsSnapshot = await getDocs(clientsCollection);

    let salesData = {};
    let salesDataThisMonth = {};

    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    clientsSnapshot.forEach((doc) => {
      const clientData = doc.data();
      const assignedTo = clientData.assigns?.assignToSale;

      if (assignedTo) {
        if (!salesData[assignedTo]) {
          salesData[assignedTo] = {
            name: assignedTo,
            assigned_quote: 0,
            converted_quote: 0,
            complete_order: 0,
            location: clientData.location || 'Unknown'
          };
          salesDataThisMonth[assignedTo] = { ...salesData[assignedTo] };
        }

        // Count assigned quotes
        salesData[assignedTo].assigned_quote++;

        // Process quotes
        if (clientData.quotes && typeof clientData.quotes === 'object') {
          Object.values(clientData.quotes).forEach(quote => {
            const quoteDate = quote.date instanceof Timestamp ? quote.date.toDate() : new Date(quote.date);

            if (quote.is_converted === true) {
              salesData[assignedTo].converted_quote++;

              // Check if it's a legit order
              if (isLegitOrder(quote) || isPaidClient(clientData)) {
                salesData[assignedTo].complete_order++;
              }

              // Check if the quote is from this month
              if (quoteDate >= firstDayOfMonth) {
                salesDataThisMonth[assignedTo].assigned_quote++;
                salesDataThisMonth[assignedTo].converted_quote++;
                if (isLegitOrder(quote) || isPaidClient(clientData)) {
                  salesDataThisMonth[assignedTo].complete_order++;
                }
              }
            }
          });
        }
      }
    });

    // Helper function to calculate converting rate
    const calculateConvertingRate = (sale) =>
      sale.assigned_quote > 0 ? ((sale.complete_order / sale.converted_quote) * 100).toFixed(2) : '0.00';

    // Format all-time data
    const formattedSalesData = Object.values(salesData).map(sale => ({
      ...sale,
      converting_rate: calculateConvertingRate(sale),
      assigned_quote: sale.assigned_quote.toString(),
      converted_quote: sale.converted_quote.toString(),
      complete_order: sale.complete_order.toString(),
    }));

    // Format this month's data
    const formattedSalesDataThisMonth = Object.values(salesDataThisMonth).map(sale => ({
      ...sale,
      converting_rate: calculateConvertingRate(sale),
      assigned_quote: sale.assigned_quote.toString(),
      converted_quote: sale.converted_quote.toString(),
      complete_order: sale.complete_order.toString(),
    }));

    // Sort by converting_rate in descending order and take top 'limit' results
    const topSalesAllTime = formattedSalesData
      .sort((a, b) => b.complete_order - a.complete_order)
      .slice(0, limit);

    const topSalesThisMonth = formattedSalesDataThisMonth
      .sort((a, b) => b.complete_order - a.complete_order)
      .slice(0, limit);

    return {
      allTime: topSalesAllTime,
      thisMonth: topSalesThisMonth
    };
  });
};



// UTILS FUNCTION TO UPDATE OLD DATA STRUCTURE
export const encodeAllDocumentReferences = async () => {
  const encodeModel = (model) => model.replace(/\//g, '_');

  const itemsRef = collection(db, "ITEM-OTTAWA");

  try {
    const querySnapshot = await getDocs(itemsRef);

    for (const docSnapshot of querySnapshot.docs) {
      const oldData = docSnapshot.data();
      const oldModel = docSnapshot.id; // This is the original Model number
      // console.log('oldModel', oldModel);
      const newEncodedModel = encodeModel(oldModel);
      console.log('newEncodedModel', newEncodedModel);

      // If the Model is already encoded, skip this document
      if (oldModel === newEncodedModel) continue;

      const newDocRef = doc(db, "ITEM-OTTAWA", newEncodedModel);

      // Create new document with encoded Model as the document name
      await setDoc(newDocRef, {
        ...oldData,
        Model: oldModel, // Keep the original Model
        EncodedModel: newEncodedModel // Add the encoded Model
      });

      // Delete the old document
      await deleteDoc(doc(db, "ITEM-OTTAWA", oldModel));

      console.log(`Encoded document: ${oldModel} -> ${newEncodedModel}`);
    }

    console.log("All documents have been encoded successfully!");
  } catch (error) {
    console.error("Error encoding documents:", error);
    throw new Error("Error encoding documents: " + error.message);
  }
};

export const changeTransactionRecordsDate = async () => {
  const clientsCollection = collection(db, 'Clients');
  const clientsSnapshot = await getDocs(clientsCollection);

  const updatePromises = [];

  clientsSnapshot.forEach(clientDoc => {
    const clientData = clientDoc.data();
    console.log('updating', clientData.id);

    if (Array.isArray(clientData.transaction_records)) {
      const updatedRecords = clientData.transaction_records.map(record => {
        let dateTimestamp;
        if (record.date instanceof Timestamp) {
          dateTimestamp = record.date;
        } else if (typeof record.date === 'string') {
          // Convert string date to Timestamp
          const dateObj = new Date(record.date);
          dateTimestamp = Timestamp.fromDate(dateObj);
        } else if (record.date instanceof Date) {
          dateTimestamp = Timestamp.fromDate(record.date);
        } else {
          console.warn(`Unexpected date format for record:`, record);
          dateTimestamp = record.date;
        }

        return {
          ...record,
          date: dateTimestamp
        };
      });

      const updatedClientData = {
        ...clientData,
        transaction_records: updatedRecords
      };

      updatePromises.push(
        updateDoc(doc(clientsCollection, clientDoc.id), updatedClientData)
      );
    }
  });

  // Wait for all updates to complete
  await Promise.all(updatePromises);
  console.log('All updates completed');
};







export const updateItemsInInstallationRecord = async () => {
  const clientsCollection = collection(db, 'Clients');
  const clientsSnapshot = await getDocs(clientsCollection);

  const updatePromises = [];

  clientsSnapshot.forEach(clientDoc => {

    const clientData = clientDoc.data();
    console.log('updating', clientData.id);
    if (Array.isArray(clientData.quotes)) {
      clientData.quotes.forEach((quote, quoteIndex) => {
        if (Array.isArray(quote.install_record)) {
          quote.install_record.forEach((installRecord, installRecordIndex) => {
            if (Array.isArray(installRecord.product)) {
              // Create items array based on the existing product array
              const items = installRecord.product.map(product => ({
                id: product.id,
                model_number: product.model_number
              }));

              // Update the install_record with the new items array
              const updatedInstallRecord = {
                ...installRecord,
                items: items
              };

              // Prepare the updated quote
              const updatedQuote = {
                ...quote,
                install_record: [
                  ...quote.install_record.slice(0, installRecordIndex), // Elements before the one being updated
                  updatedInstallRecord, // The updated element
                  ...quote.install_record.slice(installRecordIndex + 1) // Elements after the one being updated
                ]
              };

              // Prepare the updated client data
              const updatedClientData = {
                ...clientData,
                quotes: [
                  ...clientData.quotes.slice(0, quoteIndex), // Quotes before the one being updated
                  updatedQuote, // The updated quote
                  ...clientData.quotes.slice(quoteIndex + 1) // Quotes after the one being updated
                ]
              };

              // Add the update promise to the array
              updatePromises.push(
                updateDoc(doc(clientsCollection, clientDoc.id), updatedClientData)
              );
            }
          });
        }
      });
    }
  });

  // Wait for all updates to complete
  await Promise.all(updatePromises);
  console.log('Firestore data structure updated successfully');
};

export const updatePurchaseBalances = async () => {
  const clientsCollection = collection(db, 'Clients');
  const clientsSnapshot = await getDocs(clientsCollection);

  const updatePromises = [];

  clientsSnapshot.forEach(clientDoc => {
    const clientData = clientDoc.data();
    console.log('updating', clientData.id);

    if (Array.isArray(clientData.quotes)) {
      // Find the converted quote
      const convertedQuote = clientData.quotes.find(quote => quote.is_converted === true);

      if (convertedQuote && Array.isArray(convertedQuote.product)) {
        // Sum up the prices in the product array
        const purchaseBalance = convertedQuote.product.reduce((sum, product) => {
          return sum + (product.price || 0);
        }, 0);

        // Prepare the updated client data
        const updatedClientData = {
          ...clientData,
          balances: {
            ...clientData.balances,
            purchase_balance: purchaseBalance
          }
        };

        // Add the update promise to the array
        updatePromises.push(
          updateDoc(doc(clientsCollection, clientDoc.id), updatedClientData)
        );
      }
    }
  });

  // Wait for all updates to complete
  await Promise.all(updatePromises);
  console.log('Purchase balances updated successfully');
};


// Fetch the total number of clients
export const fetchTotalClients = async () => {
  const clientsCollection = collection(db, 'Clients');
  const clientsSnapshot = await getDocs(clientsCollection);
  return clientsSnapshot.size;
};


export const changeclientLocation = async () => {

  const clientsCollection = collection(db, 'Clients');
  const clientsSnapshot = await getDocs(clientsCollection);

  // if client.assigns.assignToSale is "office ottawa", change location to "Ottawa"

  const updatePromises = [];

  clientsSnapshot.forEach(clientDoc => {

    const clientData = clientDoc.data();
    console.log('updating', clientData.id);
    if (clientData.assigns && clientData.assigns.assignToSale === "office ottawa") {
      const updatedClientData = {
        ...clientData,
        location: "Ottawa"
      };
      // Add the update promise to the array
      updatePromises.push(
        updateDoc(doc(clientsCollection, clientDoc.id), updatedClientData)
      );
    }
  });
}





// ROLE
export const fetchRoles = async (email) => {
  const roles = [];
  try {
    const q = query(collection(db, 'Users'), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      roles.push(data.roles);
    });

    return roles;
  } catch (error) {
    console.error('Error fetching roles:', error);
    throw new Error('Failed to fetch roles');
  }
}

// AUTH
export const auth = getAuth(app);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: 'select_account'
});

export const signInWithGoogle = async () => {
  const userAgent = window.navigator.userAgent;
  const isSafari = userAgent.includes('Safari') && !userAgent.includes('Chrome');
  console.log('User Agent:', userAgent);
  console.log('Is Safari:', isSafari);

  if (isSafari) {
    try {
      await document.requestStorageAccess();
      console.log('Storage access granted, proceeding with sign-in.');
      return await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Storage access denied or error signing in with Google:', error);
      throw new Error('Failed to sign in with Google due to storage access denial.');
    }
  } else {
    try {
      console.log('Non-Safari browser, proceeding with sign-in.');
      return await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error signing in with Google:', error);
      throw new Error('Failed to sign in with Google due to an unknown error.');
    }
  }
};

export const handleRedirectResult = async () => {
  try {
    const result = await getRedirectResult(auth);
    if (result && result.user) {
      return result.user;
    }
    return null;
  } catch (error) {
    console.error('Error getting redirect result:', error);
    throw new Error('Failed to get redirect result');
  }
};

setPersistence(auth, indexedDBLocalPersistence)
  .then(() => {
    console.log('Auth persistence set to browserSessionPersistence');
  })
  .catch((error) => {
    console.error('Error setting auth persistence:', error);
  });


export const signOutUser = () => {
  return signOut(auth);
};

export { onAuthStateChanged };

// Log

const logDocumentChange = async ({
  userId,
  action,
  documentType,
  before,
  after,
  clientFullName = '',
  clientAddress = '',
  clientId = '',
}) => {
  const logEntry = {
    userId,
    action,
    timestamp: new Date(),
    documentType,
    before,
    after,
    clientFullName,
    clientAddress,
    clientId,
  };

  const logsCollectionRef = collection(db, 'Logs_test'); // Reference to the collection
  await addDoc(logsCollectionRef, logEntry); // Add a new document to the collection
};

// CLIENT MANAGEMENT
const getClients = async () => {
  const querySnapshot = await getDocs(collection(db, 'Clients'));
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const addClient = async (client) => {
  const user = getUser();
  const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';
  // console.log('userName', userName);
  try {
    const clientDoc = doc(db, 'Clients', client.id); // Use the client.id as the document ID
    await setDoc(clientDoc, client);
    // console.log('Client added successfully with custom ID:', client.id);

    // Log the addition
    await logDocumentChange({
      userId: userName, // TODO: Get the current user
      action: "create",
      documentType: "Client",
      before: [], // No before data for a new document
      after: [client], // Wrap the after data in a list for consistency with the logging format
      clientFullName: `${client.first_name} ${client.last_name}`,
      clientAddress: client.address,
      clientId: client.id,
    });
  } catch (error) {
    console.error('Error adding client:', error);
    throw new Error('Failed to add client');
  }
};

const updateClient = async (clientId, updatedClient) => {

  try {
    const clientRef = doc(db, 'Clients', clientId);
    const docSnapshot = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    if (docSnapshot.exists()) {
      // Capture the document's data before the update
      const beforeData = docSnapshot.data();
      const afterData = { ...updatedClient };

      // Update the client document

      await updateDoc(clientRef, updatedClient);
      console.log('Client updated successfully');

      // Log the update
      await logDocumentChange({
        userId: userName, // TODO: Get the current user
        action: "update",
        documentType: "Client",
        before: [beforeData], // Wrap the before data in a list
        after: [afterData], // Wrap the after data in a list
        clientFullName: `${beforeData.first_name} ${beforeData.last_name}`,
        clientAddress: beforeData.address,
        clientId: clientRef.id,
      });
    } else {
      console.log('Document does not exist with ID:', clientId);
    }
  } catch (error) {
    console.error('Error updating client:', error);
    throw new Error('Failed to update client');
  }
};

export const addContractInfoToClient = async (clientId, quoteID, contract_id, contract_sent_date, contract_status) => {
  if (!clientId || !quoteID || !contract_id || !contract_sent_date || !contract_status) {
    throw new Error('All parameters are required');
  }

  try {
    const clientRef = doc(db, 'Clients', clientId);
    const docSnapshot = await getDoc(clientRef);

    if (!docSnapshot.exists()) {
      throw new Error('Client document does not exist');
    }

    const clientData = docSnapshot.data();

    if (typeof clientData.quotes !== 'object' || clientData.quotes === null) {
      throw new Error('Quotes data is not an object or is missing');
    }

    const quote = clientData.quotes[quoteID];

    if (!quote) {
      throw new Error('Quote not found');
    }

    quote.contract_id = contract_id;
    quote.event_created = contract_sent_date;
    quote.contract_status = contract_status;

    // Update the client document with the modified quotes object
    await updateDoc(clientRef, {
      quotes: clientData.quotes
    });

    console.log('Contract info added successfully');
  } catch (error) {
    console.error('Error updating client contract info:', error);
    throw new Error('Failed to update client contract info');
  }
};

export const fetchFilteredClients = async (filters) => {
  const clientQuery = collection(db, 'Clients');
  let q = clientQuery;

  // Apply base filters first
  if (filters.CreatedFromValue) {
    q = query(q, where('created_at', '>=', new Date(filters.CreatedFromValue)));
  }
  if (filters.CreatedToValue) {
    q = query(q, where('created_at', '<=', new Date(filters.CreatedToValue)));
  }
  if (filters.sales) {
    q = query(q, where('assigns.assignToSale', '==', filters.sales));
  }
  if (filters.source) {
    q = query(q, where('source', '==', filters.source));
  }
  if (filters.location) {
    q = query(q, where('location', '==', filters.location));
  }

  // Fetch clients based on base filters
  const querySnapshot = await getDocs(q);
  const clients = [];
  querySnapshot.forEach((doc) => {
    clients.push({ id: doc.id, ...doc.data() });
  });

  // Further filtering on client side due to Firestore query limitations
  const filteredClients = clients.filter(client => {
    let match = true;

    // Convert client.quotes to an array if it's not already one
    const quotes = Array.isArray(client.quotes) ? client.quotes :
      (client.quotes ? [client.quotes] : []);
    // Filter for PurchaseFromValue and PurchaseToValue
    // if (filters.PurchaseFromValue || filters.PurchaseToValue) {
    //   const convertedQuote = quotes.find(quote => quote.is_converted);
    //   if (convertedQuote && convertedQuote.converting_date) {
    //     let convertingDate;
    //     if (convertedQuote.converting_date.toDate) {
    //       // If it's a Firestore Timestamp, convert it to a Date object
    //       convertingDate = convertedQuote.converting_date.toDate();
    //     } else {
    //       // Otherwise, assume it's a string and convert it to a Date object
    //       convertingDate = new Date(convertedQuote.converting_date);
    //     }

    //     if (filters.PurchaseFromValue && convertingDate < new Date(filters.PurchaseFromValue)) {
    //       match = false;
    //     }
    //     if (filters.PurchaseToValue && convertingDate > new Date(filters.PurchaseToValue)) {
    //       match = false;
    //     }
    //   } else {
    //     match = false;
    //   }
    // }
    // Filter for PurchaseFromValue and PurchaseToValue
    if (filters.PurchaseFromValue || filters.PurchaseToValue) {
      console.log(filters.PurchaseFromValue + " " + filters.PurchaseToValue);
      if (client.transaction_records && Array.isArray(client.transaction_records)) {
        const purchaseMatch = client.transaction_records.some(record => {
          // Ensure record.date is a Timestamp
          if (!(record.date instanceof Timestamp)) {
            console.warn('Record date is not a Timestamp:', record.date);
            return false;
          }

          // Convert Timestamp to Date for comparison
          const recordDate = record.date.toDate();

          // Parse the filter dates
          const fromDate = filters.PurchaseFromValue ? new Date(filters.PurchaseFromValue) : null;
          const toDate = filters.PurchaseToValue ? new Date(filters.PurchaseToValue) : null;

          // Adjust the toDate to include the entire day
          if (toDate) {
            toDate.setHours(23, 59, 59, 999);
          }

          return (!fromDate || recordDate >= fromDate) &&
            (!toDate || recordDate <= toDate);
        });

        if (!purchaseMatch) {
          match = false;
        }
      } else {
        match = false;
      }
    }

    // Filter for InstallFromValue and InstallToValue
    if (filters.InstallFromValue || filters.InstallToValue) {
      const convertedQuote = quotes.find(quote => quote.is_converted);
      if (convertedQuote && convertedQuote.install_record) {
        const installMatch = convertedQuote.install_record.some(record => {
          const fromTime = record.from_time?.toDate ? record.from_time.toDate() : new Date(record.from_time);
          const toTime = record.to_time?.toDate ? record.to_time.toDate() : new Date(record.to_time);
          return (!filters.InstallFromValue || fromTime >= new Date(filters.InstallFromValue)) &&
            (!filters.InstallToValue || toTime <= new Date(filters.InstallToValue));
        });
        if (!installMatch) {
          match = false;
        }
      } else {
        match = false;
      }
    }

    // Filter for ModelNumber
    if (filters.ModelNumber) {
      const convertedQuote = quotes.find(quote => quote.is_converted);
      if (convertedQuote && convertedQuote.product) {
        const modelMatch = convertedQuote.product.some(product => product.model_number === filters.ModelNumber);
        if (!modelMatch) {
          match = false;
        }
      } else {
        match = false;
      }
    }

    const TOLERANCE = 1;
    // Filter for Balance
    if (filters.balance) {
      const convertedQuote = quotes.find(quote => quote.is_converted);
      if (convertedQuote) {
        const extraCostTotal = Array.isArray(convertedQuote.install_record)
          ? convertedQuote.install_record.reduce((sum, record) => sum + (record.extra_cost || 0), 0)
          : 0;
        const subtotal = (client.balances.purchase_balance + client.balances.service_balance) * 1.13;
        const totalBalance = (subtotal + extraCostTotal) - client.balances.paid;
        if (client.balances.purchase_balance === 0) {
          match = false;
        }
        if (filters.balance.name === 'Outstanding Balance' && totalBalance <= TOLERANCE) {
          match = false;
        }
        if (filters.balance.name === 'Clear Balance' && totalBalance > TOLERANCE) {
          match = false;
        }
      } else {
        match = false;
      }
    }

    // Filter for Tech
    if (filters.tech) {
      const convertedQuote = quotes.find(quote => quote.is_converted);
      if (convertedQuote && convertedQuote.install_record) {
        const techMatch = convertedQuote.install_record.some(record => {
          if (typeof record.assigned_to_tech === 'string') {
            return record.assigned_to_tech === filters.tech;
          } else if (record.assigned_to_tech instanceof Map) {
            return record.assigned_to_tech.get('name') === filters.tech;
          }
          return false;
        });
        if (!techMatch) {
          match = false;
        }
      } else {
        match = false;
      }
    }

    return match;
  });

  return filteredClients;
};

// jobsite info 
export const addJobsiteInfoToClient = async (clientId, quoteId, jobsiteInfo) => {
  if (!clientId || !quoteId || !jobsiteInfo) {
    throw new Error('ClientId, quoteId, and jobsiteInfo are required');
  }

  try {
    const clientRef = doc(db, 'Clients', clientId);
    const docSnapshot = await getDoc(clientRef);

    if (!docSnapshot.exists()) {
      throw new Error('Client document does not exist');
    }

    const clientData = docSnapshot.data();


    if (!clientData.quotes || typeof clientData.quotes !== 'object') {
      throw new Error('Quotes data is not an object or is missing');
    }

    if (!clientData.quotes[quoteId]) {
      // console.log('Available quote IDs:', Object.keys(clientData.quotes));
      throw new Error(`Quote not found for ID: ${quoteId}`);
    }

    // Update the quotes object with the new jobsite info
    const updatedQuotes = {
      ...clientData.quotes,
      [quoteId]: {
        ...clientData.quotes[quoteId],
        jobsite_info: jobsiteInfo
      }
    };

    await updateDoc(clientRef, { quotes: updatedQuotes });

    console.log('Jobsite info added successfully');
    return true;
  } catch (error) {
    console.error('Error adding client jobsite info:', error);
    throw error;
  }
};
export const updateJobsiteInfoForClient = async (clientId, quoteId, updatedJobsiteInfo) => {
  if (!clientId || !quoteId || !updatedJobsiteInfo) {
    throw new Error('ClientId, quoteId, and updatedJobsiteInfo are required');
  }

  try {
    const clientRef = doc(db, 'Clients', clientId);
    const docSnapshot = await getDoc(clientRef);

    if (!docSnapshot.exists()) {
      throw new Error('Client document does not exist');
    }

    const clientData = docSnapshot.data();

    if (!clientData.quotes || typeof clientData.quotes !== 'object') {
      throw new Error('Quotes data is not an object or is missing');
    }

    if (!clientData.quotes[quoteId]) {
      throw new Error('Quote not found');
    }

    if (!clientData.quotes[quoteId].jobsite_info) {
      throw new Error('Jobsite info not found for this quote');
    }

    // Update the quotes object with the updated jobsite info
    const updatedQuotes = {
      ...clientData.quotes,
      [quoteId]: {
        ...clientData.quotes[quoteId],
        jobsite_info: {
          ...clientData.quotes[quoteId].jobsite_info,
          ...updatedJobsiteInfo
        }
      }
    };

    await updateDoc(clientRef, { quotes: updatedQuotes });

    console.log('Jobsite info updated successfully');
    return true;
  } catch (error) {
    console.error('Error updating client jobsite info:', error);
    throw error;
  }
};

export const deleteJobsiteInfoFromClient = async (clientId, quoteId) => {
  if (!clientId || !quoteId) {
    throw new Error('ClientId and quoteId are required');
  }

  try {
    const clientRef = doc(db, 'Clients', clientId);
    const docSnapshot = await getDoc(clientRef);

    if (!docSnapshot.exists()) {
      throw new Error('Client document does not exist');
    }

    const clientData = docSnapshot.data();

    if (!clientData.quotes || typeof clientData.quotes !== 'object') {
      throw new Error('Quotes data is not an object or is missing');
    }

    if (!clientData.quotes[quoteId]) {
      throw new Error('Quote not found');
    }

    if (!clientData.quotes[quoteId].jobsite_info) {
      throw new Error('Jobsite info not found for this quote');
    }

    // Create a new quotes object without the jobsite_info
    const updatedQuotes = { ...clientData.quotes };
    const updatedQuote = { ...updatedQuotes[quoteId] };
    delete updatedQuote.jobsite_info;
    updatedQuotes[quoteId] = updatedQuote;

    // Update the client document with the modified quotes object
    await updateDoc(clientRef, { quotes: updatedQuotes });

    console.log('Jobsite info deleted successfully');
    return true;
  } catch (error) {
    console.error('Error deleting client jobsite info:', error);
    throw error;
  }
};

// Service Management
export const addEmptyService = async (clientId, serviceId) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);

    // Define the service structure
    const serviceStructure = {
      id: serviceId,
      products: [],
      date: serverTimestamp(),
      service_note: '',
      // service_status: 'Pending',
      service_records: [],
    };

    // Update the document
    await updateDoc(clientRef, {
      [`services.${serviceId}`]: serviceStructure
    });

    console.log('Empty service added successfully');
  } catch (error) {
    console.error('Error adding empty service:', error);
    throw new Error('Failed to add empty service');
  }
};

export const updateService = async (clientId, serviceId, updatedService) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const docSnapshot = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';


    if (docSnapshot.exists()) {
      // Capture the document's data before the update
      const clientData = docSnapshot.data();
      const beforeData = clientData.services ? clientData.services[serviceId] : null;
      const afterData = { ...updatedService };

      // Update the service data within the client document
      await updateDoc(clientRef, {
        [`services.${serviceId}`]: updatedService
      });
      console.log('Service updated successfully');

      // Log the update
      await logDocumentChange({
        userId: userName, // TODO: Get the current user
        action: "update",
        documentType: "Service",
        before: beforeData ? [beforeData] : [], // Wrap the before data in a list
        after: [afterData], // Wrap the after data in a list
        clientFullName: `${clientData.first_name} ${clientData.last_name}`,
        clientAddress: clientData.address,
        clientId: clientRef.id,
      });
    } else {
      console.log('Client document does not exist with ID:', clientId);
    }
  } catch (error) {
    console.error('Error updating service:', error);
    throw new Error('Failed to update service');
  }
};

export const updateServiceBalance = async (clientId, service_subtotal) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const docSnapshot = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    if (docSnapshot.exists()) {
      // Capture the document's data before the update
      const clientData = docSnapshot.data();
      const beforeData = clientData.balances ? { ...clientData.balances } : {};

      // Calculate the new service balance by adding to the existing balance
      const currentBalance = beforeData.service_balance || 0;
      const newBalance = currentBalance + service_subtotal;

      const afterData = { ...beforeData, service_balance: newBalance };

      // Update the service balance within the client document
      await updateDoc(clientRef, {
        'balances.service_balance': increment(service_subtotal)
      });
      console.log('Service balance updated successfully');

      // Log the update
      await logDocumentChange({
        userId: userName,
        action: "update",
        documentType: "ServiceBalance",
        before: beforeData ? [beforeData] : [],
        after: [afterData],
        clientFullName: `${clientData.first_name} ${clientData.last_name}`,
        clientAddress: clientData.address,
        clientId: clientRef.id,
      });
    } else {
      console.log('Client document does not exist with ID:', clientId);
    }
  } catch (error) {
    console.error('Error updating service balance:', error);
    throw new Error('Failed to update service balance');
  }
};

export const saveServiceRecord = async (clientId, serviceId, serviceRecord) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      // Ensure the services map is defined
      if (!clientData.services) {
        clientData.services = {};
      }

      const service = clientData.services[serviceId];

      if (!service) {
        throw new Error('No service found with the provided ID');
      }

      // Capture the service records before the update
      const beforeData = service.service_records ? [...service.service_records] : [];

      // Add the service record to the service
      if (!service.service_records) {
        service.service_records = [];
      }

      service.service_records.push(serviceRecord);

      // Save the updated client data back to Firestore
      await updateDoc(clientRef, { [`services.${serviceId}`]: service });

      console.log('Service record saved successfully');

      // Log the addition
      await logDocumentChange({
        userId: userName,
        action: "create",
        documentType: "ServiceRecord",
        before: beforeData, // Do not wrap in another array
        after: service.service_records, // Do not wrap in another array
        clientFullName: `${clientData.first_name} ${clientData.last_name}`,
        clientAddress: clientData.address,
        clientId: clientRef.id,
      });
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error saving service record:', error);
    throw new Error('Failed to save service record');
  }
};
export const updateServiceRecord = async (clientId, serviceId, updatedRecordId, updatedRecord) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      // Find the service
      if (clientData.services && clientData.services[serviceId]) {
        const service = clientData.services[serviceId];

        if (service.service_records) {
          // Find the service record by ID
          const recordIndex = service.service_records.findIndex(record => record.id === updatedRecordId);

          if (recordIndex !== -1) {
            // Capture the before data
            const beforeData = { ...service.service_records[recordIndex] };

            // Update the specific service record
            service.service_records[recordIndex] = {
              ...service.service_records[recordIndex],
              ...updatedRecord
            };

            // Update the client document in Firestore
            await updateDoc(clientRef, { [`services.${serviceId}`]: service });

            console.log('Service record updated successfully');

            // Log the update
            await logDocumentChange({
              userId: userName, // TODO: Get the current user
              action: "update",
              documentType: "ServiceRecord",
              before: [beforeData],
              after: [service.service_records[recordIndex]],
              clientFullName: `${clientData.first_name} ${clientData.last_name}`,
              clientAddress: clientData.address,
              clientId: clientRef.id,
            });
          } else {
            throw new Error('No service record found with the provided ID');
          }
        } else {
          throw new Error('No service records found in the service');
        }
      } else {
        throw new Error('No service found with the provided ID');
      }
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error updating service record:', error);
    throw new Error('Failed to update service record');
  }
};
export const deleteService = async (clientId, serviceId) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      // Capture the service data before the deletion
      const beforeData = clientData.services ? { ...clientData.services[serviceId] } : {};

      // Delete the specific service
      delete clientData.services[serviceId];

      // Update the client document in Firestore
      await updateDoc(clientRef, { services: clientData.services });

      // update client service balance
      const serviceBalance = beforeData.products.reduce((sum, product) => sum + product.price, 0);
      await updateDoc(clientRef, {
        'balances.service_balance': increment(-serviceBalance)
      });


      console.log('Service deleted successfully');

      // Log the deletion
      await logDocumentChange({
        userId: userName,
        action: "delete",
        documentType: "Service",
        before: [beforeData],
        after: [], // No after data for a deleted document
        clientFullName: `${clientData.first_name} ${clientData.last_name}`,

        clientAddress: clientData.address,
        clientId: clientRef.id,
      });
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error deleting service:', error);
    throw new Error('Failed to delete service');
  }
};

export const deleteServiceRecord = async (clientId, serviceId, recordId) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      // Find the service
      if (clientData.services && clientData.services[serviceId]) {
        const service = clientData.services[serviceId];

        if (service.service_records) {
          // Find the service record by ID
          const recordIndex = service.service_records.findIndex(record => record.id === recordId);

          if (recordIndex !== -1) {
            // Capture before state
            const beforeData = [...service.service_records];

            // Remove the specific service record
            service.service_records.splice(recordIndex, 1);

            // Update the client document in Firestore
            await updateDoc(clientRef, { [`services.${serviceId}`]: service });

            // Log the deletion
            await logDocumentChange({
              userId: userName,
              action: 'delete',
              documentType: 'Service Record',
              before: beforeData,
              after: service.service_records,
              clientFullName: `${clientData.first_name} ${clientData.last_name}`,
              clientAddress: clientData.address,
              clientId: clientId,
            });

            console.log('Service record deleted successfully');
          } else {
            throw new Error('No service record found with the provided ID');
          }
        } else {
          throw new Error('No service records found in the service');
        }
      } else {
        throw new Error('No service found with the provided ID');
      }
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error deleting service record:', error);
    throw new Error('Failed to delete service record');
  }
};

export const markServiceRecordAsComplete = async (clientId, serviceId, recordId) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      // Find the service
      if (clientData.services && clientData.services[serviceId]) {
        const service = clientData.services[serviceId];

        if (service.service_records) {
          // Find the service record by ID
          const recordIndex = service.service_records.findIndex(record => record.id === recordId);

          if (recordIndex !== -1) {
            // Update the specific service record

            service.service_records[recordIndex].status = 'Completed';

            // Update the client document in Firestore
            await updateDoc(clientRef, { [`services.${serviceId}`]: service });

            console.log('Service record marked as complete');
          } else {
            throw new Error('No service record found with the provided ID');
          }
        } else {
          throw new Error('No service records found in the service');
        }
      } else {
        throw new Error('No service found with the provided ID');
      }
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error updating service record:', error);
    throw new Error('Failed to update service record');
  }
};



const deleteClient = async (clientId) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      await deleteDoc(clientRef);

      console.log('Client deleted successfully');

      await logDocumentChange({
        userId: userName,
        action: "delete",
        documentType: "Client",
        before: [clientData], // Log the client data before deletion
        after: [], // No after data for a deleted document
        clientFullName: `${clientData.first_name} ${clientData.last_name}`,
        clientAddress: clientData.address,
        clientId: clientRef.id,
      });
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error deleting client:', error);
    throw new Error('Failed to delete client');
  }
};

const getClientById = async (clientId) => {
  // console.log('clientId', clientId);
  const clientRef = doc(db, 'Clients', clientId);
  const clientSnap = await getDoc(clientRef);
  // console.log('clientSnap', clientSnap.data);
  if (clientSnap.exists()) {
    return { id: clientSnap.id, ...clientSnap.data() };
  } else {
    throw new Error('No such client!');
  }
};

export const updateClientQuotes = async (clientId, quotes) => {
  // console.log('Updating client with ID:', quotes);
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      // Capture before state
      const beforeData = clientData.quotes || [];

      // Update the client's quotes
      await updateDoc(clientRef, { quotes });

      // Log the update
      await logDocumentChange({
        userId: userName,
        action: 'update',
        documentType: 'Client Quotes',
        before: beforeData,
        after: quotes,
        clientFullName: `${clientData.first_name} ${clientData.last_name}`,
        clientAddress: clientData.address,
        clientId: clientId,
      });

      console.log('Client quotes updated successfully');
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error updating client quotes:', error);
    throw new Error('Failed to update client quotes');
  }
};

export const updateClientQuotewithId = async (clientId, quoteId, updatedQuote) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      // Capture before state
      const beforeData = clientData.quotes && clientData.quotes[quoteId] ? clientData.quotes[quoteId] : {};

      // Update the specific quote
      await updateDoc(clientRef, {
        [`quotes.${quoteId}`]: updatedQuote
      });

      // Log the update
      await logDocumentChange({
        userId: userName,
        action: 'update',
        documentType: 'Client Purchase',
        before: [beforeData],
        after: [updatedQuote],
        clientFullName: `${clientData.first_name} ${clientData.last_name}`,
        clientAddress: clientData.address,
        clientId: clientId,
      });

      console.log('Quote updated successfully');
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error updating quote:', error);
    throw new Error('Failed to update quote');
  }
};

export const fetchSales = async () => {
  const technicians = [];
  try {
    const q = query(collection(db, 'Users'), where('roles', 'array-contains-any', ['Sales', 'Sales Manager']));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      technicians.push({
        name: `${data.firstName} ${data.lastName}`,
        value: `${data.id}`
      });
    });

    return technicians;
  } catch (error) {
    console.error('Error fetching sales:', error);
    throw new Error('Failed to fetch sales');
  }
};

export const updatePurchaseBalance = async (clientId, purchaseBalance) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      // Capture before state
      const beforeData = {
        purchase_balance: clientData.balances ? clientData.balances.purchase_balance : 0
      };

      // Update purchase balance
      await updateDoc(clientRef, {
        'balances.purchase_balance': purchaseBalance
      });

      // Capture after state
      const afterData = {
        purchase_balance: purchaseBalance
      };

      // Log the update
      await logDocumentChange({
        userId: userName,
        action: 'update',
        documentType: 'Purchase Balance',
        before: [beforeData],
        after: [afterData],
        clientFullName: `${clientData.first_name} ${clientData.last_name}`,
        clientAddress: clientData.address,
        clientId: clientId,
      });

      console.log('Purchase balance updated successfully');
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error updating purchase balance:', error);
    throw new Error('Failed to update purchase balance');
  }
};

const updateContractNotes = async (clientId, contractNote) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    await updateDoc(clientRef, {
      'notes.contract_note': contractNote
    });
    console.log('Office note updated successfully');
  } catch (error) {
    console.error('Error updating office note:', error);
    throw new Error('Failed to update office note');
  }
};

export const addFile = async (clientId, urls) => {
  const clientRef = doc(db, 'Clients', clientId);
  await updateDoc(clientRef, {
    jobsite_urls: arrayUnion(...urls)
  });
};

export const updateAuditNotes = async (clientId, updatedQuoteId, auditNote) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      if (clientData.quotes && clientData.quotes[updatedQuoteId]) {
        // Update only the audit_note in the specific quote
        clientData.quotes[updatedQuoteId].audit_note = auditNote;

        // Update the client document in Firestore
        await updateDoc(clientRef, { quotes: clientData.quotes });

        console.log('Audit note updated successfully');
      } else {
        throw new Error(`No quote found with the provided ID: ${updatedQuoteId}`);
      }
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error updating audit note:', error);
    throw new Error('Failed to update audit note');
  }
};

export const updateAuditEmailSentDate = async (clientId, updatedQuoteId, emailSentDate) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      if (clientData.quotes && clientData.quotes[updatedQuoteId]) {
        // Update only the audit_email_sent_date in the specific quote
        clientData.quotes[updatedQuoteId].audit_email_sent_date = emailSentDate;

        // Update the client document in Firestore
        await updateDoc(clientRef, { quotes: clientData.quotes });

        console.log('Audit email sent date updated successfully');
      } else {
        throw new Error(`No quote found with the provided ID: ${updatedQuoteId}`);
      }
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error updating audit email sent date:', error);
    throw new Error('Failed to update audit email sent date');
  }
};


// INSTALLATION MANAGEMENT
export const saveInstallationRecord = async (clientId, convertedQuoteId, installationRecord) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      // Ensure the quotes map is defined
      if (!clientData.quotes) {
        clientData.quotes = {};
      }

      const convertedQuote = clientData.quotes[convertedQuoteId];

      if (!convertedQuote) {
        throw new Error('No converted quote found with the provided ID');
      }

      // Capture before state
      const beforeData = {
        install_record: convertedQuote.install_record ? [...convertedQuote.install_record] : []
      };

      // Add the installation record to the converted quote
      if (!convertedQuote.install_record) {
        convertedQuote.install_record = [];
      }

      convertedQuote.install_record.push(installationRecord);

      // Save the updated client data back to Firestore
      await updateDoc(clientRef, { [`quotes.${convertedQuoteId}`]: convertedQuote });

      // Capture after state
      const afterData = {
        install_record: [...convertedQuote.install_record]
      };

      // Log the update
      await logDocumentChange({
        userId: userName,
        action: 'update',
        documentType: 'Installation Record',
        before: [beforeData],
        after: [afterData],
        clientFullName: `${clientData.first_name} ${clientData.last_name}`,
        clientAddress: clientData.address,
        clientId: clientId,
      });

      console.log('Installation record saved successfully');
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error saving installation record:', error);
    throw new Error('Failed to save installation record');
  }
};
export const updateInstallationRecord = async (clientId, convertedQuoteId, updatedRecordId, updatedRecord) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      // Find the converted quote
      if (clientData.quotes && clientData.quotes[convertedQuoteId]) {
        const convertedQuote = clientData.quotes[convertedQuoteId];

        if (convertedQuote.install_record) {
          // Find the installation record by ID
          const installationIndex = convertedQuote.install_record.findIndex(record => record.id === updatedRecordId);

          if (installationIndex !== -1) {
            // Capture before state
            const beforeData = {
              ...convertedQuote.install_record[installationIndex]
            };

            // Update the specific installation record
            convertedQuote.install_record[installationIndex] = {
              ...convertedQuote.install_record[installationIndex],
              ...updatedRecord
            };

            // Update the client document in Firestore
            await updateDoc(clientRef, { [`quotes.${convertedQuoteId}`]: convertedQuote });

            // Capture after state
            const afterData = {
              ...convertedQuote.install_record[installationIndex]
            };

            // Log the update
            await logDocumentChange({
              userId: userName,
              action: 'update',
              documentType: 'Installation Record',
              before: [beforeData],
              after: [afterData],
              clientFullName: `${clientData.first_name} ${clientData.last_name}`,
              clientAddress: clientData.address,
              clientId: clientId,
            });

            console.log('Installation record updated successfully');
          } else {
            throw new Error('No installation record found with the provided ID');
          }
        } else {
          throw new Error('No installations found in the converted quote');
        }
      } else {
        throw new Error('No converted quote found with the provided ID');
      }
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error updating installation record:', error);
    throw new Error('Failed to update installation record');
  }
};
export const markRecordAsComplete = async (clientId, convertedQuoteId, updatedRecordId) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      // Find the converted quote
      if (clientData.quotes && clientData.quotes[convertedQuoteId]) {
        const convertedQuote = clientData.quotes[convertedQuoteId];

        if (convertedQuote.install_record) {
          // Find the installation record by ID
          const installationIndex = convertedQuote.install_record.findIndex(record => record.id === updatedRecordId);

          if (installationIndex !== -1) {
            // Update the specific installation record
            convertedQuote.install_record[installationIndex].is_completed = true;

            // Update the client document in Firestore
            await updateDoc(clientRef, { [`quotes.${convertedQuoteId}`]: convertedQuote });

            console.log('Installation marked as complete');
          } else {
            throw new Error('No installation record found with the provided ID');
          }
        } else {
          throw new Error('No installations found in the converted quote');
        }
      } else {
        throw new Error('No converted quote found with the provided ID');
      }
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error updating installation record:', error);
    throw new Error('Failed to update installation record');
  }
};

export const deleteInstallationRecord = async (clientId, convertedQuoteId, recordId) => {
  try {
    const clientRef = doc(db, 'Clients', clientId);
    const clientSnap = await getDoc(clientRef);

    if (clientSnap.exists()) {
      const clientData = clientSnap.data();

      // Find the converted quote
      if (clientData.quotes && clientData.quotes[convertedQuoteId]) {
        const convertedQuote = clientData.quotes[convertedQuoteId];

        if (convertedQuote.install_record) {
          // Find the installation record by ID
          const installationIndex = convertedQuote.install_record.findIndex(record => record.Id === recordId);

          if (installationIndex !== -1) {
            // Remove the specific installation record
            convertedQuote.install_record.splice(installationIndex, 1);

            // Update the client document in Firestore
            await updateDoc(clientRef, { [`quotes.${convertedQuoteId}`]: convertedQuote });

            console.log('Installation record deleted successfully');
          } else {
            throw new Error('No installation record found with the provided ID');
          }
        } else {
          throw new Error('No installations found in the converted quote');
        }
      } else {
        throw new Error('No converted quote found with the provided ID');
      }
    } else {
      throw new Error('No such client!');
    }
  } catch (error) {
    console.error('Error deleting installation record:', error);
    throw new Error('Failed to delete installation record');
  }
};

export const fetchTechnicians = async () => {
  const technicians = [];
  try {
    const q = query(collection(db, 'Users'), where('roles', 'array-contains', 'Technician'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      technicians.push({
        name: `${data.firstName} ${data.lastName}`,
        value: `${data.id}`
      });
    });

    return technicians;
  } catch (error) {
    console.error('Error fetching technicians:', error);
    throw new Error('Failed to fetch technicians');
  }
};

export const deleteQuote = async (clientId, quoteId) => {
  if (!clientId || !quoteId) {
    throw new Error('Both client ID and quote ID are required.');
  }

  try {
    const clientDocRef = doc(db, 'Clients', clientId);
    const clientDoc = await getDoc(clientDocRef);

    if (!clientDoc.exists()) {
      throw new Error('Client document does not exist!');
    }

    const clientData = clientDoc.data();
    const quotes = clientData.quotes || {};

    if (!quotes[quoteId]) {
      throw new Error('Quote not found in client data.');
    }

    // Delete the quote from the quotes map
    delete quotes[quoteId];

    // Update the client document with the modified quotes map
    await updateDoc(clientDocRef, { quotes });

    console.log('Quote successfully deleted!');
  } catch (error) {
    console.error('Error deleting quote:', error);
    throw new Error('Error deleting quote: ' + error.message);
  }
};
// USER MANAGEMENT

export const addUser = async (userData) => {
  // Generate a unique 4-digit id for the user
  const id = Math.floor(1000 + Math.random() * 9000).toString();

  try {
    const docRef = doc(db, 'Users', id);
    await setDoc(docRef, {
      id,
      firstName: userData.firstName,
      lastName: userData.lastName,
      phone: userData.phone || '',
      email: userData.email,
      location: userData.location.name,
      roles: userData.roles.map(role => role.name),
      officeNote: userData.officeNote || '',
      isDisabled: userData.isDisabled,
      createdAt: userData.createdAt,
    });
    console.log('Document written with ID: ', id);
  } catch (e) {
    console.error('Error adding document: ', e);
    throw new Error('Error creating user');
  }
};

export const fetchUsers = async () => {
  const querySnapshot = await getDocs(collection(db, 'Users'));
  const users = [];
  querySnapshot.forEach((doc) => {
    users.push({ id: doc.id, ...doc.data() });
  });
  return users;
};

export const updateUser = async (id, data) => {
  const userDoc = doc(db, 'Users', id);
  await updateDoc(userDoc, data);
};

export const deleteUser = async (id) => {
  const userDoc = doc(db, 'Users', id);
  await deleteDoc(userDoc);
};


// ADMIN MANAGEMENT
export const addSource = async (form) => {
  try {
    // console.log('Form data:', form); // Add this line
    if (!form.id || !form.name || !form.type || !form.description) {
      throw new Error('Form data is incomplete');
    }

    const docRef = doc(db, 'Sources', form.id);
    await setDoc(docRef, {
      id: form.id,
      name: form.name,
      type: form.type,
      description: form.description,
      createdAt: new Date().toLocaleDateString(),
      notes: form.note || '',
    });
    // console.log('Document written with ID: ', form.name);
  } catch (e) {
    console.error('Error adding document: ', e);
    throw new Error('Error creating source');
  }
};

export const fetchSources = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'Sources'));
    const sources = [];
    querySnapshot.forEach((doc) => {
      sources.push({ id: doc.id, ...doc.data() });
    });
    return sources;
  } catch (error) {
    console.error('Error fetching sources:', error);
    throw new Error('Failed to fetch sources');
  }
};

export const updateSource = async (reocrd) => {
  try {
    const sourceDoc = doc(db, 'Sources', reocrd.id);
    await updateDoc(sourceDoc, reocrd);
  } catch (error) {
    console.error('Error updating source:', error);
    throw new Error('Failed to update source');
  }
};

export const deleteSource = async (id) => {
  try {
    const sourceDoc = doc(db, 'Sources', id);
    await deleteDoc(sourceDoc);
  } catch (error) {
    console.error('Error deleting source:', error);
    throw new Error('Failed to delete source');
  }
};


export const addSupplier = async (form) => {
  try {
    // console.log('Form data:', form); // Add this line
    if (!form.id || !form.Name) {
      throw new Error('Form data is incomplete');
    }

    const docRef = doc(db, 'Supplier', form.id);
    await setDoc(docRef, {
      id: form.id,
      Name: form.Name,
      Address: form.Address || '',
      Contact: form.Contact || '',
      Email: form.Email || '',
      Phone: form.Phone || '',
      Since: form.Since,
    });
    // console.log('Document written with ID: ', form.name);
  } catch (e) {
    console.error('Error adding document: ', e);
    throw new Error('Error creating supplier');
  }
};

export const fetchSuppliers = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'Suppliers'));
    const suppliers = [];
    querySnapshot.forEach((doc) => {
      suppliers.push({ id: doc.id, ...doc.data() });
    });
    return suppliers;
  } catch (error) {
    console.error('Error fetching suppliers:', error);
    throw new Error('Failed to fetch suppliers');
  }
};

export const updateSupplier = async (reocrd) => {
  try {
    const supplierDoc = doc(db, 'Supplier', reocrd.id);
    await updateDoc(supplierDoc, reocrd);
  } catch (error) {
    console.error('Error updating supplier:', error);
    throw new Error('Failed to update supplier');
  }
};

export const deleteSupplier = async (id) => {
  try {
    const supplierDoc = doc(db, 'Supplier', id);
    await deleteDoc(supplierDoc);
  } catch (error) {
    console.error('Error deleting supplier:', error);
    throw new Error('Failed to delete supplier');
  }
};

export const CopyAndAssignIdToDoc = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'Supplier'));
    const batch = writeBatch(db); // Initialize the batch

    querySnapshot.forEach((docSnapshot) => {
      const data = docSnapshot.data();
      const docRef = doc(db, 'Supplier', docSnapshot.id);

      // Add the set operation to the batch
      batch.set(docRef, {
        id: docSnapshot.id, // Assigning the document ID to the new id field
        Address: data.Address || '',
        Contact: data.Contact || '',
        Email: data.Email || '',
        Name: data.Name || '', // Corrected from NameL to Name
        Phone: data.Phone || '',
        Since: data.Since || '',
      });
    });

    // Commit the batch
    await batch.commit();
    console.log('Documents successfully copied and assigned IDs');
  } catch (error) {
    console.error('Error copying and assigning:', error);
    throw new Error('Failed to copy and assign');
  }
};





// ACCOUNTING MANAGEMENT

export const deleteTransactionRecord = async (clientId, recordId) => {
  const clientDocRef = doc(db, 'Clients', clientId);
  const user = getUser();
  const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

  try {
    await runTransaction(db, async (transaction) => {
      // Get the client document
      const clientDoc = await transaction.get(clientDocRef);

      if (!clientDoc.exists()) {
        throw new Error("Client document does not exist!");
      }

      const clientData = clientDoc.data();
      const transactionRecords = clientData.transaction_records || [];

      // Find the transaction record with the specified recordId
      const recordToDelete = transactionRecords.find(record => record.id === recordId);
      if (!recordToDelete) {
        throw new Error("Transaction record not found!");
      }

      // Subtract the record's amount from the balances.paid field
      const newPaidAmount = (clientData.balances['paid'] || 0) - recordToDelete.amount;

      // Remove the transaction record from the transaction_records array
      const updatedTransactionRecords = transactionRecords.filter(record => record.id !== recordId);

      // Log the current state before deletion
      const beforeState = {
        balances: clientData.balances,
        transaction_records: transactionRecords,
      };

      // Update the client document in Firestore
      transaction.update(clientDocRef, {
        'balances.paid': newPaidAmount,
        transaction_records: updatedTransactionRecords
      });

      // Log the state after deletion
      const afterState = {
        balances: {
          ...clientData.balances,
          paid: newPaidAmount
        },
        transaction_records: updatedTransactionRecords,
      };

      // Log the deletion
      await logDocumentChange({
        userId: userName,
        action: 'delete',
        documentType: 'Transaction Record',
        before: [beforeState],
        after: [afterState],
        clientFullName: `${clientData.first_name} ${clientData.last_name}`,
        clientAddress: clientData.address,
        clientId: clientId,
      });

    });

    console.log('Transaction record successfully deleted!');
  } catch (e) {
    console.error('Transaction failed: ', e);
    throw new Error('Failed to delete transaction record');
  }
};

export const appendRecordToFirestore = async (clientId, form) => {
  const user = getUser();
  const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

  const record = {
    amount: form.amount,
    date: new Date(),
    id: Math.floor(1000 + Math.random() * 9000).toString().padStart(4, '0'), // Random product ID
    payment_type: form.type.name,
    reference_number: form.referenceNumber,
    transaction_by: userName,
    transaction_note: form.note,
  };

  const clientDocRef = doc(db, 'Clients', clientId);

  try {
    await runTransaction(db, async (transaction) => {
      // Get the client document
      const clientDoc = await transaction.get(clientDocRef);

      if (!clientDoc.exists()) {
        throw new Error("Client document does not exist!");
      }

      const clientData = clientDoc.data();

      // Capture the state before the update
      const beforeState = {
        balances: clientData.balances,
        transaction_records: clientData.transaction_records || [],
      };

      // Update the balances.paid field
      const newPaidAmount = (clientData.balances['paid'] || 0) + form.amount;
      transaction.update(clientDocRef, {
        'balances.paid': newPaidAmount,
        transaction_records: arrayUnion(record)
      });

      // Capture the state after the update
      const afterState = {
        balances: {
          ...clientData.balances,
          paid: newPaidAmount
        },
        transaction_records: [...(clientData.transaction_records || []), record],
      };

      // Log the append action
      await logDocumentChange({
        userId: userName,
        action: 'append',
        documentType: 'Transaction Record',
        before: [beforeState],
        after: [afterState],
        clientFullName: `${clientData.first_name} ${clientData.last_name}`,
        clientAddress: clientData.address,
        clientId: clientId,
      });

    });

    console.log('Transaction successfully committed!');
  } catch (e) {
    console.error('Transaction failed: ', e);
    throw new Error('Failed to append record');
  }
};


export const updateTransactionRecordInFirestore = async (clientId, record) => {
  const clientDocRef = doc(db, 'Clients', clientId);
  console.log('record', record);

  // Check if the payment type is an object
  if (typeof record.payment_type === 'object') {
    record.payment_type = record.payment_type.name;
  }

  const user = getUser();
  const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

  try {
    await runTransaction(db, async (transaction) => {
      // Get the client document
      const clientDoc = await transaction.get(clientDocRef);

      if (!clientDoc.exists()) {
        throw new Error("Client document does not exist!");
      }

      const clientData = clientDoc.data();

      // Find the old record
      const oldRecord = clientData.transaction_records.find(r => r.id === record.id);
      if (!oldRecord) {
        throw new Error("Record not found!");
      }

      // Calculate the new paid amount
      const newPaidAmount = (clientData.balances.paid || 0) - oldRecord.amount + record.amount;

      // Capture the state before the update
      const beforeState = {
        balances: clientData.balances,
        transaction_records: clientData.transaction_records || [],
      };

      // Remove the old record and add the updated record
      transaction.update(clientDocRef, {
        transaction_records: arrayRemove(oldRecord),
        'balances.paid': newPaidAmount
      });

      transaction.update(clientDocRef, {
        transaction_records: arrayUnion(record)
      });

      // Capture the state after the update
      const afterState = {
        balances: {
          ...clientData.balances,
          paid: newPaidAmount
        },
        transaction_records: [
          ...clientData.transaction_records.filter(r => r.id !== record.id),
          record
        ],
      };

      // Log the update action
      await logDocumentChange({
        userId: userName,
        action: 'update',
        documentType: 'Transaction Record',
        before: [beforeState],
        after: [afterState],
        clientFullName: `${clientData.first_name} ${clientData.last_name}`,
        clientAddress: clientData.address,
        clientId: clientId,
      });
    });

    console.log('Transaction successfully committed!');
  } catch (e) {
    console.error('Transaction failed: ', e);
  }
};

// Commission Functions
export const fetchSalesData = async (selectedLocation, selectedMonth) => {
  try {
    // 1. Fetch all sales in the selected location
    const usersRef = collection(db, 'Users');
    const salesQuery = query(usersRef, 
      where('roles', 'array-contains', 'Sales'),
      where('location', '==', selectedLocation)
    );
    const salesSnapshot = await getDocs(salesQuery);
    const salespeople = salesSnapshot.docs.map(doc => ({
      fullName: doc.data().firstName + ' ' + doc.data().lastName,
      ...doc.data()
    }));

    // 2. Set up date range for the selected month
    const startOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
    const endOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0, 23, 59, 59);
    const startTimestamp = Timestamp.fromDate(startOfMonth);
    const endTimestamp = Timestamp.fromDate(endOfMonth);

    // 3. Fetch legitimate orders for each salesperson
    const clientsRef = collection(db, 'Clients');
    const salesData = await Promise.all(salespeople.map(async (salesperson) => {
      const clientsQuery = query(clientsRef, where('assigns.assignToSale', '==', salesperson.fullName));
      const clientsSnapshot = await getDocs(clientsQuery);

      let legitimateOrders = 0;
      let legitimateOrdersDetail = [];
      let totalCommission = 0;
      let totalUnits = 0;
      let totalCommissionTobePaid = 0;
      let totalPaidOrders = 0;

      for (const clientDoc of clientsSnapshot.docs) {
        const clientData = clientDoc.data();
        const isPaidClientWithClearBalanceStatus = isPaidClientWithClearBalance(clientData);

        if (clientData.quotes) {
          let quotesToProcess = Array.isArray(clientData.quotes) ? clientData.quotes : Object.values(clientData.quotes);
          
          for (const quote of quotesToProcess) {
            if (quote.is_converted === true) {
              if (quote.date instanceof Timestamp &&
                  quote.date >= startTimestamp &&
                  quote.date < endTimestamp) {
                if (isLegitOrder(quote) || isPaidClientWithClearBalanceStatus) {
                  legitimateOrders++;
                  
                  let orderTotalCommission = 0;
                  const products = await Promise.all((quote.product || []).map(async p => {
                    const baseCommission = await getCommission(p.model_number);
                    const basePrice = await getBasePrice(p.model_number);
                    const unit = await getUnit(p.model_number) || 1; // Default to 1 if unit is not available
                    const upSaleAmount = p.price - basePrice;
                    let upSaleCommission = 0;
                    let totalProductCommission = baseCommission;
                    
                    if (upSaleAmount > 0) {
                      upSaleCommission = 0.4 * upSaleAmount;
                      totalProductCommission += upSaleCommission;
                    } else {
                      // If upSaleAmount is negative
                      upSaleCommission = upSaleAmount;
                      totalProductCommission += upSaleCommission;
                    }

                    orderTotalCommission += totalProductCommission;
                    totalCommission += totalProductCommission;
                    totalUnits += unit;

                    return {
                      name: p.model_number,
                      category: p.category,
                      price: p.price,
                      base_price: basePrice,
                      baseCommission: baseCommission,
                      upSaleAmount: upSaleAmount,
                      upSaleCommission: upSaleCommission,
                      totalProductCommission: totalProductCommission,
                      unit: unit,
                    };
                  }));

                  if (isPaidClientWithClearBalanceStatus) {
                    totalPaidOrders++;
                    totalCommissionTobePaid += orderTotalCommission;
                  }

                  legitimateOrdersDetail.push({
                    clientName: clientData.first_name + ' ' + clientData.last_name,
                    quoteId: quote.id,
                    date: quote.date.toDate(),
                    totalAmount: calculateTotalAmount(quote) * 1.13,
                    products: products,
                    isPaid: isPaidClientWithClearBalanceStatus,
                    totalProductCommission: orderTotalCommission
                  });
                }
              }
            }
          }
        }
      }

      return {
        salesperson: salesperson.fullName,
        legitimateOrders,
        legitimateOrdersDetail,
        totalCommission,
        totalCommissionTobePaid,
        totalUnits,
        totalPaidOrders
      };
    }));

    return salesData;
  } catch (error) {
    console.error('Error fetching sales data:', error);
    throw error;
  }
};


const isPaidClientWithClearBalance = (clientData) => {
  const tolerance = 1;
  return (
    clientData.balances &&
    Math.abs(clientData.balances.purchase_balance * 1.13 - clientData.balances.paid ) <= tolerance &&
    clientData.balances.paid > 0
  );
};

const getBasePrice = async (modelNumber) => {
  const itemRef = doc(db, 'ITEM-OTTAWA', modelNumber);
  const itemSnapshot = await getDoc(itemRef);
  return itemSnapshot.exists() ? itemSnapshot.data().BASE_PRICE : 0;
};

const getCommission = async (modelNumber) => {
  const itemRef = doc(db, 'ITEM-OTTAWA', modelNumber);
  const itemSnapshot = await getDoc(itemRef);
  return itemSnapshot.exists() ? itemSnapshot.data().COMMISSION : 0;
};

const getUnit = async (modelNumber) => {
  const itemRef = doc(db, 'ITEM-OTTAWA', modelNumber);
  const itemSnapshot = await getDoc(itemRef);
  return itemSnapshot.exists() ? itemSnapshot.data().Unit : '';
};

const calculateTotalAmount = (quote) => {
  return quote.product ? quote.product.reduce((total, product) => total + (product.price || 0), 0) : 0;
};

// INVENTORY MANAGEMENT
export const updateItemInFirestore = async (editRecord, oldModelNumber) => {
  const encodeModel = (model) => model.replace(/\//g, '_');
  if (!editRecord || !editRecord.Model) {
    throw new Error("Invalid record. The record must contain a 'Model' field.");
  }

  const oldEncodedModel = encodeModel(oldModelNumber);
  const newEncodedModel = encodeModel(editRecord.Model);

  const oldDocRef = doc(db, "ITEM-OTTAWA", oldEncodedModel);
  const newDocRef = doc(db, "ITEM-OTTAWA", newEncodedModel);

  try {
    if (editRecord.Model !== oldModelNumber) {
      // Delete the old document if the model number has changed
      await deleteDoc(oldDocRef);

      // Create a new document with the new model number
      await setDoc(newDocRef, {
        Brand: editRecord.Brand,
        Category: editRecord.Category.name,
        Description: editRecord.Description,
        Model: editRecord.Model,
        COST_PRE_ITEM: editRecord.COST_PRE_ITEM,
        BASE_PRICE: editRecord.BASE_PRICE,
        COMMISSION: editRecord.COMMISSION,
        IN_STOCK: editRecord.IN_STOCK,
        Inactive: editRecord.Inactive,
        ON_HOLD: editRecord.ON_HOLD,
        OUT: editRecord.OUT,
        Service_Item: editRecord.Service_Item,
        Supplier: editRecord.Supplier,
        Unique_Serial_Number: editRecord.Unique_Serial_Number,
        Universal_Serial_Number: editRecord.Universal_Serial_Number,
        Unit: editRecord.Unit,
        // Add any other fields you want to update
      });
    } else {
      // Update the existing document if the model number hasn't changed
      await updateDoc(oldDocRef, {
        Brand: editRecord.Brand,
        Category: editRecord.Category.name,
        Model: editRecord.Model,
        Description: editRecord.Description,
        COST_PRE_ITEM: editRecord.COST_PRE_ITEM,
        BASE_PRICE: editRecord.BASE_PRICE,
        COMMISSION: editRecord.COMMISSION,
        IN_STOCK: editRecord.IN_STOCK,
        Inactive: editRecord.Inactive,
        ON_HOLD: editRecord.ON_HOLD,
        OUT: editRecord.OUT,
        Service_Item: editRecord.Service_Item,
        Supplier: editRecord.Supplier,
        Unique_Serial_Number: editRecord.Unique_Serial_Number,
        Universal_Serial_Number: editRecord.Universal_Serial_Number,
        Unit: editRecord.Unit,
        // Add any other fields you want to update
      });
    }
    console.log("Document successfully updated!");
  } catch (e) {
    console.error("Error updating document: ", e);
    throw new Error("Error updating document: " + e.message);
  }
};

export const deleteItemFromFirestore = async (modelNumber) => {
  if (!modelNumber) {
    throw new Error("Invalid model number. The model number must be provided.");
  }

  const encodedModel = modelNumber.replace(/\//g, '_');
  const docRef = doc(db, "ITEM-OTTAWA", encodedModel);

  try {
    await deleteDoc(docRef);
    console.log("Document successfully deleted!");
  } catch (e) {
    console.error("Error deleting document: ", e);
    throw new Error("Error deleting document: " + e.message);
  }
};

export const addItemToFirestore = async (newItem) => {
  if (!newItem || !newItem.Model) {
    throw new Error("Invalid item. The item must contain a 'Model' field.");
  }

  // Encode the Model number by replacing '/' with '_'
  const encodedModel = newItem.Model.replace(/\//g, '_');

  const docRef = doc(db, "ITEM-OTTAWA", encodedModel);

  try {
    await setDoc(docRef, {
      Brand: newItem.Brand,
      Model: newItem.Model,
      Category: newItem.Category.name,
      Description: newItem.Description,
      COST_PRE_ITEM: newItem.COST_PRE_ITEM,
      BASE_PRICE: newItem.BASE_PRICE,
      COMMISSION: newItem.COMMISSION,
      IN_STOCK: 0,
      Inactive: false,
      ON_HOLD: 0,
      OUT: 0,
      Service_Item: false,
      Supplier: "",
      Unique_Serial_Number: true,
      Universal_Serial_Number: '',
      Unit: newItem.Unit,
      // Add any other fields you want to add
    });
    console.log("Document successfully added!");
  } catch (e) {
    console.error("Error adding document: ", e);
    throw new Error("Error adding document: " + e.message);
  }
};


//INVENTORY MANAGEMENT
export const updateSerialNumberById = async (recordId, newSerialNumber) => {
  if (!recordId || !newSerialNumber) {
    throw new Error("Both recordId and newSerialNumber are required.");
  }

  const inventoryCollection = collection(db, "INVENTORY-OTTAWA");
  const q = query(inventoryCollection, where("id", "==", recordId));

  try {
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      throw new Error("No matching documents found.");
    }

    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    querySnapshot.forEach(async (document) => {
      const docRef = doc(db, "INVENTORY-OTTAWA", document.id);
      const beforeData = document.data();

      await updateDoc(docRef, {
        Serial_Number: newSerialNumber,
      });

      const afterData = { ...beforeData, Serial_Number: newSerialNumber };

      await logDocumentChange({
        userId: userName,
        action: 'update',
        documentType: 'Inventory',
        before: [beforeData],
        after: [afterData],
        clientId: document.id, // Use the document ID as clientId for logging
      });
    });

    console.log("Serial number successfully updated!");
  } catch (e) {
    console.error("Error updating serial number: ", e);
    throw new Error("Error updating serial number: " + e.message);
  }
};

export const updateLocationById = async (recordId, newLocation) => {
  if (!recordId || !newLocation) {
    throw new Error("Both recordId and newLocation are required.");
  }

  const inventoryCollection = collection(db, "INVENTORY-OTTAWA");
  const q = query(inventoryCollection, where("id", "==", recordId));

  try {
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      throw new Error("No matching documents found.");
    }

    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    querySnapshot.forEach(async (document) => {
      const docRef = doc(db, "INVENTORY-OTTAWA", document.id);
      const beforeData = document.data();

      await updateDoc(docRef, {
        Location: newLocation,
      });

      const afterData = { ...beforeData, Location: newLocation };

      await logDocumentChange({
        userId: userName,
        action: 'update',
        documentType: 'Inventory',
        before: [beforeData],
        after: [afterData],
        clientId: document.id, // Use the document ID as clientId for logging
      });
    });

    console.log("Location successfully updated!");
  } catch (e) {
    console.error("Error updating location: ", e);
    throw new Error("Error updating location: " + e.message);
  }
};

export const deleteRecordById = async (recordId) => {
  if (!recordId) {
    throw new Error("Record ID is required.");
  }

  const inventoryCollection = collection(db, "INVENTORY-OTTAWA");
  const q = query(inventoryCollection, where("id", "==", recordId));

  try {
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      throw new Error("No matching documents found.");
    }

    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    querySnapshot.forEach(async (document) => {
      const docRef = doc(db, "INVENTORY-OTTAWA", document.id);
      const beforeData = document.data();

      // Delete the document
      await deleteDoc(docRef);

      // Log the deletion
      await logDocumentChange({
        userId: userName,
        action: 'delete',
        documentType: 'Inventory',
        before: [beforeData],
        after: [], // No after data for a delete operation
        clientId: document.id, // Use the document ID as clientId for logging
      });
    });

    console.log("Record successfully deleted!");
  } catch (e) {
    console.error("Error deleting record: ", e);
    throw new Error("Error deleting record: " + e.message);
  }
};

export const saveInventoryRecordToFirestore = async (record) => {
  if (!record || !record.id) {
    throw new Error("Invalid record. The record must contain an 'id' field.");
  }

  const docRef = doc(db, 'INVENTORY-OTTAWA', record.id);

  try {
    // Fetch the current document to get the "before" state
    const docSnap = await getDoc(docRef);
    const beforeData = docSnap.exists() ? docSnap.data() : null;

    // Save the new record
    await setDoc(docRef, record, { merge: true });
    console.log("Document successfully saved!");

    // Log the save operation
    const user = getUser();
    const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

    await logDocumentChange({
      userId: userName,
      action: beforeData ? 'update' : 'create', // Determine if it's a create or update action
      documentType: 'Inventory',
      before: beforeData ? [beforeData] : [], // Wrap the before data in an array
      after: [record], // Wrap the after data in an array
      clientId: record.id, // Use the document ID as clientId for logging
    });
  } catch (e) {
    console.error("Error saving document: ", e);
    throw new Error("Error saving document: " + e.message);
  }
};
export const fetchAssignedSerialNumbers = async (clientID) => {
  if (!clientID) {
    throw new Error('Client ID is required to fetch assigned serial numbers.');
  }

  try {
    const inventoryRef = collection(db, 'INVENTORY-OTTAWA');
    const q = query(inventoryRef, where('Client_Id', '==', clientID));
    const querySnapshot = await getDocs(q);
    const serialNumbers = [];

    querySnapshot.forEach((doc) => {
      serialNumbers.push(doc.data());
    });

    return serialNumbers;
  } catch (error) {
    console.error('Error fetching assigned serial numbers:', error);
    throw new Error('Error fetching assigned serial numbers: ' + error.message);
  }
};
export const fetchSNforItem = async (modelNumber, clientID) => {
  if (!modelNumber || !clientID) {
    throw new Error('Model number and client ID are required to fetch serial numbers.');
  }

  try {
    const inventoryRef = collection(db, 'INVENTORY-OTTAWA');
    const q = query(inventoryRef, where('Model', '==', modelNumber));
    const querySnapshot = await getDocs(q);
    let serialNumber = '';

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.Serial_Number && data.Client_Id === clientID) {
        serialNumber = data.Serial_Number;
      }
    });

    return serialNumber;
  } catch (error) {
    console.error('Error fetching serial numbers:', error);
    throw new Error('Error fetching serial numbers: ' + error.message);
  }
};

export const updateInventoryWithClientID = async (recordId, clientId, orderNumber, serialNumber, modelNumber) => {
  if (!recordId || !clientId || !orderNumber || !serialNumber || !modelNumber) {
    throw new Error('All parameters are required.');
  }

  const inventoryCollection = collection(db, 'INVENTORY-OTTAWA');
  const inventoryQuery = query(inventoryCollection, where('id', '==', recordId));
  const user = getUser();
  const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

  try {
    const inventorySnapshot = await getDocs(inventoryQuery);
    if (inventorySnapshot.empty) {
      throw new Error('No matching inventory documents found.');
    }

    const inventoryUpdatePromises = [];
    let beforeInventoryData = [];
    let afterInventoryData = [];

    inventorySnapshot.forEach((document) => {
      const docRef = doc(db, 'INVENTORY-OTTAWA', document.id);
      const beforeData = document.data();
      beforeInventoryData.push(beforeData);

      const updatedData = {
        ...beforeData,
        Client_Id: clientId,
        Serial_Number: serialNumber,
        WAREHOUSE_OUT_BY: userName,
        WAREHOUSE_OUT_DATE: new Date().toLocaleDateString(),
        Status: 'Out',
        Record_Status: 'warehouse_out',
        Order_number: orderNumber
      };
      afterInventoryData.push(updatedData);

      inventoryUpdatePromises.push(updateDoc(docRef, updatedData));
    });

    await Promise.all(inventoryUpdatePromises);

    // Now update the client's quote product with the assigned serial number
    const clientDocRef = doc(db, 'Clients', clientId);
    const clientDoc = await getDoc(clientDocRef);
    if (!clientDoc.exists()) {
      throw new Error('Client document does not exist!');
    }

    const clientData = clientDoc.data();
    const quotes = clientData.quotes || {};
    const quote = quotes[orderNumber];
    if (!quote) {
      throw new Error('Quote not found in client data.');
    }

    if (!quote.product || !Array.isArray(quote.product)) {
      throw new Error('Products not found in quote or products is not an array.');
    }

    const product = quote.product.find(product => product.model_number === modelNumber);
    if (!product) {
      throw new Error('Product not found in quote.');
    }

    const beforeQuoteProductData = { ...product };
    product.assigned_sn = serialNumber;
    const afterQuoteProductData = { ...product };

    // Update the client document with the modified quotes
    await updateDoc(clientDocRef, { quotes });

    // Log the inventory updates
    await logDocumentChange({
      userId: userName,
      action: 'update',
      documentType: 'Inventory',
      before: beforeInventoryData,
      after: afterInventoryData,
      clientId: clientId
    });

    // Log the quote product update
    await logDocumentChange({
      userId: userName,
      action: 'update',
      documentType: 'Client Quote Product',
      before: [beforeQuoteProductData],
      after: [afterQuoteProductData],
      clientId: clientId
    });

    console.log('Document successfully updated!');
  } catch (error) {
    console.error('Error updating documents:', error);
    throw new Error('Error updating documents: ' + error.message);
  }
};


export const revokeRecord = async (client_id, quoteId, product) => {
  const model_number = product.model_number;
  const assigned_sn = product.assigned_sn;
  const user = getUser();
  const userName = user ? user.displayName || `${user.firstName} ${user.lastName}` : '';

  try {
    // Step 1: Remove assigned serial number from product in the quote
    const clientRef = doc(db, 'Clients', client_id);
    const clientDoc = await getDoc(clientRef);

    if (!clientDoc.exists()) {
      throw new Error('Client not found');
    }

    const clientData = clientDoc.data();
    const quotes = clientData.quotes;
    let beforeQuoteProductData = [];
    let afterQuoteProductData = [];

    if (quotes && quotes[quoteId]) {
      const quote = quotes[quoteId];
      const productIndex = quote.product.findIndex(p => p.model_number === model_number && p.assigned_sn === assigned_sn);

      if (productIndex !== -1) {
        beforeQuoteProductData.push({ ...quote.product[productIndex] });
        quote.product[productIndex].assigned_sn = ""; // Remove the assigned serial number
        afterQuoteProductData.push({ ...quote.product[productIndex] });
      }

      await updateDoc(clientRef, { quotes });

      // Log the quote product update
      await logDocumentChange({
        userId: userName,
        action: 'update',
        documentType: 'Client Quote Product',
        before: beforeQuoteProductData,
        after: afterQuoteProductData,
        clientId: client_id
      });
    } else {
      throw new Error('Quote not found');
    }

    // Step 2: Update the inventory record
    const inventoryCollection = collection(db, 'INVENTORY-OTTAWA');
    const q = query(inventoryCollection, where('Model', '==', model_number), where('Client_Id', '==', client_id));
    const querySnapshot = await getDocs(q);

    let beforeInventoryData = [];
    let afterInventoryData = [];

    querySnapshot.forEach(async (document) => {
      const docRef = doc(db, 'INVENTORY-OTTAWA', document.id);
      const beforeData = document.data();
      beforeInventoryData.push(beforeData);

      const updatedData = {
        ...beforeData,
        Client_Id: "",
        WAREHOUSE_OUT_BY: "",
        WAREHOUSE_OUT_DATE: "N/A",
        Status: 'In Stock',
        Record_Status: 'warehouse_in',
        Order_number: "N/A"
      };
      afterInventoryData.push(updatedData);

      await updateDoc(docRef, updatedData);
    });

    // Log the inventory updates
    await logDocumentChange({
      userId: userName,
      action: 'revoke',
      documentType: 'Inventory',
      before: beforeInventoryData,
      after: afterInventoryData,
      clientId: client_id
    });

    console.log('Record successfully revoked');
  } catch (error) {
    console.error('Error revoking record:', error);
    throw new Error('Error revoking record: ' + error.message);
  }
};

export { getClients, updateClient, deleteClient, getClientById, updateContractNotes };