import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import { auth } from '@/service/FirestoreService'; // Ensure this path is correct
import { useUser } from '@/composables/useUser';
import { fetchRoles } from '@/service/FirestoreService';
import { toRaw } from 'vue';

const adminGuard = async (to, from, next) => {
    const userState = useUser();
    const user = userState.getUser();

    try {
        let userRoles = user.roles;

        if (!userRoles) {
            userRoles = await fetchRoles(user.email);

            // console.log('User roles dasd:', userRoles);
            userState.setUser({ ...user, roles: userRoles });
        }


        const isAdmin = Array.isArray(userRoles) && userRoles.some(roleArray => roleArray.includes('Admin'));

        if (isAdmin) {
            next();
        } else {
            next('/clients/view-all'); // Adjust this path as needed
        }
    } catch (error) {
        console.error('Error fetching user roles:', error);
        next('/error'); // Redirect to an error page or handle the error as appropriate
    }
};



const routes = [
    {
        path: '/',
        component: AppLayout,
        children: [
            {
                path: '/',
                name: 'e-commerce',
                meta: {
                    breadcrumb: ['Main Dashboard'],
                    requiresAdmin: true
                },
                component: () => import('@/views/dashboards/dashboard.vue'),

                beforeEnter: adminGuard // Add the guard here
            },
            {
                path: '/dashboard-banking',
                name: 'dashboard-banking',
                meta: {
                    breadcrumb: ['Banking Dashboard']
                },
                component: () => import('@/views/dashboards/Banking.vue')
            },

            // clients
            {
                path: '/clients/view-all',
                name: '/clients/view-all',
                meta: {
                    breadcrumb: ['Clients', 'View All']
                },
                component: () => import('@/views/clients/viewALL.vue')
            },
            {
                path: '/clients/:id',
                name: 'ClientDetail',
                props: true, // Ensure this is true to pass route params as props
                meta: {
                    breadcrumb: ['Clients', 'Detail'],
                    // requiresAuth: true // Just an example if you want to use meta fields in the global guard
                },
                component: () => import('@/views/clients/detail.vue'),
                props: true
            },

            {
                path: '/clients/create',
                name: 'ClientCreate',
                meta: {
                    breadcrumb: ['Clients', 'Create']
                },
                component: () => import('@/views/clients/create.vue')
            },
            {
                path: 'clients/report',
                name: 'clients-report',
                meta: {
                    breadcrumb: ['Clients', 'Report']
                },
                component: () => import('@/views/clients/report.vue')
            },

            // Inventory
            {
                path: '/inventory/view-items',
                name: '/inventory/view-items',
                meta: {
                    breadcrumb: ['Inventory', 'View Items']
                },
                component: () => import('@/views/Inventory/items.vue')
            },
            {
                path: '/inventory/create-item',
                name: '/inventory/create-item',
                meta: {
                    breadcrumb: ['Inventory', 'Create Item']
                },
                component: () => import('@/views/Inventory/add-item.vue')
            },
            {
                path: '/inventory/search-sn',
                name: '/inventory/search-sn',
                meta: {
                    breadcrumb: ['Inventory', 'Search SN']
                },
                component: () => import('@/views/Inventory/search-sn.vue')
            },
            {
                path: '/inventory/warehouse-in',
                name: '/inventory/warehouse-in',
                meta: {
                    breadcrumb: ['Inventory', 'Warehouse In']
                },
                component: () => import('@/views/Inventory/warehouse-in.vue')
            },
            {
                path: '/inventory/warehouse-out',
                name: '/inventory/warehouse-out',
                meta: {
                    breadcrumb: ['Inventory', 'Warehouse out']
                },
                component: () => import('@/views/Inventory/warehouse-out.vue')
            },

            // accounting
            {
                path: '/accounting/search-transaction',
                name: '/accounting/search-transaction',
                meta: {
                    breadcrumb: ['Accounting', 'Search Transactions']
                },
                component: () => import('@/views/accounting/search-transaction.vue')
            },
            {
                path: '/accounting/create-new-transaction',
                name: '/accounting/create-new-transaction',
                meta: {
                    breadcrumb: ['Accounting', 'Create Transaction']
                },
                component: () => import('@/views/accounting/create-transaction.vue')
            },

            // Admin Route
            {
                path: '/accounting/commission-report',
                name: '/accounting/commission-report',
                meta: {
                    breadcrumb: ['Accounting', 'Commission Report']
                },
                component: () => import('@/views/accounting/commission-report.vue')

            },

            {
                path: '/admin/user-management',
                name: '/admin/user-management',
                meta: {
                    breadcrumb: ['Admin', 'User Management']
                },
                component: () => import('@/views/admin/userManagement.vue')
            },
            {
                path: '/admin/logs',
                name: '/admin/logs',
                meta: {
                    breadcrumb: ['Admin', 'Logs']
                },
                component: () => import('@/views/admin/logs.vue')
            },
            {
                path: '/admin/add-user',
                name: '/admin/add-user',
                meta: {
                    breadcrumb: ['Admin', 'Add User']
                },
                component: () => import('@/views/admin/addUser.vue')
            },
            {
                path: '/admin/add-source',
                name: '/admin/add-source',
                meta: {
                    breadcrumb: ['Admin', 'Add Source']
                },
                component: () => import('@/views/admin/addSource.vue')
            },
            {
                path: '/admin/add-supplier',
                name: '/admin/add-supplier',
                meta: {
                    breadcrumb: ['Admin', 'Add Supplier']
                },
                component: () => import('@/views/admin/addSupplier.vue')
            },

            // Office Route
            {
                path: '/office/add-supplier',
                name: '/office/add-supplier',
                meta: {
                    breadcrumb: ['Office', 'Add Supplier']
                },
                component: () => import('@/views/office/addSupplier.vue')
            },
            {
                path: '/office/add-source',
                name: '/office/add-source',
                meta: {
                    breadcrumb: ['Office', 'Add Source']
                },
                component: () => import('@/views/office/addSource.vue')
            },
            {
                path: '/office/add-user',
                name: '/office/add-user',
                meta: {
                    breadcrumb: ['Office', 'Add User']
                },
                component: () => import('@/views/office/addUser.vue')
            },

            {
                path: '/apps/blog/list',
                component: () => import('@/views/apps/blog/List.vue')
            },
            {
                path: '/apps/blog/detail',
                component: () => import('@/views/apps/blog/Detail.vue')
            },
            {
                path: '/apps/blog/edit',
                name: 'blog-edit',
                component: () => import('@/views/apps/blog/Edit.vue')
            },
            {
                path: '/apps/calendar',
                name: 'calendar',
                component: () => import('@/views/apps/Calendar.vue')
            },
            {
                path: '/apps/files',
                name: 'files',
                component: () => import('@/views/apps/Files.vue')
            },
            {
                path: '/apps/chat',
                name: 'chat',
                component: () => import('@/views/apps/chat/Index.vue')
            },
            {
                path: '/apps/tasklist',
                name: 'tasklist',
                component: () => import('@/views/apps/tasklist/Index.vue')
            },
            {
                path: '/apps/mail',

                component: () => import('@/views/apps/mail/Index.vue'),
                children: [
                    {
                        path: '/apps/mail/inbox',
                        name: 'mail-inbox',
                        component: () => import('@/views/apps/mail/MailTypes.vue')
                    },
                    {
                        path: '/apps/mail/compose',
                        name: 'mail-compose',
                        component: () => import('@/views/apps/mail/ComposeNew.vue')
                    },
                    {
                        path: '/apps/mail/detail/:id',
                        name: 'mail-detail',
                        component: () => import('@/views/apps/mail/Detail.vue')
                    },
                    {
                        path: '/apps/mail/starred',
                        component: () => import('@/views/apps/mail/MailTypes.vue')
                    },
                    {
                        path: '/apps/mail/spam',
                        component: () => import('@/views/apps/mail/MailTypes.vue')
                    },
                    {
                        path: '/apps/mail/important',
                        component: () => import('@/views/apps/mail/MailTypes.vue')
                    },
                    {
                        path: '/apps/mail/sent',
                        component: () => import('@/views/apps/mail/MailTypes.vue')
                    },
                    {
                        path: '/apps/mail/archived',
                        component: () => import('@/views/apps/mail/MailTypes.vue')
                    },
                    {
                        path: '/apps/mail/trash',
                        component: () => import('@/views/apps/mail/MailTypes.vue')
                    }
                ]
            },
            {
                path: '/uikit/formlayout',
                name: 'formlayout',
                meta: {
                    breadcrumb: ['UI Kit', 'Form Layout']
                },
                component: () => import('@/views/uikit/FormLayout.vue')
            },
            {
                path: '/uikit/input',
                name: 'input',
                meta: {
                    breadcrumb: ['UI Kit', 'Input']
                },
                component: () => import('@/views/uikit/Input.vue')
            },
            {
                path: '/uikit/floatlabel',
                name: 'floatlabel',
                meta: {
                    breadcrumb: ['UI Kit', 'Float Label']
                },
                component: () => import('@/views/uikit/FloatLabel.vue')
            },
            {
                path: '/uikit/invalidstate',
                name: 'invalidstate',
                meta: {
                    breadcrumb: ['UI Kit', 'Invalid State']
                },
                component: () => import('@/views/uikit/InvalidState.vue')
            },
            {
                path: '/uikit/button',
                name: 'button',
                meta: {
                    breadcrumb: ['UI Kit', 'Button']
                },
                component: () => import('@/views/uikit/Button.vue')
            },
            {
                path: '/uikit/table',
                name: 'table',
                meta: {
                    breadcrumb: ['UI Kit', 'Table']
                },
                component: () => import('@/views/uikit/Table.vue')
            },
            {
                path: '/uikit/list',
                name: 'list',
                meta: {
                    breadcrumb: ['UI Kit', 'List']
                },
                component: () => import('@/views/uikit/List.vue')
            },
            {
                path: '/uikit/tree',
                name: 'tree',
                meta: {
                    breadcrumb: ['UI Kit', 'Tree']
                },
                component: () => import('@/views/uikit/Tree.vue')
            },
            {
                path: '/uikit/panel',
                name: 'panel',
                meta: {
                    breadcrumb: ['UI Kit', 'Panel']
                },
                component: () => import('@/views/uikit/Panels.vue')
            },

            {
                path: '/uikit/overlay',
                name: 'overlay',
                meta: {
                    breadcrumb: ['UI Kit', 'Overlay']
                },
                component: () => import('@/views/uikit/Overlay.vue')
            },
            {
                path: '/uikit/media',
                name: 'media',
                meta: {
                    breadcrumb: ['UI Kit', 'Media']
                },
                component: () => import('@/views/uikit/Media.vue')
            },
            {
                path: '/uikit/menu',
                meta: {
                    breadcrumb: ['UI Kit', 'Menu']
                },
                component: () => import('@/views/uikit/Menu.vue'),
                children: [
                    {
                        path: '/uikit/menu',
                        meta: {
                            breadcrumb: ['UI Kit', 'Menu']
                        },
                        component: () => import('@/views/uikit/menu/PersonalDemo.vue')
                    },
                    {
                        path: '/uikit/menu/seat',
                        meta: {
                            breadcrumb: ['UI Kit', 'Menu', 'Seat']
                        },
                        component: () => import('@/views/uikit/menu/SeatDemo.vue')
                    },
                    {
                        path: '/uikit/menu/payment',
                        meta: {
                            breadcrumb: ['UI Kit', 'Menu', 'Payment']
                        },
                        component: () => import('@/views/uikit/menu/PaymentDemo.vue')
                    },
                    {
                        path: '/uikit/menu/confirmation',
                        meta: {
                            breadcrumb: ['UI Kit', 'Menu', 'Confirmation']
                        },
                        component: () => import('@/views/uikit/menu/ConfirmationDemo.vue')
                    }
                ]
            },
            {
                path: '/uikit/message',
                name: 'message',
                meta: {
                    breadcrumb: ['UI Kit', 'Message']
                },
                component: () => import('@/views/uikit/Messages.vue')
            },
            {
                path: '/uikit/file',
                name: 'file',
                meta: {
                    breadcrumb: ['UI Kit', 'File']
                },
                component: () => import('@/views/uikit/File.vue')
            },
            {
                path: '/uikit/charts',
                name: 'charts',
                meta: {
                    breadcrumb: ['UI Kit', 'Charts']
                },
                component: () => import('@/views/uikit/Chart.vue')
            },
            {
                path: '/uikit/misc',
                name: 'misc',
                meta: {
                    breadcrumb: ['UI Kit', 'Misc']
                },
                component: () => import('@/views/uikit/Misc.vue')
            },
            {
                path: '/blocks',
                name: 'blocks',
                meta: {
                    breadcrumb: ['Prime Blocks', 'Free Blocks']
                },
                component: () => import('@/views/utilities/Blocks.vue')
            },
            {
                path: '/utilities/colors',
                name: 'colors',
                component: () => import('@/views/utilities/Colors.vue')
            },
            {
                path: '/utilities/icons',
                name: 'icons',
                meta: {
                    breadcrumb: ['Utilities', 'Prime Icons']
                },
                component: () => import('@/views/utilities/Icons.vue')
            },
            {
                path: '/pages/timeline',
                name: 'timeline',
                component: () => import('@/views/pages/Timeline.vue')
            },
            {
                path: '/pages/empty',
                name: 'empty',
                component: () => import('@/views/pages/Empty.vue')
            },
            {
                path: '/pages/crud',
                name: 'crud',
                component: () => import('@/views/pages/Crud.vue')
            },
            {
                path: '/ecommerce/product-overview',
                name: 'product-overview',
                meta: {
                    breadcrumb: ['E-Commerce', 'Product Overview']
                },
                component: () => import('@/views/e-commerce/ProductOverview.vue')
            },
            {
                path: '/ecommerce/product-list',
                name: 'product-list',
                meta: {
                    breadcrumb: ['E-Commerce', 'Product List']
                },
                component: () => import('@/views/e-commerce/ProductList.vue')
            },
            {
                path: '/ecommerce/new-product',
                name: 'new-product',
                meta: {
                    breadcrumb: ['E-Commerce', 'New Product']
                },
                component: () => import('@/views/e-commerce/NewProduct.vue')
            },
            {
                path: '/ecommerce/shopping-cart',
                name: 'shopping-cart',
                meta: {
                    breadcrumb: ['E-Commerce', 'Shopping Cart']
                },
                component: () => import('@/views/e-commerce/ShoppingCart.vue')
            },
            {
                path: '/ecommerce/checkout-form',
                name: 'checkout-form',
                meta: {
                    breadcrumb: ['E-Commerce', 'Checkout Form']
                },
                component: () => import('@/views/e-commerce/CheckoutForm.vue')
            },
            {
                path: '/ecommerce/order-history',
                name: 'order-history',
                meta: {
                    breadcrumb: ['E-Commerce', 'Order History']
                },
                component: () => import('@/views/e-commerce/OrderHistory.vue')
            },
            {
                path: '/ecommerce/order-summary',
                name: 'order-summary',
                meta: {
                    breadcrumb: ['E-Commerce', 'Order Summary']
                },
                component: () => import('@/views/e-commerce/OrderSummary.vue')
            },

            {
                path: '/profile/create',
                name: 'user-create',
                meta: {
                    breadcrumb: ['User Management', 'Create']
                },
                component: () => import('@/views/user-management/UserCreate.vue')
            },
            {
                path: '/profile/list',
                name: 'user-list',
                meta: {
                    breadcrumb: ['User Management', 'List']
                },
                component: () => import('@/views/user-management/UserList.vue')
            },
            {
                path: '/documentation',
                name: 'documentation',
                component: () => import('@/views/utilities/Documentation.vue')
            },
            {
                path: '/pages/aboutus',
                name: 'aboutus',
                meta: {
                    breadcrumb: ['Pages', 'About']
                },
                component: () => import('@/views/pages/AboutUs.vue')
            },
            {
                path: '/pages/contact',
                name: 'contact',
                component: () => import('@/views/pages/ContactUs.vue')
            },
            {
                path: '/pages/faq',
                name: 'faq',
                meta: {
                    breadcrumb: ['Pages', 'FAQ']
                },
                component: () => import('@/views/pages/Faq.vue')
            },
            {
                path: '/pages/help',
                name: 'help',
                component: () => import('@/views/pages/Help.vue')
            },
            {
                path: '/pages/invoice',
                name: 'invoice',
                component: () => import('@/views/pages/Invoice.vue')
            }
        ]
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('@/views/pages/Landing.vue')
    },
    {
        path: '/pages/notfound',
        name: 'notfound',
        component: () => import('@/views/pages/NotFound.vue')
    },
    {
        path: '/auth-check',
        name: 'AuthCheck',
        component: () => import('@/components/AuthCheck.vue')
    },
    {
        path: '/auth/login',
        name: 'Login',
        component: () => import('@/views/pages/auth/Login.vue')
    },
    {
        path: '/auth/access',
        name: 'accessDenied',
        component: () => import('@/views/pages/auth/AccessDenied.vue')
    },
    {
        path: '/auth/error',
        name: 'error',
        component: () => import('@/views/pages/auth/Error.vue')
    },
    {
        path: '/auth/register',
        name: 'register',
        component: () => import('@/views/pages/auth/Register.vue')
    },
    {
        path: '/auth/forgotpassword',
        name: 'forgotpassword',
        component: () => import('@/views/pages/auth/ForgotPassword.vue')
    },
    {
        path: '/auth/newpassword',
        name: 'newpassword',
        component: () => import('@/views/pages/auth/NewPassword.vue')
    },
    {
        path: '/auth/verification',
        name: 'verification',
        component: () => import('@/views/pages/auth/Verification.vue')
    },
    {
        path: '/auth/lockscreen',
        name: 'lockscreen',
        component: () => import('@/views/pages/auth/LockScreen.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notfound',
        component: () => import('@/views/pages/NotFound.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

router.beforeEach(async (to, from, next) => {
    console.log(`Navigation attempt: from ${from.path} to ${to.path}`);

    const userState = useUser();
    let user = userState.getUser();

    // Log current user state
    console.log('Current user:', user ? 'Authenticated' : 'Not authenticated');

    // If Firebase hasn't initialized yet and there's no user, wait for it to initialize
    if (!user) {
        await new Promise(resolve => {
            const unsubscribe = auth.onAuthStateChanged(fetchedUser => {
                if (fetchedUser) {
                    userState.setUser(fetchedUser); // Ensure user state is updated
                    user = fetchedUser;
                }
                unsubscribe();
                resolve();
            });
        });
    }

    // If it's the AuthCheck route, allow navigation without further checks
    if (to.name === 'AuthCheck') {
        console.log('Allowing navigation to AuthCheck');
        return next();
    }

    // If no user and not on Login page, redirect to login
    if (!user && to.name !== 'Login') {
        console.log('User not authenticated, redirecting to Login');
        return next({ name: 'Login' });
    }

    // If user is trying to access ClientDetail and is authenticated
    if (to.name === 'ClientDetail' && user) {
        console.log('User authenticated, allowing navigation to ClientDetail');
        return next();
    }

    // Role-based navigation check
    if (user && to.meta.requiresAdmin) {
        const userRoles = toRaw(user.roles).flat(); // Ensure userRoles is accessed correctly
        console.log('User roles from admin guard:', userRoles);

        // Simplified role check logic
        const isAdmin = userRoles.includes('Admin');

        if (!isAdmin) {
            console.log('User is not an admin, redirecting to clients/view-all');
            return next('/clients/view-all');
        } else {
            console.log('User is an admin, allowing navigation');
            return next();
        }
    }

    if (to.matched.some(record => record.meta.requiresAuth) && !user) {
        // Redirect to AuthCheck with the intended destination
        next({
            name: 'AuthCheck',
            query: { redirect: encodeURIComponent(to.fullPath) }
        });
    } else {
        next();
    }

    // Default navigation
    console.log('Default case, allowing navigation');
    next();
});
export default router;
