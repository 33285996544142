
const CACHE_EXPIRATION = 0.5 * 60 * 1000; // 5 minutes in milliseconds

const setCache = (key, data) => {
  const cacheItem = {
    data,
    timestamp: Date.now()
  };
  localStorage.setItem(key, JSON.stringify(cacheItem));
};

const getCache = (key) => {
  const cachedItem = localStorage.getItem(key);
  if (!cachedItem) return null;

  const { data, timestamp } = JSON.parse(cachedItem);
  if (Date.now() - timestamp > CACHE_EXPIRATION) {
    localStorage.removeItem(key);
    return null;
  }

  return data;
};

export const fetchWithCache = async (key, fetchFunction) => {
  const cachedData = getCache(key);
  if (cachedData) return cachedData;

  const data = await fetchFunction();
  setCache(key, data);
  return data;
};