// src/composables/useUser.js
import { reactive, toRefs } from 'vue';
import { getUser, setUser, clearUser } from '@/service/authModule';

const useUser = () => {
  const state = reactive({
    user: null,
    loading: true
  });

  const getUserState = () => {
    state.user = getUser();
    return state.user;
  };

  const setUserState = (newUser) => {
    setUser(newUser);
    state.user = newUser;
  };

  const clearUserState = () => {
    clearUser();
    state.user = null;
  };

  const setLoading = (isLoading) => {
    state.loading = isLoading;
  };

  return {
    ...toRefs(state),
    getUser: getUserState,
    setUser: setUserState,
    clearUser: clearUserState,
    setLoading
  };
};

export { useUser };