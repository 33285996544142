import { reactive } from 'vue';
import { getCurrentUser } from './authService';

const state = reactive({
  user: null,
});

export const getUser = () => {
  if (!state.user) {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      state.user = JSON.parse(storedUser);
    } else {
      state.user = getCurrentUser();
    }
  }
  return state.user;
};

export const setUser = (newUser) => {
  state.user = newUser;
  if (newUser) {
    localStorage.setItem('user', JSON.stringify(newUser));
  } else {
    localStorage.removeItem('user');
  }
};

export const clearUser = () => {
  state.user = null;
  localStorage.removeItem('user');
};