<script setup>
import { ref, computed, onMounted } from 'vue';
import AppMenuItem from './AppMenuItem.vue';
import { useUser } from '@/composables/useUser';
import { fetchRoles } from '@/service/FirestoreService';

const { getUser } = useUser();

const userRoles = ref([]); // Ensure userRoles is an array
// console.log('user:', user); 
// console.log('userRoles:', userRoles);

const hasRole = (roles) => {
    //   console.log('Checking roles:', roles, 'against userRoles:', userRoles.value);
    if (!Array.isArray(roles) || !Array.isArray(userRoles.value)) {
        return false;
    }
    const plainUserRoles = JSON.parse(JSON.stringify(userRoles.value));
    return roles.some(role => plainUserRoles.includes(role));
};

onMounted(async () => {
    const user = getUser();
    if (user) {
        userRoles.value = user?.roles || [];
        // console.log('User email:', user.email, 'Initial userRoles:', userRoles.value);

        const fetchedRoles = await fetchRoles(user.email);
        userRoles.value = fetchedRoles.flat(); // Flatten the array if nested
        // console.log('Fetched userRoles:', userRoles.value);
    }
});


const filteredModel = computed(() => {
    //   console.log('Filtering model with userRoles:', userRoles.value); // Log before filtering
    const filtered = model.value.map(category => {
        const filteredItems = category.items.filter(item => {
            const result = hasRole(item.roles);
            //   console.log('Item:', item.label, 'Roles:', item.roles, 'Result:', result); // Log filtered items
            return result;
        });
        // console.log('Category:', category.label, 'Filtered items:', filteredItems); // Log filtered items
        return {
            ...category,
            items: filteredItems
        };
    }).filter(category => category.items.length > 0); // Remove empty categories

    //   console.log('Filtered model:', filtered); // Log final filtered model
    return filtered;
});
const model = ref([
    {
        label: 'Dashboard',
        icon: 'pi pi-home',
        items: [
            {
                label: 'Admin Dashboard',
                icon: 'pi pi-fw pi-home',
                to: '/',
                roles: ['Admin']
            },
            // {
            //     label: 'Calendar',
            //     icon: 'pi pi-fw pi-calendar',
            //     to: '/apps/calendar'
            // },
            // {
            //     label: 'Banking',
            //     icon: 'pi pi-fw pi-image',
            //     to: '/dashboard-banking'
            // }
        ]
    },
    {
        label: 'Clients',
        icon: 'pi pi-th-large',
        items: [
            {
                label: 'View All Clients',
                icon: 'pi pi-fw pi-user',
                to: '/clients/view-all',
                roles: ['Admin', 'Sales', 'Office', 'Sales Manager', 'Inventory', 'Accountant'],
            },

            {
                label: 'Create New Client',
                icon: 'pi pi-fw pi-user-plus',
                to: '/clients/create',
                roles: ['Admin', 'Sales', 'Office', 'Sales Manager'],
            },

            {
                label: 'Client Report',
                icon: 'pi pi-fw pi-chart-bar',
                to: '/clients/report',
                roles: ['Admin', 'Office', 'Sales Manager'],

            },

            // {
            //     label: 'Blog',
            //     icon: 'pi pi-fw pi-comment',
            //     items: [
            //         {
            //             label: 'List',
            //             icon: 'pi pi-fw pi-image',
            //             to: '/apps/blog/list'
            //         },
            //         {
            //             label: 'Detail',
            //             icon: 'pi pi-fw pi-list',
            //             to: '/apps/blog/detail'
            //         },
            //         {
            //             label: 'Edit',
            //             icon: 'pi pi-fw pi-pencil',
            //             to: '/apps/blog/edit'
            //         }
            //     ]
            // },

            // {
            //     label: 'Chat',
            //     icon: 'pi pi-fw pi-comments',
            //     to: '/apps/chat'
            // },
            // {
            //     label: 'Files',
            //     icon: 'pi pi-fw pi-folder',
            //     to: '/apps/files'
            // },
            // {
            //     label: 'Mail',
            //     icon: 'pi pi-fw pi-envelope',
            //     items: [
            //         {
            //             label: 'Inbox',
            //             icon: 'pi pi-fw pi-inbox',
            //             to: '/apps/mail/inbox'
            //         },
            //         {
            //             label: 'Compose',
            //             icon: 'pi pi-fw pi-pencil',
            //             to: '/apps/mail/compose'
            //         },
            //         {
            //             label: 'Detail',
            //             icon: 'pi pi-fw pi-comment',
            //             to: '/apps/mail/detail/1000'
            //         }
            //     ]
            // },
            // {
            //     label: 'Task List',
            //     icon: 'pi pi-fw pi-check-square',
            //     to: '/apps/tasklist'
            // }
        ]
    },
    {
        label: 'Inventory',
        icon: 'pi pi-fw pi-th-large',
        items: [
            {
                label: 'View Items',
                icon: 'pi pi-fw pi-list',
                to: '/inventory/view-items',
                roles: ['Admin', 'Office', 'Sales Manager', 'Inventory'],
            },
            {
                label: 'Search Serial Number',
                icon: 'pi pi-fw pi-search',
                to: '/inventory/search-sn',
                roles: ['Admin', 'Office', 'Sales Manager', 'Inventory'],
            },
            {
                label: 'Warehouse In',
                icon: 'pi pi-fw pi-arrow-down',
                to: '/inventory/warehouse-in',
                roles: ['Admin', 'Office', 'Sales Manager', 'Inventory'],
            },
            {
                label: 'Warehouse Out',
                icon: 'pi pi-fw pi-arrow-up',
                to: '/inventory/warehouse-out',
                roles: ['Admin', 'Office', 'Sales Manager', 'Inventory']
            },


            // {
            //     label: 'Form Layout',
            //     icon: 'pi pi-fw pi-id-card',
            //     to: '/uikit/formlayout'
            // },
            // {
            //     label: 'Input',
            //     icon: 'pi pi-fw pi-check-square',
            //     to: '/uikit/input'
            // },
            // {
            //     label: 'Float Label',
            //     icon: 'pi pi-fw pi-bookmark',
            //     to: '/uikit/floatlabel'
            // },
            // {
            //     label: 'Invalid State',
            //     icon: 'pi pi-fw pi-exclamation-circle',
            //     to: '/uikit/invalidstate'
            // },
            // {
            //     label: 'Button',
            //     icon: 'pi pi-fw pi-box',
            //     to: '/uikit/button'
            // },
            // {
            //     label: 'Table',
            //     icon: 'pi pi-fw pi-table',
            //     to: '/uikit/table'
            // },
            // {
            //     label: 'List',
            //     icon: 'pi pi-fw pi-list',
            //     to: '/uikit/list'
            // },
            // {
            //     label: 'Tree',
            //     icon: 'pi pi-fw pi-share-alt',
            //     to: '/uikit/tree'
            // },
            // {
            //     label: 'Panel',
            //     icon: 'pi pi-fw pi-tablet',
            //     to: '/uikit/panel'
            // },
            // {
            //     label: 'Overlay',
            //     icon: 'pi pi-fw pi-clone',
            //     to: '/uikit/overlay'
            // },
            // {
            //     label: 'Media',
            //     icon: 'pi pi-fw pi-image',
            //     to: '/uikit/media'
            // },
            // {
            //     label: 'Menu',
            //     icon: 'pi pi-fw pi-bars',
            //     to: '/uikit/menu'
            // },
            // {
            //     label: 'Message',
            //     icon: 'pi pi-fw pi-comment',
            //     to: '/uikit/message'
            // },
            // {
            //     label: 'File',
            //     icon: 'pi pi-fw pi-file',
            //     to: '/uikit/file'
            // },
            // {
            //     label: 'Chart',
            //     icon: 'pi pi-fw pi-chart-bar',
            //     to: '/uikit/charts'
            // },
            // {
            //     label: 'Misc',
            //     icon: 'pi pi-fw pi-circle-off',
            //     to: '/uikit/misc'
            // }
        ]
    },
    {
        label: 'Accounting',
        icon: 'pi pi-fw pi-prime',
        items: [

            {
                label: 'View All Transactions',
                icon: 'pi pi-fw pi-list',
                to: '/accounting/search-transaction',
                roles: ['Admin', 'Accountant'],
            },
            {
                label: 'Create New Transaction',
                icon: 'pi pi-fw pi-plus',
                to: '/accounting/create-new-transaction',
                roles: ['Admin', 'Accountant']
            },
            {
                label: 'Commission Report',
                icon: 'pi pi-fw pi-chart-bar',
                to: '/accounting/commission-report',
                roles: ['Admin', 'Accountant']
            },
            // {
            //     label: 'Free Blocks',
            //     icon: 'pi pi-fw pi-eye',
            //     to: '/blocks'
            // },
            // {
            //     label: 'All Blocks',
            //     icon: 'pi pi-fw pi-globe',
            //     url: 'https://www.primefaces.org/primeblocks-vue',
            //     target: '_blank'
            // }
        ]
    },
    {
        label: 'Office',
        icon: 'pi pi-fw pi-compass',
        items: [
            {
                label: 'Add New Source',
                icon: 'pi pi-fw pi-bars',
                to: '/office/add-source',
                roles: ['Admin', 'Office', 'Sales Manager'],
            },
            {
                label: 'Add New Supplier',
                icon: 'pi pi-fw pi-external-link',
                to: '/office/add-supplier',
                roles: ['Admin', 'Office', 'Sales Manager'],
            },
            {
                label: 'Add New User',
                icon: 'pi pi-fw pi-user-plus',
                to: '/office/add-user',
                roles: ['Admin', 'Office', 'Sales Manager'],
            },
            {
                label: 'Logs',
                icon: 'pi pi-fw pi-cog',
                to: '/admin/logs',
                roles: ['Admin', 'Office'],
            }
        ]
    },
    {
        label: 'Admin',
        icon: 'pi pi-fw pi-cog',
        items: [
            {
                label: 'User Management',
                icon: 'pi pi-fw pi-box',
                to: '/admin/user-management',
                roles: ['Admin'],
            },
            {
                label: 'Add User',
                icon: 'pi pi-fw pi-user-plus',
                to: '/admin/add-user',
                roles: ['Admin'],
            },
            // {
            //     label: 'Add Source',
            //     icon: 'pi pi-fw pi-desktop',
            //     to: '/admin/add-source',
            //     roles: ['Admin'], 
            // },
            // {
            //     label: 'Add Supplier',
            //     icon: 'pi pi-fw pi-cart-plus',
            //     to: '/admin/add-supplier',
            //     roles: ['Admin'], 
            // },


        ]
    },
    // {
    //     label: 'Pages',
    //     icon: 'pi pi-fw pi-briefcase',
    //     items: [
    //         {
    //             label: 'Landing',
    //             icon: 'pi pi-fw pi-globe',
    //             to: '/landing'
    //         },
    //         {
    //             label: 'Auth',
    //             icon: 'pi pi-fw pi-user',
    //             items: [
    //                 {
    //                     label: 'Login',
    //                     icon: 'pi pi-fw pi-sign-in',
    //                     to: '/auth/login'
    //                 },

    //                 {
    //                     label: 'Error',
    //                     icon: 'pi pi-fw pi-times-circle',
    //                     to: '/auth/error'
    //                 },

    //                 {
    //                     label: 'Access Denied',
    //                     icon: 'pi pi-fw pi-lock',
    //                     to: '/auth/access'
    //                 },

    //                 {
    //                     label: 'Register',
    //                     icon: 'pi pi-fw pi-user-plus',
    //                     to: '/auth/register'
    //                 },
    //                 {
    //                     label: 'Forgot Password',
    //                     icon: 'pi pi-fw pi-question',
    //                     to: '/auth/forgotpassword'
    //                 },
    //                 {
    //                     label: 'New Password',
    //                     icon: 'pi pi-fw pi-cog',
    //                     to: '/auth/newpassword'
    //                 },
    //                 {
    //                     label: 'Verification',
    //                     icon: 'pi pi-fw pi-envelope',
    //                     to: '/auth/verification'
    //                 },
    //                 {
    //                     label: 'Lock Screen',
    //                     icon: 'pi pi-fw pi-eye-slash',
    //                     to: '/auth/lockscreen'
    //                 }
    //             ]
    //         },
    //         {
    //             label: 'Crud',
    //             icon: 'pi pi-fw pi-pencil',
    //             to: '/pages/crud'
    //         },
    //         {
    //             label: 'Timeline',
    //             icon: 'pi pi-fw pi-calendar',
    //             to: '/pages/timeline'
    //         },
    //         {
    //             label: 'Invoice',
    //             icon: 'pi pi-fw pi-dollar',
    //             to: '/pages/invoice'
    //         },

    //         {
    //             label: 'Help',
    //             icon: 'pi pi-fw pi-question-circle',
    //             to: '/pages/help'
    //         },
    //         {
    //             label: 'Not Found',
    //             icon: 'pi pi-fw pi-exclamation-circle',
    //             to: '/pages/notfound'
    //         },
    //         {
    //             label: 'Empty',
    //             icon: 'pi pi-fw pi-circle-off',
    //             to: '/pages/empty'
    //         },
    //         {
    //             label: 'Contact Us',
    //             icon: 'pi pi-fw pi-phone',
    //             to: '/pages/contact'
    //         }
    //     ]
    // },
    // {
    //     label: 'E-Commerce',
    //     icon: 'pi pi-fw pi-wallet',
    //     items: [
    //         {
    //             label: 'Product Overview',
    //             icon: 'pi pi-fw pi-image',
    //             to: '/ecommerce/product-overview'
    //         },
    //         {
    //             label: 'Product List',
    //             icon: 'pi pi-fw pi-list',
    //             to: '/ecommerce/product-list'
    //         },
    //         {
    //             label: 'New Product',
    //             icon: 'pi pi-fw pi-plus',
    //             to: '/ecommerce/new-product'
    //         },
    //         {
    //             label: 'Shopping Cart',
    //             icon: 'pi pi-fw pi-shopping-cart',
    //             to: '/ecommerce/shopping-cart'
    //         },
    //         {
    //             label: 'Checkout Form',
    //             icon: 'pi pi-fw pi-check-square',
    //             to: '/ecommerce/checkout-form'
    //         },
    //         {
    //             label: 'Order History',
    //             icon: 'pi pi-fw pi-history',
    //             to: '/ecommerce/order-history'
    //         },
    //         {
    //             label: 'Order Summary',
    //             icon: 'pi pi-fw pi-file',
    //             to: '/ecommerce/order-summary'
    //         }
    //     ]
    // },

    // {
    //     label: 'User Management',
    //     icon: 'pi pi-fw pi-user',
    //     items: [
    //         {
    //             label: 'List',
    //             icon: 'pi pi-fw pi-list',
    //             to: '/profile/list'
    //         },
    //         {
    //             label: 'Create',
    //             icon: 'pi pi-fw pi-plus',
    //             to: '/profile/create'
    //         }
    //     ]
    // },
    // {
    //     label: 'Hierarchy',
    //     icon: 'pi pi-fw pi-align-left',
    //     items: [
    //         {
    //             label: 'Submenu 1',
    //             icon: 'pi pi-fw pi-align-left',
    //             items: [
    //                 {
    //                     label: 'Submenu 1.1',
    //                     icon: 'pi pi-fw pi-align-left',
    //                     items: [
    //                         {
    //                             label: 'Submenu 1.1.1',
    //                             icon: 'pi pi-fw pi-align-left'
    //                         },
    //                         {
    //                             label: 'Submenu 1.1.2',
    //                             icon: 'pi pi-fw pi-align-left'
    //                         },
    //                         {
    //                             label: 'Submenu 1.1.3',
    //                             icon: 'pi pi-fw pi-align-left'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     label: 'Submenu 1.2',
    //                     icon: 'pi pi-fw pi-align-left',
    //                     items: [
    //                         {
    //                             label: 'Submenu 1.2.1',
    //                             icon: 'pi pi-fw pi-align-left'
    //                         }
    //                     ]
    //                 }
    //             ]
    //         },
    //         {
    //             label: 'Submenu 2',
    //             icon: 'pi pi-fw pi-align-left',
    //             items: [
    //                 {
    //                     label: 'Submenu 2.1',
    //                     icon: 'pi pi-fw pi-align-left',
    //                     items: [
    //                         {
    //                             label: 'Submenu 2.1.1',
    //                             icon: 'pi pi-fw pi-align-left'
    //                         },
    //                         {
    //                             label: 'Submenu 2.1.2',
    //                             icon: 'pi pi-fw pi-align-left'
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     label: 'Submenu 2.2',
    //                     icon: 'pi pi-fw pi-align-left',
    //                     items: [
    //                         {
    //                             label: 'Submenu 2.2.1',
    //                             icon: 'pi pi-fw pi-align-left'
    //                         }
    //                     ]
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     label: 'Start',
    //     icon: 'pi pi-fw pi-download',
    //     items: [
    //         {
    //             label: 'Buy Now',
    //             icon: 'pi pi-fw pi-shopping-cart',
    //             url: 'https://www.primefaces.org/store'
    //         },
    //         {
    //             label: 'Documentation',
    //             icon: 'pi pi-fw pi-info-circle',
    //             to: '/documentation'
    //         }
    //     ]
    // }
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in filteredModel" :key="item">
            <AppMenuItem :item="item" root :index="i" />

            <li class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
